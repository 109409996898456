import React from "react"
// react plugin used to create DropdownMenu for selecting items
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap"
import Select2 from "react-select2-wrapper"

class User extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.modalTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <div className="form-row">
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Nome completo
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            placeholder="Nome completo"
                            type="text"
                            value={this.props.fullName}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.fullNameState === "valid"
                            }
                            invalid={
                              this.props.customStyles.fullNameState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "fullName")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o nome do usuário.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            E-mail
                          </label>
                          <Input
                            id="validationCustom02"
                            placeholder="E-mail"
                            type="text"
                            autoComplete="new-password"
                            value={this.props.email}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.emailState === "valid"
                            }
                            invalid={
                              this.props.customStyles.emailState === "invalid"
                            }
                            onChange={(e) => this.props.stylesForm(e, "email")}
                          />
                          <div className="invalid-feedback">
                            Digite um e-mail.
                          </div>
                        </Col>
                      </div>

                      <div className="form-row">
                        <Col className="mb-3" lg="6" md="12">
                          <label
                            className="form-control-label"
                            htmlFor="consultant"
                          >
                            Classificação
                          </label>
                          <Select2
                            id="consultant"
                            type="text"
                            value={this.props.class}
                            onChange={(e) => this.props.stylesForm(e, "class")}
                            options={{
                              hidden: true,
                              size: "small",
                            }}
                            data={[
                              {
                                id: "CG",
                                text: "Consultoria",
                              },
                              {
                                id: "TP",
                                text: "Terceiro",
                              },
                            ]}
                          ></Select2>
                        </Col>

                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="entryDate"
                          >
                            Data Entrada
                          </label>
                          <Input
                            id="entryDate"
                            type="date"
                            value={this.props.entryDate}
                            readOnly={this.props.readOnly}
                            disabled={
                              this.props.saving || this.props.class === "TP"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "entryDate")
                            }
                          />
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="hourlyRate"
                          >
                            Valor Hora (R$)
                          </label>
                          <Input
                            defaultValue="0.00"
                            id="hourlyRate"
                            type="number"
                            value={this.props.hourlyRate}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.hourlyRateState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.hourlyRateState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "hourlyRate")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o valor hora.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="birthDate"
                          >
                            Data Nascimento
                          </label>
                          <Input
                            id="birthDate"
                            type="date"
                            value={this.props.birthDate}
                            readOnly={this.props.readOnly}
                            disabled={
                              (this.props.saving, this.props.class === "TP")
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "birthDate")
                            }
                          />
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustomUsername"
                          >
                            Senha
                          </label>
                          <Input
                            aria-describedby="inputGroupPrepend"
                            id="validationCustomUsername"
                            placeholder="Senha"
                            type="password"
                            autoComplete="new-password"
                            value={this.props.password}
                            readOnly={this.props.readOnly}
                            disabled={
                              this.props.saving || this.props.class === "TP"
                            }
                            valid={
                              this.props.customStyles.passwordState === "valid"
                            }
                            invalid={
                              this.props.customStyles.passwordState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "password")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite uma senha.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validateProfile"
                          >
                            Perfil
                          </label>
                          <Input
                            id="validateProfile"
                            type="select"
                            value={this.props.sglProfile}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              this.props.class === "TP"
                            }
                            valid={
                              this.props.customStyles.sglProfileState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.sglProfileState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "sglProfile")
                            }
                          >
                            <option value=""></option>
                            {this.props.menuAccesses.map((ma) => {
                              return <option value={ma.code}>{ma.name}</option>
                            })}
                          </Input>
                          <div className="invalid-feedback">
                            Escolha um perfil.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label className="form-control-label" htmlFor="kind">
                            Tipo
                          </label>
                          <Input
                            id="kind"
                            type="select"
                            value={this.props.kind}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              this.props.class === "TP"
                            }
                            valid={
                              this.props.customStyles.kindState === "valid"
                            }
                            invalid={
                              this.props.customStyles.kindState === "invalid"
                            }
                            onChange={(e) => this.props.stylesForm(e, "kind")}
                          >
                            <option value=""></option>
                            <option value="R">Recurso</option>
                            <option value="M">Gerente</option>
                          </Input>
                          <div className="invalid-feedback">
                            Escolha um tipo de usuário.
                          </div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom05"
                          >
                            Ativo
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                defaultChecked={this.props.active}
                                type="checkbox"
                                value={this.props.active}
                                disabled={
                                  this.props.readOnly || this.props.saving
                                }
                                onChange={(e) =>
                                  this.props.stylesForm(e, "active")
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom05"
                          >
                            Exige Agenda
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                defaultChecked={this.props.showSchedule}
                                type="checkbox"
                                value={this.props.showSchedule}
                                disabled={
                                  this.props.readOnly ||
                                  this.props.saving ||
                                  this.props.class === "TP"
                                }
                                onChange={(e) =>
                                  this.props.stylesForm(e, "showSchedule")
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e, "abort")}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.modalSave(e)}
              hidden={this.props.crud === "R"}
              // disabled={this.props.saving || this.props.hasActivities}
              disabled={this.props.saving}
            >
              {this.props.crud === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default User
