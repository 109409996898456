import axios from "axios"
const apiSap = axios.create({
  withCredentials: true,
  baseURL: "https://sap-sl-golive.skyinone.net:50000/b1s/v1", ///process.env.NEXT_PUBLIC_SAP_API_URL 357
  //baseURL: "https://b1.ativy.com:50870/b1s/v1" 
})

apiSap.interceptors.response.use((response) => response)

export default apiSap
