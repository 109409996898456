import ScheduleForm from "components/Forms/ScheduleForm"
import React, { useEffect, useMemo, useState } from "react"
// react plugin used to create DropdownMenu for selecting items
// reactstrap components
import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import api from "services/api"
import { userId } from "services/auth"
import { hoursToMinutes } from "utils/timeHandling"
import { minutesToHours } from "utils/timeHandling"
import ConfirmationModal from "../ConfirmationModal"

const cleanSchedule = {
  customer: "",
  address: "",
  consultant: "",
  date: new Date().toISOString().split("T")[0],
  timeFrom: "00:00",
  timeTo: "00:00",
  timeCommute: "00:00",
  timeOthers: "00:00",
  timeTotal: "00:00",
  billing: true,
  project: "",
}

export default function CreateSchedule(props) {
  const [saving, setSaving] = useState(false)
  const [customers, setCustomers] = useState([])
  const [customerAddresses, setCustomerAddresses] = useState([])
  const [consultants, setConsultants] = useState([])
  const [projects, setProjects] = useState([])

  const [scheduleBody, setScheduleBody] = useState(cleanSchedule)

  //confirmationModal
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationModalAction, setConfirmationModalAction] = useState({})
  const [confirmation, setConfirmation] = useState(undefined)

  function cleanScheduleAndCloseModal() {
    setScheduleBody(cleanSchedule)
    props.closeModal()
  }

  useEffect(() => {
    let timeArray = [
      scheduleBody.timeTo,
      scheduleBody.timeFrom,
      scheduleBody.timeCommute,
      scheduleBody.timeOthers,
    ]
    let timeTotal = 0
    timeArray.forEach((time, index) => {
      //on even index we add in not even we subtract
      if (index % 2 === 0) {
        timeTotal += hoursToMinutes(time)
      } else {
        timeTotal -= hoursToMinutes(time)
      }
    })
    setScheduleBody((prev) => ({
      ...prev,
      timeTotal: minutesToHours(timeTotal),
    }))
  }, [
    scheduleBody.timeCommute,
    scheduleBody.timeFrom,
    scheduleBody.timeOthers,
    scheduleBody.timeTo,
  ])

  useEffect(() => {}, [scheduleBody.address])

  useMemo(async () => {
    const customersResponse = await api.get("customer/all")
    setCustomers(customersResponse.data)

    const customerAddressesResponse = await api.get("customerAddress/all")
    setCustomerAddresses(customerAddressesResponse.data)

    const consultantsResponse = await api.post("user/search", {
      class: "CG",
      showSchedule: true,
    })
    const consultants = consultantsResponse.data
    setConsultants(consultants)

    const projectResponse = await api.get("project/all")
    setProjects(projectResponse.data)
  }, [])

  /**
   *
   */
  const saveSchedule = async () => {
    setSaving(true)
    try {
      const consultantSchedulesResponse = await api.get(
        "user/read-with-schedules/" + scheduleBody.consultant
      )

      const activityConsultantResponse = await api.post(
        "activityConsultant/search",
        { consultant: scheduleBody.consultant }
      )
      const consultantSchedules = consultantSchedulesResponse.data.schedules
      const consultantActivities = activityConsultantResponse.data

      //takes schedules with the same project
      const projectSchedules = consultantSchedules.filter((schedule) => {
        return (
          schedule.project === scheduleBody.project && schedule.deleted === "N"
        )
      })

      //takes use activities with the same project
      const projectActivities = consultantActivities.filter(
        (activityConsultant) => {
          return activityConsultant.activity.project === scheduleBody.project
        }
      )
      const conflictingSchedules = projectSchedules.filter(
        (schedule) => schedule.date.split("T")[0] === scheduleBody.date
      )
      if (conflictingSchedules.length > 0) {
        alert(
          "OPERAÇÃO INVÁLIDA: Consultor já possui agenda para este projeto na data selecionada"
        )
        setScheduleBody((prev) => ({ ...prev, project: "" }))
        return
      }
      if (projectActivities.length < 1) {
        setConfirmationModalAction({
          action: "continue",
          text: `ATENÇÃO: O consultor não possui atividades alocadas para o projeto selecionado. Deseja continuar?`,
        })
        setConfirmationModalOpen(true)
      } else {
        await api.post("schedule/create", { ...scheduleBody, user: userId() })
        props.updateList()
        cleanScheduleAndCloseModal()
      }
    } catch (error) {
      alert("Ocorreu um erro")
      console.error(error)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => cleanScheduleAndCloseModal()}
          disabled={saving}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardBody>
                {customers && customerAddresses && consultants && (
                  <ScheduleForm
                    customers={customers}
                    customerAddresses={customerAddresses}
                    consultants={consultants}
                    projects={projects}
                    scheduleBody={scheduleBody}
                    setScheduleBody={(prop) => {
                      setScheduleBody((prev) => ({ ...prev, ...prop }))
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
      <div className="modal-footer">
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            cleanScheduleAndCloseModal()
          }}
          disabled={saving}
        >
          Cancelar
        </Button>
        <Button
          color="success"
          type="button"
          onClick={(e) => saveSchedule()}
          disabled={
            saving ||
            !scheduleBody.address ||
            !scheduleBody.consultant ||
            !scheduleBody.customer ||
            !scheduleBody.date ||
            !scheduleBody.project ||
            !scheduleBody.timeTo ||
            !scheduleBody.timeFrom
          }
        >
          {props.action}
        </Button>
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          action={confirmationModalAction}
          closeModal={() => {
            setConfirmationModalOpen(false)
          }}
          confirm={async () => {
            await api.post("schedule/create", {
              ...scheduleBody,
              user: userId(),
            })
            props.updateList()
            cleanScheduleAndCloseModal()
          }}
          deny={() => {
            setConfirmation(false)
          }}
        />
      </div>
    </Modal>
  )
}
