import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import api from "services/api"
import HollidayForm from "components/Forms/HollidayForm"

const emptyHolliday = {
  date: "",
  reason: "",
  hours: "08:00",
}

function CreateHolliday({
  isOpen,
  closeModal,
  setLoading,
  refetchTable,
  warningAlert,
}) {
  const [hollidayBody, setHollidayBody] = useState(emptyHolliday)
  const [saving, setSaving] = useState(false)

  /**
   * saves holliday
   */
  const createHolliday = async () => {
    setLoading(true)
    setSaving(true)
    try {
      const checkDateResponse = await api.post("holliday/search", {
        date: hollidayBody.date,
      })
      if (checkDateResponse.data.length > 0) {
        warningAlert({
          color: "danger",
          message: "Já existe um feriado cadasrado nessa data",
        })

        return
      }

      await api.post("holliday/create", hollidayBody)
      refetchTable()
      closeModal()
      warningAlert({
        color: "success",
        message: `Feriado de ${hollidayBody.reason} cadastrado`,
      })
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    } finally {
      setLoading(false)
      setSaving(false)
    }
  }
  useEffect(() => {}, [])

  const formValid = useMemo(
    () => hollidayBody.date !== "" && hollidayBody.reason !== "",
    [hollidayBody]
  )

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isOpen}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">Criar Feriado</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => closeModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <HollidayForm
                    hollidayBody={hollidayBody}
                    setHollidayProp={(prop) =>
                      setHollidayBody((prev) => ({ ...prev, ...prop }))
                    }
                  ></HollidayForm>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
        <div className="modal-footer">
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={(e) => {
              setHollidayBody(emptyHolliday)
              closeModal()
            }}
          >
            Cancelar
          </Button>
          <Button
            color="success"
            type="button"
            disabled={saving || !formValid}
            onClick={(e) => {
              setHollidayBody(emptyHolliday)
              createHolliday()
            }}
          >
            Salvar
          </Button>
        </div>
      </Modal>
      {alert}
    </>
  )
}

CreateHolliday.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
}
export default CreateHolliday
