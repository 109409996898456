import React, { useEffect } from 'react'
import { Col, Form, Input } from 'reactstrap'
import { minutesToHours } from 'utils/timeHandling'
import { hoursToMinutes } from 'utils/timeHandling'

export default function AppointmentForm (props) {
  function checkHours (hoursInput) {
    const totalMinutes = hoursToMinutes(hoursInput)
    if (totalMinutes > 1440) {
      alert('Apontamento não pode ter mais do que 24 horas')
      props.setAppointmentBody({ hours: '00:00' })
      return false
    } else if (props.activityRemainingMinutes - totalMinutes < 0 && props.activity.hoursSettled) {
      alert('Não há saldo de horas o sufucuente para apontar esse valor')
      props.setAppointmentBody({ hours: '00:00' })
      return false
    } else {
      return true
    }
  }

  return (
    <Form className='needs-validation' noValidate autoComplete='off'>
      <div className='form-row'>
        {/* Date */}
        <Col className='mb-3' lg='6' md='12'>
          <label className='form-control-label' htmlFor='date'>
            Data
          </label>
          <Input
            id='date'
            type='date'
            value={props.appointmentBody.date}
            readOnly={true}
            disabled={true}
          />
        </Col>
        <Col className='mb-3' xs='12' md='6'>
          <label className='form-control-label' htmlFor='hours'>
            Horas
          </label>
          <Input
            aria-describedby='inputGroupPrepend'
            id='hours'
            type='time'
            value={props.appointmentBody.hours}
            readOnly={props.readOnly}
            disabled={props.saving}
            valid={props.appointmentBody.hours !== ''}
            invalid={props.appointmentBody.hours === ''}
            onChange={e => {
              if (checkHours(e.target.value)) {
                props.setAppointmentBody({ hours: e.target.value })
              }
            }}
          />
          <div className='invalid-feedback'>Digite uma hora.</div>
        </Col>
        {/* Descriptio  */}
        <Col className='mb-3' md='12'>
          <label className='form-control-label' htmlFor='description'>
            Descrição
          </label>
          <Input
            aria-describedby='inputGroupPrepend'
            id='description'
            type='textarea'
            value={props.appointmentBody.description}
            readOnly={props.readOnly}
            disabled={props.saving}
            valid={props.appointmentBody.description !== ''}
            invalid={props.appointmentBody.description === ''}
            onChange={e => {
              props.setAppointmentBody({ description: e.target.value })
            }}
          />
          <div className='invalid-feedback'>Digite uma Descrição</div>
        </Col>
        {/* Date */}
      </div>
    </Form>
  )
}
