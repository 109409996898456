import api from "services/api"
import LightHeader from "components/Headers/LightHeader"
import Loading from "components/Modals/Loading"
import React, { useState, useEffect, useCallback } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap"
import { BrowserView, MobileView } from "react-device-detect"
import MenuAccessTable from "components/Tables/admin/MenuAccessTable"
/*
  TODO:
  fix the get all user method to get schedules with all inner data
*/
export default function MenuAccess(props) {
  //component
  const [menuAccesses, setMenuAccesses] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [createMenuAccessModalOpen, setCreateMenuAccesModalOpen] =
    useState(false)

  const fetchMenuAccesses = useCallback(async () => {
    setIsLoading(true)
    const customersResponse = await api.get("/menu/all")
    setIsLoading(false)
    setMenuAccesses(customersResponse.data)
  }, [])

  useEffect(() => {
    fetchMenuAccesses()
  }, [fetchMenuAccesses])

  return (
    <>
      <LightHeader
        title="Perfil de Acesso"
        parent="Cadastros"
        menuTitle="Perfil de acesso"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Perfis de Acesso</h3>
                  </Col>
                  <Col xs="6" style={{ textAlignLast: "end" }}>
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      onClick={(e) => {
                        setCreateMenuAccesModalOpen(true)
                      }}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Novo Acesso</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody
                style={{
                  overflowX: "auto",
                  padding: "0px 0px 0px 0px",
                  height: "65vh",
                }}
              >
                <Row>
                  <MenuAccessTable
                    createMenuAccessModalOpen={createMenuAccessModalOpen}
                    setCreateMenuAccesModalOpen={(value) => {
                      setCreateMenuAccesModalOpen(value)
                    }}
                    refetchAccesses={() => {
                      fetchMenuAccesses()
                    }}
                    menuAccesses={menuAccesses}
                  />
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
        <Loading modalOpened={isLoading} />
      </Container>
    </>
  )
}
