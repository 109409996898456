import React, { useCallback, useMemo } from "react"
import { Button, Col, Input, Row } from "reactstrap"
import Select2 from "react-select2-wrapper"

function MobileTableHeader(props) {
  const scheduleBetweenDates = useCallback(
    (schedule) => {
      let scheduleDate = new Date(schedule.date?.split("T")[0])
      return (
        scheduleDate <= new Date(props.to) &&
        scheduleDate >= new Date(props.from)
      )
    },
    [props.to, props.from]
  )
  const schedules = useMemo(() => {
    let schedulesCounter = { approved: 0, toApprove: 0, rejected: 0, total: 0 }

    for (const user of props.users) {
      for (const schedule of user.schedules.filter((schedule) =>
        scheduleBetweenDates(schedule)
      )) {
        schedulesCounter.total++
        if (schedule.status === "AP") schedulesCounter.approved++
        else if (schedule.status === "PA") schedulesCounter.toApprove++
        else if (schedule.status === "RJ") schedulesCounter.rejected++
      }
    }
    return schedulesCounter
  }, [props.users, scheduleBetweenDates])
  return (
    <Row>
      <Col xs="4">
        <h3 className="mb-0">Agenda Equipe</h3>
      </Col>
      <Col className="" xs="2">
        <label className="form-control-label" htmlFor="from">
          De
        </label>
        <Input
          id="from"
          type="date"
          value={props.from}
          onChange={(e) => {
            props.setFrom(e.target.value)
          }}
        />
      </Col>
      <Col className="pb-2" xs="2">
        <label className="form-control-label" htmlFor="from">
          Até
        </label>
        <Input
          id="to"
          type="date"
          value={props.to}
          onChange={(e) => {
            props.setTo(e.target.value)
          }}
        />
      </Col>
      <Col className="pb-2" xs="2">
        <label className="form-control-label" htmlFor="from">
          Status
        </label>
        <Select2
          id="consultant"
          type="text"
          value={props.status}
          onChange={(e) => props.setStatus(e.target.value)}
          options={{
            placeholder: "Selecione um status",
          }}
          data={[
            { id: "all", text: `Todos (${schedules.total}) ` },
            { id: "AP", text: `Aprovadas (${schedules.approved})` },
            { id: "PA", text: `Á aprovar (${schedules.toApprove})` },
            { id: "RJ", text: `Rejeitadas (${schedules.rejected})` },
          ]}
        />
      </Col>

      <Col xs="2">
        <Row className="mb-2" style={{ justifyContent: "end" }}>
          <Button
            className="btn-round btn-icon"
            color="primary"
            onClick={(e) => props.handleCreateSchedulesModal(true)}
            size="sm"
          >
            <span className="btn-inner--icon mr-1">
              <i className="fas fa-plus" />
            </span>
            <span className="btn-inner--text">Nova Agenda</span>
          </Button>
        </Row>
        <Row style={{ justifyContent: "end" }}>
          <Button
            className="btn-round btn-icon"
            color="primary"
            onClick={(e) => props.handleLogModal(true)}
            size="sm"
          >
            <span className="btn-inner--icon mr-1">
              <i className="fas fa-info" />
            </span>
            <span className="btn-inner--text">Logs</span>
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default MobileTableHeader
