import "moment/locale/pt-br"
import React, { useCallback, useEffect, useState } from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import Select2 from "react-select2-wrapper"

import { Col, Modal, ModalBody, Row, Table } from "reactstrap"
import api from "services/api"
import { convertMongoDateAndHour } from "utils/dateHandling"

export default function Log(props) {
  const [filter, setFilter] = useState("Todos")
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState([])

  /**
   * gets the local by id
   * @param {*} id
   * @returns {Promise}
   */
  async function getLocal(id) {
    try {
      const local = await api.get(`/customerAddress/read/${id}`)
      return local.data
    } catch (error) {}
  }
  /**
   * getsProjectById
   * @param {*} id
   * @returns {Promise}
   */
  async function getProject(id) {
    try {
      const project = await api.get(`/project/read/${id}`)
      return project.data
    } catch (error) {}
  }
  /**
   * getsProjectById
   * @param {*} id
   * @returns {Promise}
   */
  async function getUser(id) {
    try {
      const user = await api.get(`/user/read/${id}`)
      return user.data
    } catch (error) {}
  }
  const fillLogs = useCallback(async () => {
    setLoading(true)

    try {
      let logs = []
      if (filter === "Todos") {
        logs = props.schedule.log.map((log) => {
          return log
        })
      } else {
        logs = props.schedule.log.filter((log) => {
          return log.operation === filter
        })
      }

      for (let log of logs) {
        log.user = log.user._id ? log.user : await getUser(log.user)
        log.observation.local = log.observation.local._id
          ? log.observation.local
          : await getLocal(log.observation.local)
        log.observation.project = log.observation.project._id
          ? log.observation.project
          : await getProject(log.observation.project)
      }
      setLogs(logs)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [filter, props.schedule])

  useEffect(() => {
    fillLogs()
  }, [fillLogs])

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody>
        <Row>
          <Col className="" xs="6">
            <label className="form-control-label" htmlFor="consultant">
              Filtro
            </label>
            <Select2
              id="consultant"
              type="text"
              value={filter}
              onSelect={(e) => {
                setFilter(e.target.value)
              }}
              options={{
                hidden: true,
                placeholder: "Selecione um consultor",
                language: {
                  noResults: function () {
                    return "Nenhum consultor encontrado."
                  },
                },
              }}
              data={["Todos", "Criado", "Editado", "Deletado"]}
            ></Select2>
          </Col>
        </Row>
        {loading ? (
          <center>
            <Skeleton
              count={5}
              width="95%"
              height={30}
              borderRadius="5px"
              inline
            />
          </center>
        ) : (
          <Col xs={12} style={{ marginTop: "5vh", overflowX: "auto" }}>
            <Table>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Usuário</th>
                  <th>Operação</th>
                  <th>Observação</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((entry, index) => {
                  return (
                    <tr key={index}>
                      <td>{convertMongoDateAndHour(entry.date)}</td>
                      <td>{entry.user?.fullName}</td>
                      <td>{entry.operation}</td>
                      <td>
                        <Col xs={12}>
                          Local: {entry.observation.local?.address}
                        </Col>
                        <Col
                          xs={12}
                          style={{ width: "100%", whiteSpace: "initial" }?.name}
                        >
                          {entry.observation.time}
                        </Col>
                        <Col xs={12}>
                          Projeto: {entry.observation.project?.name}
                        </Col>
                        <Col xs={12}>
                          Atividade: {entry.observation.activity}
                        </Col>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        )}
      </ModalBody>
    </Modal>
  )
}
