import React from "react"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
  CardHeader,
  Table,
} from "reactstrap"
import InputMask from "react-input-mask"
import Moment from "moment"

class CreateInstallments extends React.Component {
  previewInstallments = (installments) => {
    return installments.map((prop, key) => {
      return (
        <tr key={key}>
          <td>{Moment(prop.docDate).format("DD/MM/YYYY")}</td>
          <td>{Moment(prop.dueDate).format("DD/MM/YYYY")}</td>
          <td>{prop.hours}</td>
          <td>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(prop.amount)}
          </td>
        </tr>
      )
    })
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalCreateInstallmentsOpen}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createInstallments">
              Gerar Parcelas do Contrato
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModalCreateInstallments(e)}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <Row>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="createInstallmentsDateFrom"
                          >
                            Emissão Inicial
                          </label>
                          <Input
                            autoFocus
                            size={"sm"}
                            id="createInstallmentsDateFrom"
                            type="date"
                            value={this.props.createInstallmentsDateFrom}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .createInstallmentsDateFromState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .createInstallmentsDateFromState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.createPreviewInstallments(
                                e,
                                "createInstallmentsDateFrom"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a data de emissão inicial.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="createInstallmentsFirstDueDate"
                          >
                            Primeiro Vencimento
                          </label>
                          <Input
                            size={"sm"}
                            id="createInstallmentsFirstDueDate"
                            type="date"
                            value={this.props.createInstallmentsFirstDueDate}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .createInstallmentsFirstDueDateState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .createInstallmentsFirstDueDateState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.createPreviewInstallments(
                                e,
                                "createInstallmentsFirstDueDate"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a data do primeiro vencimento.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="createInstallmentsTotalAmount"
                          >
                            Valor (R$)
                          </label>
                          <Input
                            size={"sm"}
                            id="createInstallmentsTotalAmount"
                            placeholder="0.00"
                            type="number"
                            value={this.props.createInstallmentsTotalAmount}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .createInstallmentsTotalAmountState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .createInstallmentsTotalAmountState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.createPreviewInstallments(
                                e,
                                "createInstallmentsTotalAmount"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o valor.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="createInstallmentsHours"
                          >
                            Horas
                          </label>
                          <Input
                            size={"sm"}
                            id="createInstallmentsHours"
                            placeholder="0"
                            type="text"
                            mask="999:99"
                            tag={InputMask}
                            value={this.props.createInstallmentsHours}
                            disabled={
                              this.props.saving || this.props.hourlyRate === 0
                            }
                            valid={
                              this.props.customStyles
                                .createInstallmentsHoursState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .createInstallmentsHoursState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.createPreviewInstallments(
                                e,
                                "createInstallmentsHours"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Qtde. horas/minutos inválida.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="createInstallmentsQty"
                          >
                            Parcelas
                          </label>
                          <Input
                            size={"sm"}
                            id="createInstallmentsQty"
                            placeholder="0"
                            type="number"
                            value={this.props.createInstallmentsQty}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .createInstallmentsQtyState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .createInstallmentsQtyState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.createPreviewInstallments(
                                e,
                                "createInstallmentsQty"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o número de parcelas.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="contractInstallmentsRecurrence"
                          >
                            Valor Recorrente ?
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                type="checkbox"
                                checked={
                                  this.props.contractInstallmentsRecurrence
                                }
                                disabled={this.props.saving}
                                onChange={(e) =>
                                  this.props.createPreviewInstallments(
                                    e,
                                    "contractInstallmentsRecurrence",
                                    true
                                  )
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                  <CardHeader>
                    <Row>
                      <Col xs="6">
                        <h3 className="mb-0">{"Preview (Novas Parcelas)"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Emissão</th>
                        <th>Vencimento</th>
                        <th>Horas</th>
                        <th>{"Valor (R$)"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.previewInstallments(this.props.previewInstallments)}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModalCreateInstallments(e)}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.createInstallments(e)}
              disabled={this.props.saving}
            >
              Gerar Parcelas
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default CreateInstallments
