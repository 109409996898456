import api from "services/api"
import LightHeader from "components/Headers/LightHeader"
import Loading from "components/Modals/Loading"
import React, { useState, useMemo, useEffect } from "react"
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap"
import Schedule from "components/Modals/admin/Schedule"
import { BrowserView, MobileView } from "react-device-detect"
import MobileSchedulesTable from "components/SchedulesTableMobile/admin/MobileSchedulesTable"
import MobileSchedulesTableHeader from "components/SchedulesTableMobile/admin/MobileSchedulesTableHeader"
import Logs from "components/Modals/Logs"
import CreateSchedule from "components/Modals/create/CreateSchedule"
import SchedulesTable from "components/Tables/admin/SchedulesTable"
import SchedulesTableHeader from "components/TableHeaders/admin/SchedulesTableHeader"
import ReactBSAlert from "react-bootstrap-sweetalert"

/*
  TODO:
  fix the get all user method to get schedules with all inner data
*/
const now = new Date()
export default function Schedules(props) {
  //component
  const [customers, setCustomers] = useState([])
  const [projects, setProjects] = useState([])
  const [adresses, setAddresses] = useState([])
  const [users, setUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [status, setStatus] = useState("all")
  const [columns, setColumns] = useState([])
  const [loading, setloading] = useState(false)

  //consultant used on mobile table
  const [mobileConsultant, setMobileConsultant] = useState("")

  //Schedule modal
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)
  const [createScheduleModalOpen, setCreateScheduleModalOpen] = useState(false)
  const [selectedSchedule, setSelectedSchedule] = useState({})

  //Logs Modal
  const [logsModalOpen, setLogsModalOpen] = useState(false)

  async function getUsersAndSchedules() {
    try {
      setloading(true)

      const usersResponse = await api.get("/user/all-with-schedules")

      const consultants = usersResponse.data
      //Sets users with deleted schedules
      setAllUsers(consultants)

      let newUsers = consultants.map((consultant) => {
        const schedules = consultant.schedules.filter((schedule) => {
          return schedule.deleted === "N"
        })
        return {
          ...consultant,
          schedules: schedules,
        }
      })

      setUsers(newUsers)

      const customersResponse = await api.get("/customer/all")
      setCustomers(customersResponse.data)

      const projectsResponse = await api.get("/project/all")
      setProjects(projectsResponse.data)

      const adressResponse = await api.get("/customerAddress/all")
      setAddresses(adressResponse.data)
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  useMemo(async () => {
    await getUsersAndSchedules()
    setFrom(
      new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split("T")[0]
    )
    setTo(
      new Date(now.getFullYear(), now.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0]
    )
  }, [])

  function yearsAppartTooBig(to, from) {
    if (!to || !from) return false

    let yearTo = parseInt(to.split("-")[0])
    let yearFrom = parseInt(from.split("-")[0])
    let result = yearTo - yearFrom > 2 || yearTo - yearFrom < 0
    return result
  }

  useEffect(() => {
    if (!yearsAppartTooBig(to, from)) {
      for (
        var arr = [], dt = new Date(from + " 00:00");
        dt <= new Date(to + " 00:00");
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push({
          day: new Date(dt).toLocaleDateString("pt-BR", {
            month: "numeric",
            day: "numeric",
          }),
          weekday: new Date(dt).toLocaleDateString("pt-BR", {
            weekday: "short",
          }),
          fullDate: new Date(dt).toISOString(),
        })
      }
      setColumns(arr)
    }
  }, [to, from])

  return (
    <>
      <LightHeader
        title="Agenda Equipe"
        parent="Cadastros"
        menuTitle="Agendas"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <BrowserView>
                  <SchedulesTableHeader
                    users={users}
                    to={to}
                    from={from}
                    status={status}
                    setTo={(to) => {
                      setTo(to)
                    }}
                    setFrom={(from) => {
                      setFrom(from)
                    }}
                    setStatus={(status) => {
                      setStatus(status)
                    }}
                    handleCreateSchedulesModal={(value) => {
                      setCreateScheduleModalOpen(value)
                    }}
                    handleLogModal={(value) => {
                      setLogsModalOpen(value)
                    }}
                  />
                </BrowserView>
                <MobileView>
                  <MobileSchedulesTableHeader
                    consultants={users}
                    to={to}
                    from={from}
                    status={status}
                    handleCreateSchedulesModal={(value) => {
                      setCreateScheduleModalOpen(value)
                    }}
                    setMobileConsultant={(con) => {
                      const consultant = users.find((user) => user._id === con)
                      setMobileConsultant(consultant)
                    }}
                    setTo={(to) => {
                      setTo(to)
                    }}
                    setFrom={(from) => {
                      setFrom(from)
                    }}
                    setStatus={(status) => {
                      setStatus(status)
                    }}
                  />
                </MobileView>
              </CardHeader>
              <CardBody
                style={{
                  overflowX: "auto",
                  padding: "0px 0px 0px 0px",
                  height: "65vh",
                }}
              >
                <BrowserView>
                  <SchedulesTable
                    status={status}
                    columns={columns ?? []}
                    customers={customers ?? []}
                    projects={projects ?? []}
                    adresses={adresses ?? []}
                    users={users ?? []}
                    handleScheduleModal={(value) => {
                      setScheduleModalOpen(value)
                    }}
                    handleSelectSchedule={(schedule) => {
                      setSelectedSchedule(schedule)
                    }}
                  />
                </BrowserView>
                <MobileView>
                  {mobileConsultant.schedules && (
                    <MobileSchedulesTable
                      customers={customers}
                      schedules={mobileConsultant.schedules.filter((sh) => {
                        let date = sh.date.split("T")[0]
                        if (status === "all") return date <= to && date >= from
                        return (
                          date <= to && date >= from && sh.status === status
                        )
                      })}
                      handleScheduleModal={(value) => {
                        setScheduleModalOpen(value)
                      }}
                      handleSelectSchedule={(schedule) => {
                        setSelectedSchedule(schedule)
                      }}
                    />
                  )}
                </MobileView>
              </CardBody>
            </Card>
          </div>
        </Row>

        <Loading modalOpened={props.isLoading} />
        {allUsers && (
          <Logs
            title={"Logs"}
            isOpen={logsModalOpen}
            consultants={allUsers}
            customers={customers}
            closeModal={() => {
              setLogsModalOpen(false)
            }}
          />
        )}

        <CreateSchedule
          isOpen={createScheduleModalOpen}
          title="Nova Agenda"
          action="Criar"
          updateList={() => {
            getUsersAndSchedules()
          }}
          closeModal={() => {
            setCreateScheduleModalOpen(false)
          }}
        />
        <Schedule
          isOpen={scheduleModalOpen}
          userSchedules={
            users.find((user) => user._id === selectedSchedule.consultant)
              ?.schedules
          }
          title="Editar Agenda"
          action="Salvar"
          schedule={selectedSchedule}
          getUsersAndSchedules={() => getUsersAndSchedules()}
          updateList={() => {
            getUsersAndSchedules()
          }}
          closeModal={() => {
            setScheduleModalOpen(false)
          }}
        />
      </Container>
      <Loading modalOpened={loading} />
    </>
  )
}
