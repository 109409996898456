import React from "react"
import { Button, Col, Input, Row } from "reactstrap"

function ServiceDeskTableHeader(props) {
  return (
    <>
      <Row>
        <Col className="" xs="2">
          <label className="form-control-label" htmlFor="from">
            De
          </label>
          <Input
            id="from"
            type="date"
            value={props.from}
            onChange={(e) => {
              props.setFrom(e.target.value)
            }}
          />
        </Col>
        <Col className="pb-2" xs="2">
          <label className="form-control-label" htmlFor="from">
            Até
          </label>
          <Input
            id="to"
            type="date"
            value={props.to}
            onChange={(e) => {
              props.setTo(e.target.value)
            }}
          />
        </Col>
        <Col lg="2 mt-3">
          <Button
            type="button"
            id="authorizeAll"
            className="mt-3"
            onClick={(e) => props.fetchContracts()}
          >
            Filtrar
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ServiceDeskTableHeader
