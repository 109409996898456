import React from "react";
import PendenciesDetailTable from "../PendenciesDetailTable";
import PropTypes from "prop-types";

function UserPendenciesTable(props) {
  return (
    <div style={{ overflowX: "scroll" }}>
      {(props.pendingPointings || props?.pendingSchedulings) && (
        <PendenciesDetailTable
          pendency={{
            consultant: props.pendingPointings[0].consultant,
            pointing: props.pendingPointings,
            schedules: props.pendingPointings,
          }}
        />
      )}
    </div>
  );
}
UserPendenciesTable.propTypes = {
  pendingPointings: PropTypes.array.isRequired,
  pendingSchedulings: PropTypes.array.isRequired,
};
export default UserPendenciesTable;
