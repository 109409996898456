import AppointmentForm from "components/Forms/AppointmentForm"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import api from "services/api"
import { userId } from "services/auth"
import { hoursToMinutes } from "utils/timeHandling"

const emptyAppointment = {
  activity: "",
  description: "",
  hours: "00:00",
  cost: 0.0,
  hourlyRate: 0.0,
}

function CreateAppointment(props) {
  const [appointmentBody, setAppointmentBody] = useState(emptyAppointment)
  const [saving, setSaving] = useState(false)

  /**
   * closes modal
   */
  function closeModal() {
    props.closeModal()
  }

  /**
   * saves appointment
   */
  const createAppointment = async () => {
    setSaving(true)
    appointmentBody.osCreated = false
    appointmentBody.activity = props.activity._id
    appointmentBody.schedule = props.schedule._id

    const consultantResponse = await api.get("user/read/" + userId())
    const consultant = consultantResponse.data
    const data = {
      ...appointmentBody,
      consultant: userId(),
      hourlyRate: consultant.hourlyRate,
      cost:
        (consultant.hourlyRate * hoursToMinutes(appointmentBody.hours)) / 60,
    }
    try {
      await api.post(`appointment/create`, data)
      props.updateActivities()
      closeModal()
    } catch (error) {
      alert("Erro ao salvar o apontamento")
      closeModal()
    } finally {
      setSaving(false)
    }
  }
  useEffect(() => {
    setAppointmentBody({ ...emptyAppointment, date: props.schedule.date })
  }, [props.schedule])

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardBody>
                <AppointmentForm
                  activity={props.activity}
                  appointmentBody={appointmentBody}
                  activityRemainingMinutes={props.activityRemainingMinutes}
                  setAppointmentBody={(prop) => {
                    setAppointmentBody((prev) => ({ ...prev, ...prop }))
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
      <div className="modal-footer">
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            closeModal()
          }}
        >
          Cancelar
        </Button>
        <Button
          color="success"
          type="button"
          disabled={saving}
          onClick={(e) => createAppointment()}
        >
          {props.action}
        </Button>
      </div>
    </Modal>
  )
}
export default CreateAppointment
