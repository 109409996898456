import MenuAccessForm from 'components/Forms/MenuAccessForm'
import 'moment/locale/pt-br'
import React, {useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { Button, Card, CardBody, Modal } from 'reactstrap'
import api from 'services/api'

import ConfirmationModal from '../ConfirmationModal'

const emptyMenuAccess ={
  name: "", code : "", menu:{ 
  users:false,
  services:false,
  clients:false,
  contracts:false,
  projects:false,
  schedules:false,
  serviceOrders: false,
  billingsRelease: false,
  billings: false,
  menuAccess:false
}}

export default function CreateMenuAccess (props) {
  const [saving, setSaving] = useState(false)
  const [menuAccessBody, setMenuAccessBody] = useState(emptyMenuAccess)
  
  const create = async ()=>{
    setSaving(true)
    try {
      let menuAccessExists = await api.post("menu/search",{code: menuAccessBody.code})
      if(menuAccessExists.data){
        alert("Esse código já está sendo usado")
      }else{
        await api.post("menu/create", menuAccessBody)
        props.refetchAccesses()
        props.closeModal()
      }
    } catch (error) {
      console.error(error)
      alert("ocorreu um erro")
    }
    finally{
      setSaving(false)
    }
  }
 
  return (
    <Modal
      className='modal-dialog-centered'
      size='lg'
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className='modal-header'>
        <h5 className='modal-title'>{props.title}</h5>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={e =>{ 
            setMenuAccessBody(emptyMenuAccess)
            props.closeModal()}}
          disabled={saving}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <Card>
        <CardBody>
          {
            menuAccessBody && (
            <MenuAccessForm
              menuAccessBody={menuAccessBody}
              setMenuAccessBody={(prop)=>{
                setMenuAccessBody(prev=>({...prev, ...prop}))}}
            ></MenuAccessForm>
            )
          }
         
        </CardBody>
      </Card>
        <div className='modal-footer'>
          <Button
            color='warning'
            data-dismiss='modal'
            type='button'
            id='deleteSchedule'
            onClick={() => {
            setMenuAccessBody(emptyMenuAccess)
            }}
          >
            Cancelar
          </Button>
          <Button
            color='success'
            type='button'
            disabled={menuAccessBody.name==="" || menuAccessBody.code===""}
            onClick={e => {
              create()
            }}
           
          >
            {props.action}
          </Button>
        </div>
    </Modal>
  )
}
