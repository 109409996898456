/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class ProfileHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: "500px",
            backgroundImage:
              'url("' +
              require("assets/img/theme/profile-cover.jpg").default +
              '")',
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          <span className="mask bg-gradient-default opacity-8" />

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">
                  {"Olá, " + this.props.headerName}
                </h1>
                <p className="text-white mt-0 mb-5">
                  Essa é a página do seu perfil de usuário. Aqui você poderá
                  alterar seus dados cadastrais e configurar uma imagem de
                  perfil da sua preferência.
                </p>
                <Button
                  color="danger"
                  href="#editProfile"
                  disabled={this.props.editing}
                  onClick={(e) => this.props.editProfile(e)}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                  <span className="btn-inner--text">Editar Perfil</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ProfileHeader;
