import React from "react"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
  CardFooter,
} from "reactstrap"
import InputMask from "react-input-mask"

class Activity extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalActivityOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="activity">
              {this.props.modalActivityTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModalActivity(e)}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <Row>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="activityCode"
                          >
                            Código
                          </label>
                          <Input
                            size={"sm"}
                            id="activityCode"
                            type="text"
                            value={this.props.activityCode}
                            disabled
                          />
                        </Col>
                        <Col className="mb-3" md="9">
                          <label
                            className="form-control-label"
                            htmlFor="activityProcess"
                          >
                            Processo
                          </label>
                          <Input
                            size={"sm"}
                            autoFocus
                            id="activityProcess"
                            type="text"
                            placeholder="Título da atividade/processo"
                            value={this.props.activityProcess}
                            readOnly={
                              this.props.crudActivity === "R" ||
                              this.props.crudActivity === "D"
                            }
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.activityProcessState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.activityProcessState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "activityProcess")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o título da atividade/processo.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="activityStatus"
                          >
                            Status
                          </label>
                          <Input
                            size={"sm"}
                            id="activityStatus"
                            type="select"
                            value={this.props.activityStatus}
                            onChange={(e) =>
                              this.props.stylesForm(e, "activityStatus")
                            }
                          >
                            <option value={null}></option>
                            <option value="P">Pendente</option>
                            <option value="I">Em Andamento</option>
                            <option value="F">Finalizada</option>
                          </Input>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="activityRequester"
                          >
                            Solicitante
                          </label>
                          <Input
                            size={"sm"}
                            id="activityRequester"
                            placeholder="Nome do solicitante"
                            type="text"
                            value={this.props.activityRequester}
                            readOnly={
                              this.props.crudActivity === "R" ||
                              this.props.crudActivity === "D" ||
                              this.props.crudActivity === "U"
                            }
                            disabled={this.props.saving}
                            onChange={(e) =>
                              this.props.stylesForm(e, "activityRequester")
                            }
                          />
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="activityHasActivities"
                          >
                            Possui sub-atividades ?
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                type="checkbox"
                                checked={this.props.activityHasActivities}
                                disabled={
                                  this.props.saving ||
                                  this.props.crudActivity === "R" ||
                                  this.props.crudActivity === "D" ||
                                  this.props.crudActivity === "U"
                                }
                                onChange={(e) =>
                                  this.props.stylesForm(
                                    e,
                                    "activityHasActivities",
                                    true
                                  )
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                  <CardFooter hidden={this.props.activityHasActivities}>
                    <Form>
                      <Row>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="activitySoldHours"
                          >
                            Horas Referência
                          </label>
                          <Input
                            size={"sm"}
                            id="activitySoldHours"
                            placeholder="0"
                            type="text"
                            mask="9999:99"
                            tag={InputMask}
                            value={this.props.activitySoldHours}
                            readOnly={
                              this.props.crudActivity === "R" ||
                              this.props.crudActivity === "D" ||
                              this.props.crudActivity === "U"
                            }
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.activitySoldHoursState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.activitySoldHoursState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "activitySoldHours")
                            }
                          />
                          <div className="invalid-feedback">
                            Qtde. horas/minutos inválida.
                          </div>
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="activityEstimatedHours"
                          >
                            Horas Estimadas
                          </label>
                          <Input
                            size={"sm"}
                            id="activityEstimatedHours"
                            placeholder="0"
                            type="text"
                            mask="9999:99"
                            tag={InputMask}
                            value={this.props.activityEstimatedHours}
                            readOnly
                            disabled={
                              this.props.saving ||
                              this.props.activityHasActivities
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "activityEstimatedHours")
                            }
                          />
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="activityPerformedHours"
                          >
                            Horas Realizadas
                          </label>
                          <Input
                            size={"sm"}
                            id="activityPerformedHours"
                            placeholder="0"
                            type="text"
                            mask="9999:99"
                            tag={InputMask}
                            value={this.props.activityPerformedHours}
                            disabled
                          />
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="activityHoursSettled"
                          >
                            Valida Estimativa ?
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                type="checkbox"
                                checked={this.props.activityHoursSettled}
                                disabled={
                                  this.props.saving ||
                                  this.props.crudActivity === "R" ||
                                  this.props.crudActivity === "D"
                                }
                                onChange={(e) =>
                                  this.props.stylesForm(
                                    e,
                                    "activityHoursSettled",
                                    true
                                  )
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModalActivity(e)}
              disabled={this.props.saving}
            >
              {this.props.crudActivity === "R" ? "Fechar" : "Cancelar"}
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.saveActivity(e)}
              disabled={this.props.saving}
              hidden={this.props.crudActivity === "R"}
            >
              {this.props.crudActivity === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default Activity
