function minutesToHoursInDecimal(time) {
  return parseFloat(Math.round(time / 60).toFixed(2))
}
function minutesToHours(time) {
  if (!time) return "00:00"
  let negative = false
  if (time <= 0) {
    negative = true
    time *= -1
  }
  let hours = Math.floor(time / 60)
  let minutes = time % 60
  const stringHours = hours < 10 ? `0${hours}:` : `${hours}:`
  const stringMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
  if (negative) return "-" + stringHours + stringMinutes
  return stringHours + stringMinutes
}

function hoursToMinutes(time) {
  try {
    let negative = false

    if (typeof time === "number") {
      return time
    }
    if (time[0] === "-") negative = true

    let hours = parseInt(time.split(":")[0]) * 60
    let minutes = parseInt(time.split(":")[1])
    if (negative) return hours - minutes
    return hours + minutes
  } catch (error) {
    console.error(error)
    return 0
  }
}

export { minutesToHours, hoursToMinutes, minutesToHoursInDecimal }
