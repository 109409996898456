import Loading from "components/Modals/Loading"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardHeader } from "reactstrap"
import { convertMongoDate } from "utils/dateHandling"
import { minutesToHours } from "utils/timeHandling"
import { hoursToMinutes } from "utils/timeHandling"
import { useLocation, useParams } from "react-router"

const imageHeader = require("assets/img/brand/marca-original.png").default

const border = {
  borderStyle: "solid",
  borderColor: "black",
  borderWidth: "1px",
  padding: "0px 5px 0px 5px",
}
const blackTimes = {
  fontFamily: "Times New Roman",
  color: "black",
}
const whiteTimes = {
  fontFamily: "Times New Roman",
  color: "white",
}
const tableHeader = {
  backgroundColor: "dimGray",
  color: "white",
  fontSize: "14px",
  border: "1px solid dimGray  ",
  textAlign: "center",
}
const tableCell = {
  color: "black",
  fontSize: "14px",
  border: "1px solid lightGray  ",
  paddingLeft: " 2.5px",
  height: "4vh",
  textAlignLast: "center",
}

//TODO: Make the PDF itself a component
function ServiceOrdersReport(props) {
  /** */
  const [loading, setLoading] = useState(false)
  const [workedHours, setWorkedHours] = useState("00:00")
  const [periodWorkingHours, setPeriodWorkingHours] = useState("00:00")
  const [todayWorkingHours, setTodayWorkingHours] = useState("00:00")
  const [periodBalance, setPeriodBalance] = useState("00:00")
  const [generalBalance, setGeneralBAlance] = useState("00:00")
  const [todayBalance, setTodayBalance] = useState("00:00")
  const [hollidayHours, setHollidayHours] = useState("00:00")
  const search = useLocation().search

  async function printDocument() {
    const pdf = new jsPDF()
    setLoading(true)
    const input = document.getElementById(`header`)
    const tableHeader = document.getElementById(`table-header`)
    const hoursTotal = document.getElementById("hours-total")

    let canvas = await html2canvas(input)
    let imgData = canvas.toDataURL("image/png")
    pdf.addImage(imgData, "PNG", 10, 10, 190, 50)

    canvas = await html2canvas(tableHeader)
    imgData = canvas.toDataURL("image/png")
    pdf.addImage(imgData, "PNG", 10, 65, 190, 7.5)
    const rows = props.userServiceOrders.serviceOrders.length
    let vertical_dislocation = 72.5
    for (let index = 0; index < rows; index++) {
      canvas = await html2canvas(document.getElementById(`row-${index}`))
      imgData = canvas.toDataURL("image/png")
      pdf.addImage(imgData, "PNG", 10, vertical_dislocation, 190, 7.5)
      vertical_dislocation += 7
      if (index % 30 === 0 && index !== 0) {
        pdf.addPage()
        vertical_dislocation = 5
      }
    }
    canvas = await html2canvas(hoursTotal)
    imgData = canvas.toDataURL("image/png")
    pdf.addImage(imgData, "PNG", 10, vertical_dislocation, 190, 63, 5)
    setLoading(false)
    pdf.save(`RELATÓRIO_DE_OS_${props.userServiceOrders.fullName}.pdf`)
  }

  useEffect(() => {
    let periodWorkingMinutes = 0,
      todayWorkingMinutes = 0
    let today = new Date()
    const dateTo = new URLSearchParams(search).get("dateTo")
    const dateFrom = new URLSearchParams(search).get("dateFrom")
    const hollidayMinutes = props.periodHolidays.reduce(
      (acc, curent) => acc + hoursToMinutes(curent.hours),
      0
    )
    for (
      var dt = new Date(dateFrom + " 00:00");
      dt <= new Date(dateTo + " 00:00");
      dt.setDate(dt.getDate() + 1)
    ) {
      let date = new Date(dt)
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        let holliday = props.periodHolidays.find(
          (holliday) =>
            holliday.date.split("T")[0] === date.toISOString().split("T")[0]
        )
        periodWorkingMinutes +=
          480 - (holliday ? hoursToMinutes(holliday.hours) : 0)

        if (date <= today)
          todayWorkingMinutes +=
            480 - (holliday ? hoursToMinutes(holliday.hours) : 0)
      }
    }

    const workedMinutes = props.userServiceOrders.serviceOrders.reduce(
      (acc, curr) => {
        return acc + hoursToMinutes(curr.timeTotal)
      },
      0
    )

    let latestClosingMinutes = 0
    if (props.latestClosing)
      latestClosingMinutes = hoursToMinutes(props.latestClosing.hours)

    setWorkedHours(minutesToHours(workedMinutes))
    setPeriodBalance(minutesToHours(workedMinutes - periodWorkingMinutes))
    setGeneralBAlance(
      minutesToHours(workedMinutes - todayWorkingMinutes + latestClosingMinutes)
    )
    setTodayBalance(minutesToHours(workedMinutes - todayWorkingMinutes))
    setPeriodWorkingHours(minutesToHours(periodWorkingMinutes))
    setTodayWorkingHours(minutesToHours(todayWorkingMinutes))
    setHollidayHours(minutesToHours(hollidayMinutes))
    console.log(
      minutesToHours(hollidayMinutes),
      minutesToHours(todayWorkingMinutes),
      minutesToHours(periodWorkingMinutes),
      minutesToHours(workedMinutes)
    )
  }, [props, search])

  function getDayDifference(time, day, cellIndex) {
    let holliday = props.periodHolidays.find(
      (holliday) => holliday.date.split("T")[0] === day.split("T")[0]
    )
    let sameSoHigherIndex = props.userServiceOrders.serviceOrders.filter(
      (so, index) => so.date === day && index > cellIndex
    )
    if (sameSoHigherIndex?.length > 0) return <></>
    let sameSoLowerIndex = props.userServiceOrders.serviceOrders.filter(
      (so, index) => so.date === day && index < cellIndex
    )
    let result = 0
    if (sameSoLowerIndex.length > 0) {
      for (const serviceOrder of sameSoLowerIndex) {
        result += hoursToMinutes(serviceOrder.timeTotal)
      }
    }
    result += hoursToMinutes(time) - hoursToMinutes(getDayWorkingHours(day))
    if (result < 0)
      return <span style={{ color: "crimson" }}>{minutesToHours(result)}</span>
    else if (result > 0)
      return <span style={{ color: "green" }}>{minutesToHours(result)}</span>
    else return <span>{minutesToHours(result)}</span>
  }
  function getDayWorkingHours(day) {
    let holliday = props.periodHolidays.find(
      (holliday) => holliday.date.split("T")[0] === day.split("T")[0]
    )
    return minutesToHours(480 - (holliday ? hoursToMinutes(holliday.hours) : 0))
  }

  return (
    <Card>
      <CardHeader>
        <Button
          hidden={props.printAll}
          type="button"
          onClick={(e) => {
            printDocument()
          }}
        >
          Baixar
        </Button>
      </CardHeader>
      <CardBody>
        <div
          style={{
            fontFamily: "Times New Roman",
            width: "210mm",
            minHeight: "297mm",
          }}
        >
          {/* header */}
          <div style={{ margin: "auto" }}>
            <div id="header">
              <div
                style={{
                  width: "100%",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                <img
                  src={imageHeader}
                  alt={""}
                  style={{ maxWidth: "60%" }}
                ></img>

                <h1 style={{ ...blackTimes }}> Relação de Ordens de Serviço</h1>
                <h2 style={{ ...blackTimes }}>
                  Referência: De {props.from} Até:{props.to}
                </h2>
              </div>
              <div style={{ backgroundColor: "black" }}>
                <h3 style={{ ...whiteTimes }}>
                  Consultor: {props.userServiceOrders.fullName}
                </h3>
              </div>
            </div>
            <table style={{ width: "100%" }}>
              <thead id="table-header">
                <tr>
                  <th style={{ ...tableHeader, width: "10%" }}>Data</th>
                  <th style={{ ...tableHeader, width: "42%" }}>
                    Cliente-Projeto
                  </th>
                  <th style={{ ...tableHeader, width: "7%" }}>Entrada</th>
                  <th style={{ ...tableHeader, width: "7%" }}>Saída</th>
                  <th style={{ ...tableHeader, width: "7%" }}>Traslado</th>
                  <th style={{ ...tableHeader, width: "7%" }}>Outros</th>
                  <th style={{ ...tableHeader, width: "7%" }}>Total</th>
                  <th style={{ ...tableHeader, width: "7%" }}>Dia</th>
                  <th style={{ ...tableHeader, width: "7%" }}>Diferença</th>
                </tr>
              </thead>
              {props.userServiceOrders.serviceOrders.map(
                (serviceOrder, index) => {
                  return (
                    <tr key={index} id={`row-${index}`}>
                      <td style={{ ...tableCell, textAlign: "left" }}>
                        {convertMongoDate(serviceOrder.date)}
                      </td>
                      <td style={{ ...tableCell, textAlign: "left" }}>
                        {serviceOrder.customer.shortName || "GoLive"} -{" "}
                        {serviceOrder.project.name}
                      </td>
                      <td style={{ ...tableCell, textAlign: "left" }}>
                        {serviceOrder.timeFrom}
                      </td>
                      <td style={{ ...tableCell, textAlign: "left" }}>
                        {serviceOrder.timeTo}
                      </td>
                      <td style={{ ...tableCell, textAlign: "left" }}>
                        {serviceOrder.timeCommute}
                      </td>
                      <td style={{ ...tableCell, textAlign: "left" }}>
                        {serviceOrder.timeOthers}
                      </td>
                      <td
                        style={{
                          ...tableCell,
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {serviceOrder.timeTotal}
                      </td>
                      <td style={{ ...tableCell, textAlign: "left" }}>
                        {getDayWorkingHours(serviceOrder.date)}
                      </td>
                      <td
                        style={{
                          ...tableCell,
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        {getDayDifference(
                          serviceOrder.timeTotal,
                          serviceOrder.date,
                          index
                        )}
                      </td>
                    </tr>
                  )
                }
              )}
            </table>
            <div id="hours-total" style={{ overflow: "auto" }}>
              <div
                style={{
                  ...border,
                  backgroundColor: "#D00000",
                  borderColor: "#D00000",
                  marginTop: "2vh",
                  width: "100%",
                  borderRight: "1px",
                  float: "left",
                  marginBottom: "3vh",
                }}
              >
                <h4 style={{ ...whiteTimes, margin: "auto" }}>TOTAIS:</h4>
              </div>

              <div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "85.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderRight: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4 style={{ ...blackTimes }}>Apontadas:</h4>
                  </div>
                  <div
                    style={{
                      width: "15.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4
                      style={{
                        ...blackTimes,
                        margin: "auto",
                        textAlign: "end",
                      }}
                    >
                      {workedHours}
                    </h4>
                  </div>
                </div>

                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    style={{
                      width: "85.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderRight: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4 style={{ ...blackTimes }}>
                      {" "}
                      Horas úteis do período: ( {props.from} - {props.to} )
                    </h4>
                  </div>
                  <div
                    style={{
                      width: "15.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4
                      style={{
                        ...blackTimes,
                        margin: "auto",
                        textAlign: "end",
                      }}
                    >
                      {periodWorkingHours}
                    </h4>
                  </div>
                </div>

                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    style={{
                      width: "85.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderRight: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4 style={{ ...blackTimes }}>
                      {" "}
                      Diferença do período ( {props.from} - {props.to} )
                    </h4>
                  </div>
                  <div
                    style={{
                      width: "15.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4
                      style={{
                        ...blackTimes,
                        margin: "auto",
                        textAlign: "end",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bolder",
                          color:
                            hoursToMinutes(periodBalance) > 0
                              ? "green"
                              : hoursToMinutes(periodBalance) < 0
                              ? "crimson"
                              : "black",
                        }}
                      >
                        {" "}
                        {periodBalance}
                      </span>
                    </h4>
                  </div>
                </div>

                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    style={{
                      width: "85.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderRight: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4 style={{ ...blackTimes }}>
                      Horas úteis até hoje: ( {props.from} -
                      {new Date().toLocaleDateString("pt-BR")} )
                    </h4>
                  </div>
                  <div
                    style={{
                      width: "15.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4
                      style={{
                        ...blackTimes,
                        margin: "auto",
                        textAlign: "end",
                      }}
                    >
                      {todayWorkingHours}
                    </h4>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    margin: "0px",
                  }}
                >
                  <div
                    style={{
                      width: "85.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderRight: "none",
                      borderColor: "lightgray",
                      borderBottom: "none",

                      borderWidth: "1px",
                    }}
                  >
                    <h4 style={{ ...blackTimes }}>Feriados </h4>
                  </div>
                  <div
                    style={{
                      width: "15.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderColor: "lightgray",
                      borderBottom: "none",
                      borderWidth: "1px",
                    }}
                  >
                    <h4
                      style={{
                        ...blackTimes,
                        margin: "auto",
                        textAlign: "end",
                      }}
                    >
                      {hollidayHours}
                    </h4>
                  </div>
                </div>

                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    style={{
                      width: "85.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderRight: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4 style={{ ...blackTimes }}>
                      Diferenca até hoje: ( {props.from} -
                      {new Date().toLocaleDateString("pt-BR")} )
                    </h4>
                  </div>
                  <div
                    style={{
                      width: "15.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4
                      style={{
                        ...blackTimes,
                        margin: "auto",
                        textAlign: "end",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bolder",
                          color:
                            hoursToMinutes(todayBalance) > 0
                              ? "green"
                              : hoursToMinutes(todayBalance) < 0
                              ? "crimson"
                              : "black",
                        }}
                      >
                        {" "}
                        {todayBalance}
                      </span>
                    </h4>
                  </div>
                </div>
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    style={{
                      width: "85.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderRight: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4 style={{ ...blackTimes }}>Diferença Acumulada</h4>
                  </div>
                  <div
                    style={{
                      width: "15.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderBottom: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4
                      style={{
                        ...blackTimes,
                        margin: "auto",
                        textAlign: "end",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px",
                          fontWeight: "bolder",
                          color:
                            hoursToMinutes(props.latestClosing?.hours) > 0
                              ? "green"
                              : hoursToMinutes(props.latestClosing?.hours) < 0
                              ? "crimson"
                              : "black",
                        }}
                      >
                        {" "}
                        {props.latestClosing
                          ? props.latestClosing.hours
                          : "00:00"}
                      </p>
                    </h4>
                  </div>
                </div>
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    style={{
                      width: "85.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderRight: "none",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4 style={{ ...blackTimes }}>Diferença Total</h4>
                  </div>
                  <div
                    style={{
                      width: "15.0%",
                      float: "right",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                      border: "solid",
                      borderColor: "lightgray",
                      borderWidth: "1px",
                    }}
                  >
                    <h4
                      style={{
                        ...blackTimes,
                        margin: "auto",
                        textAlign: "end",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px",
                          fontWeight: "bolder",
                          color:
                            hoursToMinutes(generalBalance) > 0
                              ? "green"
                              : hoursToMinutes(generalBalance) < 0
                              ? "crimson"
                              : "black",
                        }}
                      >
                        {" "}
                        {generalBalance}
                      </p>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loading isOpen={loading}></Loading>
      </CardBody>
    </Card>
  )
}

export default ServiceOrdersReport
