import React from "react"
import { Nav, Row, TabContent, TabPane, NavLink } from "reactstrap"
import ContractInstallmentsReleaseTable from "./ContractInstallmentsReleaseTable"
import ServiceOrderReleaseTable from "./ServiceOrderReleaseTable"
import classNames from "classnames"

function CreateBillingTables(props) {
  return (
    <>
      <Row className="mt-3 ml-0">
        <h3>
          As seguintes Ordens de Serviço e Parcelas estão sendo consideradas
        </h3>
      </Row>

      <Row>
        <Nav tabs>
          <NavLink
            onClick={() => {
              props.setActiveTab("1")
            }}
            style={{ cursor: "pointer" }}
            className={classNames({
              active: props.activeTab === "1",
            })}
          >
            Ordens de serviço
          </NavLink>
          <NavLink
            onClick={() => {
              props.setActiveTab("2")
            }}
            style={{ cursor: "pointer" }}
            className={classNames({
              active: props.activeTab === "2",
            })}
          >
            Parcelas
          </NavLink>
        </Nav>
      </Row>
      <TabContent activeTab={props.activeTab}>
        <TabPane tabId={"1"} style={{ overflowX: "scroll" }}>
          <ServiceOrderReleaseTable
            serviceOrders={props.serviceOrders}
            readOnly={true}
          />
        </TabPane>
        <TabPane tabId={"2"} style={{ overflowX: "scroll" }}>
          <ContractInstallmentsReleaseTable
            contractInstallments={props.contractInstallments}
            readOnly={true}
          />
        </TabPane>
      </TabContent>
    </>
  )
}

export default CreateBillingTables
