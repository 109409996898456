import LightHeader from 'components/Headers/LightHeader'
import Loading from 'components/Modals/Loading'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import { BrowserView, MobileView } from 'react-device-detect'
import api from 'services/api'
import BillingReleaseTableHeader from 'components/TableHeaders/admin/BillingReleaseTableHeader'
import ServiceOrderReleaseTable from 'components/Tables/admin/ServiceOrderReleaseTable'
import ContractInstallmentsReleaseTable from 'components/Tables/admin/ContractInstallmentsReleaseTable'
/*
  TODO:
  fix the get all user method to get schedules with all inner data
  see hos=w to precent mongo to return whole Cotract installmente if contract is null
*/
export default function BillingsRelease () {
  const [loading, setloading] = useState(false)
  const [filters, setFilters] = useState({})
  const [serviceOrders, setServiceOrders] = useState([])
  const [contractInstallments, setContractInstallments] = useState([])
  const [activeTab, setActiveTab] = useState('1')

  /**
   *
   */
  const save = async () => {
    setloading(true)
    try {
      await Promise.all(
        serviceOrders.map(async so => {
          return await api.patch(`serviceOrder/patch/${so._id}`, {
            authorized: so.authorized
          })
        })
      )
      await Promise.all(
        contractInstallments.map(async ca => {
          return await api.patch(`contractInstallment/patch/${ca._id}`, {
            authorized: ca.authorized
          })
        })
      )
      getServiceOrders()
      getContractInstallments()
    } catch (error) {
      alert('Ocorreu um erro')
    } finally {
      setloading(false)
    }
  }

  /**
   *
   * @param {Object} prop
   */
  const setHeaderFilters = prop => {
    let currentFilters = { ...filters, ...prop }

    setFilters(currentFilters)
  }
  /**
   *
   */
  const authorizeAll = () => {
    const newServiceOrders = serviceOrders.map(so => {
      return {
        ...so,
        authorized: true
      }
    })
    setServiceOrders(newServiceOrders)
  }
  /**
   *
   */
  const unauthorizeAll = () => {
    const newServiceOrders = serviceOrders.map(so => {
      return {
        ...so,
        authorized: false
      }
    })
    setServiceOrders(newServiceOrders)
  }

  /**
   * gets serviceOrder Data from database
   */
  const getServiceOrders = useCallback(async () => {
    if (activeTab === '1') {
      setloading(true)

      let currentFilters = { ...filters }
      delete currentFilters.service
      Object.keys(currentFilters).forEach(key => {
        currentFilters[key] === '0' && delete currentFilters[key]
      })

      const serviceOrdersResponse = await api.post('serviceOrder/search', {
        billed: false,
        ...currentFilters
      })

      const serviceOrders = serviceOrdersResponse.data.filter(so=> {return so.project.contract?.type !== "C"})

      setServiceOrders(serviceOrders)
      setloading(false)
    }
  }, [activeTab, filters])
  /**
   * gets contracts Data from database
   */
  const getContractInstallments = useCallback(async () => {
    setloading(true)
    let currentFilters = { ...filters }
    delete currentFilters.consultant
    Object.keys(currentFilters).forEach(key => {
      currentFilters[key] === '0' && delete currentFilters[key]
    })
    try {
      const contractInstallmentsResponse = await api.post(
        'contractInstallment/deepSearch',
        {
          status: 'P',
          deleted: 'N',
          docDate: { $lte: new Date().toISOString() },
          contract: { ...currentFilters }
        }
      )
      setContractInstallments(
        contractInstallmentsResponse.data.filter(installment => {
          return installment.contract !== null
        })
      )
    } catch (error) {
      setContractInstallments([])
      alert('Ocorreu um erro ao buscar as parcealas')
    } finally {
      setloading(false)
    }
  }, [filters])
  /**
   *
   * @param {Number} index
   */
  const updateAuthorizedServiceOrders = index => {
    let currentServiceOrders = [...serviceOrders]
    currentServiceOrders[index].authorized = !currentServiceOrders[index]
      .authorized
    setServiceOrders(currentServiceOrders)
  }
  /**
   *
   * @param {Number} index
   */
  const updateAuthorizedContractInstallments = index => {
    let currentContractInstallments = [...contractInstallments]
    currentContractInstallments[
      index
    ].authorized = !currentContractInstallments[index].authorized
    setContractInstallments(currentContractInstallments)
  }
  /**
   * hook to get database data
   */
  useEffect(() => {
    getServiceOrders()
    getContractInstallments()
  }, [filters, getContractInstallments, getServiceOrders])

  /**
   * saves authorized/unauthorized service orders
   */

  return (
    <>
      <LightHeader
        title='Liberação de Faturamentos'
        menuTitle='Liberação de Faturamentos'
        parent='Fechamentos'
      />
      <Container className='mt--6' fluid style={{ overFlowY: 'scroll' }}>
        <Row>
          <div className='col'>
            <Card>
              <CardHeader>
                <BrowserView>
                  <BillingReleaseTableHeader
                    filters={filters}
                    save={() => save()}
                    setFilters={prop => setHeaderFilters(prop)}
                    authorizeAll={() => authorizeAll()}
                    unAuthorizeAll={() => unauthorizeAll()}
                    selectTab={tab => setActiveTab(tab)}
                    activeTab={activeTab}
                  />
                </BrowserView>
                <MobileView></MobileView>
              </CardHeader>
              <CardBody
                style={{
                  overflowX: 'auto',
                  padding: '0px 0px 0px 0px'
                }}
              >
                <BrowserView>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={'1'}>
                      <ServiceOrderReleaseTable
                        serviceOrders={serviceOrders}
                        updateAuthorizedServiceOrders={index =>
                          updateAuthorizedServiceOrders(index)
                        }
                      />
                    </TabPane>
                    <TabPane tabId={'2'}>
                      <ContractInstallmentsReleaseTable
                        contractInstallments={contractInstallments}
                        updateAuthorizedServiceOrders={index =>
                          updateAuthorizedContractInstallments(index)
                        }
                      />
                    </TabPane>
                  </TabContent>
                </BrowserView>
                <MobileView></MobileView>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loading modalOpened={loading} />
    </>
  )
}
