function keyFactory(key) {
  switch (key) {
    case "users":
      return "Usuários"
    case "services":
      return "Serviços"
    case "clients":
      return "Clientes"
    case "contracts":
      return "Contratos"
    case "projects":
      return "Projetos"
    case "schedules":
      return "Agendas"
    case "serviceOrders":
      return "Ordens de serviço"
    case "billingsRelease":
      return "Liberação de Faturamentos"
    case "billings":
      return "Faturamentos"
    case "menuAccess":
      return "Perfil de acesso"
    case "appointments":
      return "Apontamentos"
    case "hoursAnalysis":
      return "Análise de horas"
    case "invoicesAnalysis":
      return "Análise de Faturamento"
    case "projectIndicator":
      return "Indicador de Projetos"
    case "resourceCostManagment":
      return "Reajuste de Custo"
    case "thirdPartyAppointment":
      return "Apontamento de Terceiros"
    case "dueContracts":
      return "Vencimento de Contratos"
    case "projectsReport":
      return "Projeto Previsto x Realizado"
    case "serviceDesk":
      return "Análise Service Desk"
    case "consultantClosing":
      return "Banco de Horas"
    case "hollidays":
      return "Feriados"
    default:
      break
  }
}

export { keyFactory }
