import LightHeader from "components/Headers/LightHeader"
import Loading from "components/Modals/Loading"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap"
import { BrowserView, MobileView } from "react-device-detect"
import InvoicesForecastTableHeader from "components/TableHeaders/admin/InvoicesForecastTableHeader.js"
import InvoicesForecastTable from "components/Tables/admin/InvoicesForecastTable"
import api from "services/api"
import { hoursToMinutes } from "utils/timeHandling"
import { minutesToHours } from "utils/timeHandling"

import { transformNumberToCurency } from "utils/currencyHandling"
import { getMidnightThisDay } from "utils/dateHandling"

const now = new Date()

export default function InvoicesAnalysis() {
  const [loading, setloading] = useState(false)
  const [filters, setFilters] = useState({
    to: null,
    from: null,
    grouping: "consul",
  })
  const [grouping, setGrouping] = useState("")
  const [data, setData] = useState([])

  const fetchData = useCallback(async (searchParams) => {
    setloading(true)
    const total = {
      fullName: "TOTAL",
      scheduledTime: 0,
      forseenTime: 0,
      performedTime: 0,
      scheduledCost: 0,
      forseenCost: 0,
      performedCost: 0,
      costOverTime: 0,
    }
    const customerResponse = await api.post(
      "customer/searchForInvoicesForecast ",
      searchParams
    )
    let data = []

    for (const customer of customerResponse.data) {
      let scheduledTime = 0
      let forseenTime = 0
      let performedTime = 0
      let scheduledCost = 0
      let forseenCost = 0
      let performedCost = 0

      try {
        for (const schedule of customer.schedules) {
          let contract = schedule.project[0].contract[0]
          let service = schedule.project[0].contract[0].service[0]

          if (!contract || service.investment) {
            continue
          }

          scheduledTime += hoursToMinutes(schedule.timeTotal)
          scheduledCost +=
            (hoursToMinutes(schedule.timeTotal) / 60) * contract.hourlyRate || 0

          total.scheduledTime += hoursToMinutes(schedule.timeTotal)
          total.scheduledCost +=
            (hoursToMinutes(schedule.timeTotal) / 60) * contract.hourlyRate || 0

          if (!schedule.serviceOrder) {
            forseenTime += hoursToMinutes(schedule.timeTotal)
            forseenCost +=
              (hoursToMinutes(schedule.timeTotal) / 60) * contract.hourlyRate ||
              0

            total.forseenTime += hoursToMinutes(schedule.timeTotal)
            total.forseenCost +=
              (hoursToMinutes(schedule.timeTotal) / 60) * contract.hourlyRate ||
              0

            continue
          }

          for (const appointment of schedule.appointments) {
            if (appointment.osCreated) {
              performedTime += hoursToMinutes(appointment.hours)
              performedCost +=
                (hoursToMinutes(appointment.hours) / 60) *
                  contract.hourlyRate || 0

              total.performedTime += hoursToMinutes(appointment.hours)
              total.performedCost +=
                (hoursToMinutes(appointment.hours) / 60) *
                  contract.hourlyRate || 0

              forseenTime += hoursToMinutes(appointment.hours)
              forseenCost +=
                (hoursToMinutes(appointment.hours) / 60) *
                  contract.hourlyRate || 0

              total.forseenTime += hoursToMinutes(appointment.hours)
              total.forseenCost +=
                (hoursToMinutes(appointment.hours) / 60) *
                  contract.hourlyRate || 0
            }
          }
        }
        data.push({
          name: customer.fullName,
          scheduledTime: minutesToHours(scheduledTime),
          forseenTime: minutesToHours(forseenTime),
          performedTime: minutesToHours(performedTime),
          scheduledCost: transformNumberToCurency(scheduledCost),
          forseenCost: transformNumberToCurency(forseenCost),
          performedCost: transformNumberToCurency(performedCost),
          costOverTime: transformNumberToCurency(
            performedCost / (performedTime / 60) || 0
          ),
        })
      } catch (error) {
        console.error(error)
      }
    }

    setData([
      ...data,
      {
        name: total.fullName,
        scheduledTime: minutesToHours(total.scheduledTime),
        forseenTime: minutesToHours(total.forseenTime),
        performedTime: minutesToHours(total.performedTime),
        scheduledCost: transformNumberToCurency(total.scheduledCost),
        forseenCost: transformNumberToCurency(total.forseenCost),
        performedCost: transformNumberToCurency(total.performedCost),
        costOverTime: transformNumberToCurency(
          total.performedCost / (total.performedTime / 60)
        ),
      },
    ])
    setloading(false)
  }, [])

  async function fetchSchedules() {
    let searchParams = {}
    if (filters.from && !filters.to)
      searchParams.date = {
        $gte: getMidnightThisDay(filters.from),
      }
    if (filters.to && !filters.from)
      searchParams.date = {
        $lte: getMidnightThisDay(filters.to),
      }
    if (filters.to && filters.from)
      searchParams.date = {
        $gte: getMidnightThisDay(filters.from),
        $lte: getMidnightThisDay(filters.to),
      }
    setGrouping(filters.grouping)
    await fetchData(searchParams)
  }

  useEffect(() => {
    let filters = {
      from: new Date(now.getFullYear(), now.getMonth(), 1)
        .toISOString()
        .split("T")[0],
      to: new Date(now.getFullYear(), now.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0],
    }

    setFilters((prev) => ({ ...prev, ...filters }))

    let searchParams = {}

    if (filters.from && !filters.to)
      searchParams.date = { $gte: new Date(`${filters.from}`).toISOString() }
    if (filters.to && !filters.from)
      searchParams.date = {
        $lte: getMidnightThisDay(filters.to),
      }
    if (filters.to && filters.from)
      searchParams.date = {
        $gte: new Date(`${filters.from}`).toISOString(),
        $lte: getMidnightThisDay(filters.to),
      }

    fetchData(searchParams)
  }, [fetchData])

  return (
    <>
      <LightHeader
        title="Análise de Faturamento"
        menuTitle="Análise de Faturamento"
        parent="Relatórios"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <InvoicesForecastTableHeader
                  filters={filters}
                  setFilters={(filter) => {
                    setFilters((prev) => ({ ...prev, ...filter }))
                  }}
                  fetchSchedules={() => fetchSchedules()}
                />
              </CardHeader>
              <CardBody
                style={{
                  padding: "0px 0px 0px 0px",
                  height: "auto",
                }}
              >
                <InvoicesForecastTable
                  grouping={grouping}
                  data={data}
                  hours={false}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loading modalOpened={loading} />
    </>
  )
}
