/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";

class LightHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header header-dark bg-default pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 d-inline-block mb-0 text-white">
                    {this.props.title}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links"
                  >
                    <BreadcrumbItem>
                      <i className="fas fa-home text-danger" />
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <span className="text-danger">{this.props.parent}</span>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      <span className="text-danger">
                        {this.props.menuTitle}
                      </span>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default LightHeader;
