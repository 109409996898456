import React from "react"
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Select2 from "react-select2-wrapper"
import { withRouter } from "react-router"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  CardBody,
  Form,
  Input,
  Badge,
  Table,
} from "reactstrap"

// core components
import LightHeader from "components/Headers/LightHeader.js"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Moment from "moment"

import api from "services/api"
import CreateInstallments from "components/Modals/create/CreateInstallments"
import Loading from "components/Modals/Loading"
import ContractInstallment from "components/Modals/show-edit/ContractInstallment"
import { convertMongoDate } from "utils/dateHandling"
import { getFirstDayOfNextMonth } from "utils/dateHandling"
import { convertMongoDateToDateInput } from "utils/dateHandling"

const contractTypes = {
  O: "Aberto",
  C: "Fechado",
}

const dueDateTypes = {
  I: "Após Faturamento",
  F: "Dia Fixo",
}

const installmentStatus = {
  P: "Pendente",
  I: "Faturada",
}

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 25,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
})
const now = new Date()

const { SearchBar } = Search

class Contracts extends React.Component {
  state = {
    data: [],
    isLoading: true,
    saving: false,
    alert: null,
    dueContractView: false,

    //Contract panel data variables
    branch: null,
    invoiceNotice: null,
    dataPanelTitle: "",
    crud: "",
    readOnly: false,
    id: "",
    name: "",
    customer: "",
    service: "",
    needHourlyRate: false,
    type: "",
    hourlyRate: "0.00",
    dueDateType: "",
    dueDateDay: 0,
    dueDateDayLabel: "",
    dueDateDayMsg: "",
    dueDateSameMonth: false,
    newDueDate: "",
    readjust: false,
    separateInvoice: false,
    installments: [],
    customersList: [],

    //Contract data fields validations
    customStyles: {
      customerState: null,
      serviceState: null,
      nameState: null,
      typeState: null,
      hourlyRateState: null,
      dueDateTypeState: null,
      dueDateDayState: null,
      //Creaate installments
      createInstallmentsDateFromState: null,
      createInstallmentsFirstDueDateState: null,
      createInstallmentsTotalAmountState: null,
      createInstallmentsHoursState: null,
      createInstallmentsQtyState: null,
      //Contract installments
      contractInstallmentDocDateState: null,
      contractInstallmentDueDateState: null,
      contractInstallmentAmountState: null,
      contractInstallmentHoursState: null,
      contractInstallmentStatusState: null,
    },

    //Modal create installments
    modalCreateInstallmentsOpen: false,
    createInstallmentsDateFrom: "",
    createInstallmentsFirstDueDate: "",
    createInstallmentsTotalAmount: 0,
    createInstallmentsQty: 0,
    createInstallmentsHours: "0000:00",
    contractInstallmentsRecurrence: false,
    previewInstallments: [],

    //Modal contract installment
    crudInstallment: "",
    selectedContractInstallment: null,
    modalContractInstallmentOpen: false,
    modalContractInstallmentTitle: "",
    contractInstallmentDocDate: "",
    contractInstallmentDueDate: "",
    contractInstallmentAmount: 0,
    contractInstallmentHours: "0000:00",
    contractInstallmentCriteria: "",
    contractInstallmentAuthorized: false,
    contractInstallmentStatus: "",
  }

  componentDidMount() {
    const { match } = this.props
    this.setState({
      dueContractView:
        match.url === "/admin/due-contracts" ||
        match.url === "/user/due-contracts",
    })

    this.loadData()
    this.loadCustomersList()
    this.loadServicesList()
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
      crud: "",
    })

    const { match } = this.props
    let response = null

    if (
      match.url === "/admin/due-contracts" ||
      match.url === "/user/due-contracts"
    ) {
      response = await api.post("/contract/search", {
        deleted: "N",
        newDueDate: {
          $lt: getFirstDayOfNextMonth(now),
        },
      })
    } else {
      response = await api.post("/contract/search", {
        deleted: "N",
      })
    }

    this.setState({
      data: response.data,
      isLoading: false,
    })
  }

  loadCustomersList = async () => {
    const customers = await api.get("/customer/all")

    var customer = {}
    var customersList = []

    for (let i = 0; i < customers.data.length; i++) {
      customer = {
        id: customers.data[i]._id,
        text: customers.data[i].shortName,
      }

      if (customersList.length === 0) {
        customersList = [customer]
      } else {
        customersList.push(customer)
      }
    }

    this.setState({
      customersList: customersList,
      isLoading: false,
    })
  }

  loadServicesList = async () => {
    const services = await api.get("/service/all")

    var service = {}
    var servicesList = []

    for (let i = 0; i < services.data.length; i++) {
      service = {
        id: services.data[i]._id,
        text: services.data[i].code + " - " + services.data[i].name,
      }

      if (servicesList.length === 0) {
        servicesList = [service]
      } else {
        servicesList.push(service)
      }
    }

    this.setState({
      servicesList: servicesList,
      isLoading: false,
    })
  }

  openDataPanel = async (e, row, crud) => {
    let newState = this.state

    newState.crud = crud

    if (crud === "C") {
      newState.dataPanelTitle = "Novo Contrato"
    } else if (crud === "R") {
      newState.dataPanelTitle = "Visualizando Contrato"
    } else if (crud === "U") {
      newState.dataPanelTitle = "Alterando Contrato"
    } else if (crud === "D") {
      newState.dataPanelTitle = "Excluindo Contrato"
    }

    if (crud === "C") {
      newState.id = ""
      newState.name = ""
      newState.customer = ""
      newState.service = ""
      newState.type = "O"
      newState.hourlyRate = "0.00"
      newState.dueDateType = "I"
      newState.dueDateDay = 0
      newState.dueDateDayLabel = "Dias Vencimento"
      newState.dueDateDayMsg = "Digite o dia do vencimento"
      newState.dueDateSameMonth = false
      newState.newDueDate = ""
      newState.readjust = false
      newState.separateInvoice = false
      newState.installments = []
    } else {
      var newDueDate = null

      if (row.newDueDate && row.newDueDate !== null) {
        newDueDate = row.newDueDate.substring(0, 10)
      }
      newState.readOnly = crud === "R" || crud === "D"
      newState.branch = row.branch
      newState.invoiceNotice = row.invoiceNotice
      newState.id = row._id
      newState.code = row.code
      newState.name = row.name
      newState.customer = row.customer._id
      newState.service = row.service._id
      newState.needHourlyRate = row.service.needHourlyRate
      newState.type = row.type
      newState.hourlyRate = row.hourlyRate
      newState.dueDateType = row.dueDateType
      newState.dueDateDay = row.dueDateDay
      newState.dueDateDayLabel =
        row.dueDateType === "I" ? "Dias Vencimento" : "Dia Vencimento"
      newState.dueDateSameMonth = row.dueDateSameMonth
      newState.newDueDate = newDueDate
      newState.readjust = row.readjust
      newState.separateInvoice = row.separateInvoice

      const response = await api.post("/contractInstallment/search", {
        contract: row._id,
        deleted: "N",
      })
      newState.installments = response.data
    }

    this.setState(newState)
  }

  closeDataPanel(e) {
    this.setState({
      crud: "",
      readOnly: false,
      saving: false,
      id: "",
      name: "",
      customer: "",
      service: "",
      type: "",
      hourlyRate: "0.00",
      dueDateType: "",
      dueDateDay: 0,
      dueDateDayLabel: "",
      dueDateDayMsg: "",
      dueDateSameMonth: false,
      newDueDate: "",
      invoiceNotice: "",
      readjust: false,
      separateInvoice: false,
      installments: [],

      //Contract data fields validations
      customStyles: {
        customerState: null,
        serviceState: null,
        nameState: null,
        typeState: null,
        hourlyRateState: null,
        dueDateTypeState: null,
        dueDateDayState: null,

        //Installment data field validations
      },
    })
  }

  saveContractData = async (e) => {
    this.setState({ saving: true })

    let newState = this.state

    var letSave = true
    var newData = {}
    var success = true

    if (this.state.crud === "D") {
      //In the future add exclusion dependencies validations
      //...

      try {
        //Delete contract installments
        for (let i = 0; i < newState.installments.length; i++) {
          await api.put(
            "/contractInstallment/update/" + newState.installments[i]._id,
            { deleted: "Y" }
          )
        }

        //Delete contract
        await api.put("/contract/update/" + this.state.id, { deleted: "Y" })
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar excluir. Tente novamente.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeDataPanel()
      }
    } else if (this.state.crud === "C" || this.state.crud === "U") {
      //Field content validations
      if (newState.name === "") {
        newState.nameState = "invalid"
        letSave = false
      } else {
        newState.nameState = "valid"
      }

      if (newState.customer === "") {
        newState.customerState = "invalid"
        letSave = false
      } else {
        newState.customerState = "valid"
      }

      if (newState.service === "") {
        newState.serviceState = "invalid"
        letSave = false
      } else {
        newState.serviceState = "valid"
      }

      if (newState.type === "") {
        newState.typeState = "invalid"
        letSave = false
      } else {
        newState.typeState = "valid"
      }

      if (newState.needHourlyRate) {
        if (
          newState.hourlyRate * 1 === 0 ||
          newState.hourlyRate === null ||
          !newState.hourlyRate
        ) {
          newState.hourlyRateState = "invalid"
          letSave = false
        } else {
          newState.hourlyRateState = "valid"
        }
      } else {
        newState.hourlyRateState = "valid"
      }

      if (newState.dueDateType === "") {
        newState.dueDateTypeState = "invalid"
        letSave = false
      } else {
        newState.dueDateTypeState = "valid"
      }

      if (newState.dueDateType === "F") {
        if (newState.dueDateDay * 1 < 1 || newState.dueDateDay * 1 > 31) {
          newState.dueDateDayState = "invalid"
          newState.dueDateDayMsg = "Dia de vencimento inválido"
          letSave = false
        } else {
          newState.dueDateDayState = "valid"
        }
      } else {
        if (newState.dueDateDay * 1 < 1) {
          newState.dueDateDayState = "invalid"
          newState.dueDateDayMsg = "Digite os dias para o vencimento"
          letSave = false
        } else {
          newState.dueDateDayState = "valid"
        }
      }

      this.setState({
        customStyles: newState,
      })

      if (!letSave) {
        this.setState({ saving: false })
        return
      } else {
        var newDueDate = null

        if (this.state.newDueDate && this.state.newDueDate !== "") {
          newDueDate = Moment(this.state.newDueDate).format("YYYY-MM-DD")
        }
        newData = {
          invoiceNotice: this.state.invoiceNotice,
          branch: this.state.branch,
          name: this.state.name,
          customer: this.state.customer,
          service: this.state.service,
          type: this.state.type,
          hourlyRate: this.state.hourlyRate * 1,
          dueDateType: this.state.dueDateType,
          dueDateDay: this.state.dueDateDay * 1,
          dueDateSameMonth: this.state.dueDateSameMonth,
          newDueDate: newDueDate,
          readjust: this.state.readjust,
          separateInvoice: this.state.separateInvoice,
        }

        success = true

        var contractId

        if (this.state.crud === "C") {
          try {
            var respCode = await api.post("/contract/maxCode", {
              deleted: "N",
            })

            let nextCode = 1500

            if (respCode.data.length > 0) {
              nextCode = respCode.data[0].code + 1
            }

            newData = {
              ...newData,
              code: nextCode,
            }

            const newContract = await api.post("/contract/create", newData)

            contractId = newContract.data._id
          } catch (err) {
            this.warningAlert("Houve um problema ao cadastrar o contrato.")
            success = false
            this.setState({ saving: false })
            return
          }
        } else {
          contractId = this.state.id

          try {
            await api.put("/contract/update/" + contractId, newData)
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar alterar. Tente novamente."
            )
            success = false
            this.setState({ saving: false })
            return
          }
        }

        if (success) {
          const respMaxCode = await api.post("/contractInstallment/maxCode", {
            deleted: "N",
            contract: contractId,
          })
          let installmentCode = 0

          if (respMaxCode.data.length > 0) {
            installmentCode = respMaxCode.data[0].code
          }

          //Saving installments data
          for (let i = 0; i < newState.installments.length; i++) {
            if (newState.installments[i].neededAction) {
              let installmentData = {
                contract: contractId,
                docDate: newState.installments[i].docDate,
                dueDate: newState.installments[i].dueDate,
                amount: newState.installments[i].amount,
                hours: newState.installments[i].hours,
                criteria: newState.installments[i].criteria,
                authorized: newState.installments[i].authorized,
                status: newState.installments[i].status,
              }

              if (newState.installments[i].neededAction === "C") {
                installmentCode++
                installmentData = {
                  ...installmentData,
                  code: installmentCode,
                }
                await api.post("/contractInstallment/create", installmentData)
              } else {
                if (newState.installments[i].neededAction === "U") {
                  await api.put(
                    "/contractInstallment/update/" +
                      newState.installments[i]._id,
                    installmentData
                  )
                } else {
                  if (
                    newState.installments[i].code &&
                    newState.installments[i].neededAction === "D"
                  ) {
                    await api.put(
                      "/contractInstallment/update/" +
                        newState.installments[i]._id,
                      { deleted: "Y" }
                    )
                  }
                }
              }
            }
          }

          this.closeDataPanel()
          this.loadData()
        }
      }
    }
  }

  stylesForm = async (e, stateName, checkbox) => {
    if (checkbox) {
      let newCheck = this.state[stateName]
      newCheck = newCheck ? false : true

      this.setState({
        [stateName]: newCheck,
      })
    } else {
      let newState = this.state
      let newCustomStyles = newState.customStyles
      let newValue = e.target.value

      switch (stateName) {
        case "service":
          if (newValue === null || newValue === "") {
            newCustomStyles[stateName + "State"] = "invalid"
            newState.needHourlyRate = false
          } else {
            newCustomStyles[stateName + "State"] = "valid"

            let respService = await api.get("/service/read/" + newValue)

            if (respService.data) {
              newState.needHourlyRate = respService.data.needHourlyRate
            } else {
              newState.needHourlyRate = false
            }
          }

          if (newState.needHourlyRate) {
            if (newState.hourlyRate === null || newState.hourlyRate * 1 < 1) {
              newCustomStyles.hourlyRateState = "invalid"
            } else {
              newCustomStyles.hourlyRateState = "valid"
            }
          } else {
            newCustomStyles.hourlyRateState = "valid"
          }
          break

        case "hourlyRate":
          if (newState.needHourlyRate) {
            if (newValue === null || newValue * 1 < 1) {
              newCustomStyles[stateName + "State"] = "invalid"
            } else {
              newCustomStyles[stateName + "State"] = "valid"
            }
          } else {
            newCustomStyles[stateName + "State"] = "valid"
          }
          break

        case "dueDateDay":
          if (newState.dueDateType === "F") {
            if (newValue * 1 < 1 || newValue * 1 > 31) {
              newCustomStyles.dueDateDayState = "invalid"
            } else {
              newCustomStyles.dueDateDayState = "valid"
            }
          } else {
            if (newValue * 1 < 1) {
              newCustomStyles.dueDateDayState = "invalid"
            } else {
              newCustomStyles.dueDateDayState = "valid"
            }
          }
          break

        case "dueDateType":
          let dueDateDayLabel =
            newValue === "I" ? "Dias Vencimento" : "Dia Vencimento"
          let dueDateDayMsg =
            newValue === "I"
              ? "Digite os dias para o vencimento"
              : "Dia de vencimento inválido"
          this.setState({
            dueDateDayLabel: dueDateDayLabel,
            dueDateDayMsg: dueDateDayMsg,
          })
          break

        case "contractInstallmentDocDate":
          if (newValue !== "") {
            let newDocDate = newValue
            let newFirstDueDate

            if (newState.dueDateType === "F") {
              newFirstDueDate = new Date(
                Moment(newDocDate).format("YYYY-MM") +
                  "-" +
                  String(parseInt(newState.dueDateDay)).padStart(2, "0") +
                  " 01:00:00"
              )

              if (!newState.dueDateSameMonth) {
                newFirstDueDate.setMonth(newFirstDueDate.getMonth() + 1)
              }
            } else {
              newFirstDueDate = new Date(
                Moment(newDocDate).format("YYYY-MM-DD") + " 01:00:00"
              )
              newFirstDueDate.setDate(
                newFirstDueDate.getDate() + parseInt(newState.dueDateDay)
              )
            }
            newState.contractInstallmentDueDate =
              Moment(newFirstDueDate).format("YYYY-MM-DD")

            newState.customStyles.contractInstallmentDueDateState = "valid"
          }
          break

        case "contractInstallmentAmount":
          if (newState.hourlyRate === 0) {
          } else if (newValue * 1 > 0) {
            let decimalHoursAndMinutes = (
              newValue / newState.hourlyRate
            ).toFixed(2)
            let clockHours = String(parseInt(decimalHoursAndMinutes)).padStart(
              3,
              "0"
            )
            let decimalMinutes = String(decimalHoursAndMinutes).replace(
              Number(clockHours) + ".",
              ""
            )
            let clockMinutes = Number("0." + decimalMinutes) * 60

            clockMinutes = clockMinutes.toFixed()
            clockMinutes = String(clockMinutes).padStart(2, "0")

            newState.contractInstallmentHours = clockHours + ":" + clockMinutes
            newState.customStyles.contractInstallmentAmountState = "valid"
          } else {
            newState.customStyles.contractInstallmentAmountState = "invalid"
          }
          break

        case "contractInstallmentHours":
          let clockHours = String(newValue).split(":")[0]
          let clockMinutes = String(newValue).split(":")[1]
          if (newState.hourlyRate === 0) {
          } else if (
            Number(clockMinutes) > 59 ||
            clockMinutes.length < 2 ||
            isNaN(clockMinutes)
          ) {
            newState.contractInstallmentAmount = 0
            newState.customStyles.contractInstallmentHoursState = "invalid"
            newState.customStyles.contractInstallmentAmountState = "invalid"
          } else {
            newState.customStyles.contractInstallmentHoursState = "valid"
            newState.customStyles.contractInstallmentAmountState = "valid"
            let decimalHours = Number(clockHours)
            let decimalMinutes = Number(clockMinutes) / 60

            newState.contractInstallmentAmount =
              this.state.hourlyRate * (decimalHours + decimalMinutes)
          }
          break
        default:
          if (newValue === null || newValue === "") {
            newCustomStyles[stateName + "State"] = "invalid"
          } else {
            newCustomStyles[stateName + "State"] = "valid"
          }
      }

      newState.newCustomStyles = newCustomStyles
      newState[stateName] = newValue

      this.setState({
        newState,
      })
    }
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  getInstallments = (installments) => {
    return installments.map((prop, key) => {
      return prop.neededAction !== "D" ? (
        <tr key={key}>
          <td>
            {"#"} {prop.code ? prop.code : "(nova)"}
          </td>
          <td>{convertMongoDate(prop.docDate)}</td>
          <td>{convertMongoDate(prop.dueDate)}</td>
          <td>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(prop.amount)}
          </td>
          <td>{prop.hours}</td>
          <td>{prop.criteria}</td>
          <td>
            <label className="custom-toggle custom-toggle-default">
              <input checked={prop.authorized} type="checkbox" disabled />
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="Não"
                data-label-on="Sim"
              />
            </label>
          </td>
          <td>
            <Badge color="" className="badge-dot mr-4">
              <i
                className={prop.status === "P" ? "bg-warning" : "bg-success"}
              />
              <span className="status">{installmentStatus[prop.status]}</span>
            </Badge>
          </td>
          <td className="text-right">
            <UncontrolledDropdown
              hidden={this.state.crud === "D" || this.state.crud === "R"}
            >
              <DropdownToggle
                className="btn-icon-only text-light"
                color=""
                role="button"
                size="sm"
                hidden={prop.status === "I"}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  onClick={(e) =>
                    this.openModalContractInstallment(e, "U", prop)
                  }
                >
                  <i className="fas fa-edit" />
                  <span>Alterar</span>
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  onClick={(e) =>
                    this.openModalContractInstallment(e, "D", prop)
                  }
                  hidden={prop.status === "I"}
                >
                  <i className="fas fa-trash" />
                  <span>Excluir</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      ) : null
    })
  }

  openModalCreateInstallments(e) {
    this.setState({
      modalCreateInstallmentsOpen: true,
    })
  }

  closeModalCreateInstallments(e) {
    let newState = this.state

    newState.modalCreateInstallmentsOpen = false
    newState.createInstallmentsDateFrom = ""
    newState.createInstallmentsFirstDueDate = ""
    newState.createInstallmentsTotalAmount = 0
    newState.createInstallmentsQty = 0
    newState.createInstallmentsHours = "0000:00"
    newState.contractInstallmentsRecurrence = false
    newState.previewInstallments = []

    newState.customStyles = {
      modalCreateInstallmentsOpenState: null,
      createInstallmentsDateFromState: null,
      createInstallmentsTotalAmountState: null,
      createInstallmentsQtyState: null,
      createInstallmentsHoursState: null,
    }

    this.setState(newState)
  }

  createInstallments(e) {
    let newState = this.state
    let installments = newState.installments
    let d = new Date()

    for (let i = 0; i < newState.previewInstallments.length; i++) {
      installments.push({
        neededAction: "C",
        _id:
          "NEW_INSTALLMENT_" +
          i +
          "_" +
          d.getHours() +
          d.getMinutes() +
          d.getSeconds(),
        contract: this.state.id,
        docDate: newState.previewInstallments[i].docDate,
        dueDate: newState.previewInstallments[i].dueDate,
        amount: newState.previewInstallments[i].amount,
        hours: newState.previewInstallments[i].hours,
        authorized: true,
        criteria: "",
        status: "P",
      })
    }
    this.setState(newState)
    this.closeModalCreateInstallments()
  }

  createPreviewInstallments(e, stateName, checkbox) {
    let newState = this.state
    let newCustomStyles = newState.customStyles
    let newValue = e.target.value

    if (checkbox) {
      let newCheck = newState[stateName]
      newCheck = newCheck ? false : true

      newState[stateName] = newCheck
    } else {
      if (newValue === null || newValue === "" || newValue * 1 < 1) {
        newCustomStyles[stateName + "State"] = "invalid"
      } else {
        newCustomStyles[stateName + "State"] = "valid"
      }

      newState[stateName] = newValue
    }

    if (stateName === "createInstallmentsDateFrom" && newValue !== "") {
      let newDocDate = newValue
      let newFirstDueDate

      if (newState.dueDateType === "F") {
        newFirstDueDate = new Date(
          Moment(newDocDate).format("YYYY-MM") +
            "-" +
            String(parseInt(newState.dueDateDay)).padStart(2, "0") +
            " 01:00:00"
        )

        if (!newState.dueDateSameMonth) {
          newFirstDueDate.setMonth(newFirstDueDate.getMonth() + 1)
        }
      } else {
        newFirstDueDate = new Date(
          Moment(newDocDate).format("YYYY-MM-DD") + " 01:00:00"
        )
        newFirstDueDate.setDate(
          newFirstDueDate.getDate() + parseInt(newState.dueDateDay)
        )
      }
      newState.createInstallmentsFirstDueDate =
        Moment(newFirstDueDate).format("YYYY-MM-DD")

      newState.customStyles.createInstallmentsFirstDueDateState = "valid"
    }

    if (stateName === "createInstallmentsTotalAmount") {
      if (this.state.hourlyRate !== 0) {
        let decimalHoursAndMinutes = (newValue / this.state.hourlyRate).toFixed(
          2
        )
        let clockHours = String(parseInt(decimalHoursAndMinutes)).padStart(
          3,
          "0"
        )
        let decimalMinutes = String(decimalHoursAndMinutes).replace(
          Number(clockHours) + ".",
          ""
        )
        let clockMinutes = Number("0." + decimalMinutes) * 60

        clockMinutes = clockMinutes.toFixed()
        clockMinutes = String(clockMinutes).padStart(2, "0")

        newState.createInstallmentsHours = clockHours + ":" + clockMinutes
      }
    }

    let validHours = true

    if (stateName === "createInstallmentsHours") {
      if (this.state.hourlyRate !== 0) {
        let clockHours = String(newValue).split(":")[0]
        let clockMinutes = String(newValue).split(":")[1]

        if (
          Number(clockMinutes) > 59 ||
          clockMinutes.length < 2 ||
          isNaN(clockMinutes)
        ) {
          validHours = false
          newCustomStyles[stateName + "State"] = "invalid"
        } else {
          let decimalHours = Number(clockHours)
          let decimalMinutes = Number(clockMinutes) / 60

          newState.createInstallmentsTotalAmount =
            this.state.hourlyRate * (decimalHours + decimalMinutes)
          newCustomStyles[stateName + "State"] = "valid"
        }
      }
    }

    if (
      newState.createInstallmentsDateFrom !== "" &&
      newState.createInstallmentsFirstDueDate !== "" &&
      newState.createInstallmentsTotalAmount > 0 &&
      newState.createInstallmentsQty > 0 &&
      validHours
    ) {
      let previewInstallments = []
      let docDate = new Date(
        Moment(newState.createInstallmentsDateFrom).format("YYYY-MM-DD") +
          " 01:00:00"
      )
      let amount =
        newState.createInstallmentsTotalAmount / newState.createInstallmentsQty
      let dueDate = new Date(
        Moment(newState.createInstallmentsFirstDueDate).format("YYYY-MM-DD") +
          " 01:00:00"
      )

      if (newState.contractInstallmentsRecurrence) {
        amount = newState.createInstallmentsTotalAmount
      }

      //Clock hours and minutes calculations
      let decimalHoursAndMinutes = (amount / this.state.hourlyRate).toFixed(2)
      let clockHours = String(parseInt(decimalHoursAndMinutes)).padStart(3, "0")
      let decimalMinutes = String(decimalHoursAndMinutes).replace(
        Number(clockHours) + ".",
        ""
      )
      let clockMinutes = Number("0." + decimalMinutes) * 60

      clockMinutes = clockMinutes.toFixed()
      clockMinutes = String(clockMinutes).padStart(2, "0")

      let hours = clockHours + ":" + clockMinutes
      let originalDueDay = dueDate.getDate()
      for (let i = 1; i <= newState.createInstallmentsQty; i++) {
        previewInstallments.push({
          docDate: Moment(docDate).format("YYYY-MM-DD"),
          dueDate: Moment(dueDate).format("YYYY-MM-DD"),
          hours: hours === "NaN:NaN" ? "00:00" : hours,
          amount: amount,
        })
        docDate.setMonth(docDate.getMonth() + 1)
        let month = dueDate.getMonth()
        dueDate.setMonth(dueDate.getMonth() + 1)
        dueDate.setDate(originalDueDay)
        while (dueDate.getMonth() > month + 1) {
          dueDate.setDate(dueDate.getDate() - 1)
        }
      }

      newState.previewInstallments = previewInstallments
    } else {
      newState.previewInstallments = []
    }

    this.setState(newState)
  }

  openModalContractInstallment(e, crud, installment) {
    let newState = this.state

    newState.modalContractInstallmentOpen = true
    newState.crudInstallment = crud
    newState.selectedContractInstallment = installment

    if (crud === "C") {
      newState.modalContractInstallmentTitle = "Nova Parcela Manual"
      newState.contractInstallmentStatus = "P"
      newState.contractInstallmentAuthorized = true
    }

    if (crud === "D" || crud === "U") {
      if (crud === "U")
        newState.modalContractInstallmentTitle = "Atualizando Parcela Manual"
      if (crud === "D")
        newState.modalContractInstallmentTitle = "Excluindo Parcela Manual"
      newState.contractInstallmentDocDate = convertMongoDateToDateInput(
        installment.docDate
      )
      newState.contractInstallmentDueDate = convertMongoDateToDateInput(
        installment.dueDate
      )

      newState.contractInstallmentAmount = installment.amount
      newState.contractInstallmentHours = installment.hours
      newState.contractInstallmentCriteria = installment.criteria
      newState.contractInstallmentAuthorized = installment.authorized
      newState.contractInstallmentStatus = installment.status
    }

    this.setState(newState)
  }

  closeModalContractInstallment() {
    let newState = this.state

    newState.contractInstallmentDocDate = ""
    newState.contractInstallmentDueDate = ""
    newState.contractInstallmentAmount = 0
    newState.contractInstallmentHours = "0000:00"
    newState.contractInstallmentCriteria = ""
    newState.contractInstallmentAuthorized = false
    newState.contractInstallmentStatus = ""

    newState.customStyles = {
      contractInstallmentDocDateState: null,
      contractInstallmentDueDateState: null,
      contractInstallmentAmountState: null,
      contractInstallmentHoursState: null,
      contractInstallmentStatusState: null,
    }

    newState.modalContractInstallmentOpen = false
    newState.selectedContractInstallment = null
    newState.crudInstallment = ""
    newState.modalContractInstallmentTitle = ""

    this.setState(newState)
  }

  saveContractInstallment(e) {
    this.setState({ saving: true })
    let newState = this.state

    if (newState.crudInstallment === "D") {
      for (let i = 0; i < newState.installments.length; i++) {
        if (
          newState.selectedContractInstallment._id ===
          newState.installments[i]._id
        ) {
          newState.installments[i].neededAction = "D"
          break
        }
      }
    } else {
      let letSave = true

      if (newState.contractInstallmentDocDate !== "") {
        newState.customStyles.contractInstallmentDocDateState = "valid"
      } else {
        newState.customStyles.contractInstallmentDocDateState = "invalid"
        letSave = false
      }

      if (newState.contractInstallmentDueDate !== "") {
        newState.customStyles.contractInstallmentDueDateState = "valid"
      } else {
        newState.customStyles.contractInstallmentDueDateState = "invalid"
        letSave = false
      }

      if (newState.contractInstallmentAmount * 1 <= 0) {
        newState.customStyles.contractInstallmentAmountState = "invalid"
        letSave = false
      } else {
        newState.customStyles.contractInstallmentAmountState = "valid"
      }

      if (newState.contractInstallmentHours * 1 <= 0) {
        newState.customStyles.contractInstallmentHoursState = "invalid"
        letSave = false
      } else {
        newState.customStyles.contractInstallmentHoursState = "valid"
      }

      if (newState.contractInstallmentStatus !== "") {
        newState.customStyles.contractInstallmentStatusState = "valid"
      } else {
        newState.customStyles.contractInstallmentStatusState = "invalid"
        letSave = false
      }

      if (!letSave) {
        newState.saving = false
        this.setState(newState)
        return
      }

      if (newState.crudInstallment === "C") {
        let d = new Date()

        newState.installments.push({
          neededAction: "C",
          _id:
            "NEW_INSTALLMENT_" + d.getHours() + d.getMinutes() + d.getSeconds(),
          contract: this.state.id,
          docDate: newState.contractInstallmentDocDate,
          dueDate: newState.contractInstallmentDueDate,
          amount: newState.contractInstallmentAmount,
          hours: newState.contractInstallmentHours,
          criteria: newState.contractInstallmentCriteria,
          authorized: newState.contractInstallmentAuthorized,
          status: newState.contractInstallmentStatus,
        })
      } else {
        if (newState.crudInstallment === "U") {
          for (let i = 0; i < newState.installments.length; i++) {
            if (
              newState.selectedContractInstallment._id ===
              newState.installments[i]._id
            ) {
              newState.installments[i].neededAction = "U"
              newState.installments[i].docDate =
                newState.contractInstallmentDocDate
              newState.installments[i].dueDate =
                newState.contractInstallmentDueDate
              newState.installments[i].amount =
                newState.contractInstallmentAmount
              newState.installments[i].hours = newState.contractInstallmentHours
              newState.installments[i].criteria =
                newState.contractInstallmentCriteria
              newState.installments[i].authorized =
                newState.contractInstallmentAuthorized
              newState.installments[i].status =
                newState.contractInstallmentStatus
              break
            }
          }
        }
      }
    }

    this.setState(newState)
    this.closeModalContractInstallment()
  }

  render() {
    return (
      <>
        <LightHeader
          title={
            this.state.dueContractView
              ? "Vencimento de Contratos"
              : "Cadastro de Contratos"
          }
          parent="Cadastros"
          menuTitle={
            this.state.dueContractView ? "Vencimento de Contratos" : "Contratos"
          }
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card hidden={this.state.crud !== ""}>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">
                        {this.state.dueContractView
                          ? "Lista de Contratos à Vencer"
                          : "Lista de Contratos"}
                      </h3>
                    </Col>
                    {!this.state.dueContractView && (
                      <Col className="text-right" xs="6">
                        <Button
                          className="btn-round btn-icon"
                          color="primary"
                          onClick={(e) => this.openDataPanel(e, null, "C")}
                          size="sm"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Novo Contrato</span>
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "actions",
                      editable: false,
                      text: "Ações",
                      isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) => this.openDataPanel(e, row, "U")}
                              >
                                <i className="fas fa-edit" />
                                <span>Alterar</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openDataPanel(e, row, "D")}
                              >
                                <i className="fas fa-trash" />
                                <span>Excluir</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openDataPanel(e, row, "R")}
                              >
                                <i className="fas fa-glasses" />
                                <span>Visualizar</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      },
                    },
                    {
                      dataField: "_id",
                      text: "Id",
                      hidden: true,
                    },
                    {
                      dataField: "code",
                      text: "Código",
                      sort: true,
                    },
                    {
                      dataField: "name",
                      text: "Nome do Contrato",
                      sort: true,
                    },
                    {
                      dataField: "customer.shortName",
                      text: "Cliente",
                      sort: true,
                    },
                    {
                      dataField: "service.name",
                      text: "Serviço",
                      sort: true,
                    },
                    {
                      dataField: "hourlyRate",
                      text: "Valor/Hora",
                      sort: true,
                      formatter: (cell, row) =>
                        new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(cell),
                    },
                    {
                      dataField: "type",
                      text: "Tipo",
                      sort: true,
                      formatter: (cell, row) => contractTypes[cell],
                    },
                    {
                      dataField: "dueDateType",
                      text: "Tipo Vencimento",
                      sort: true,

                      formatter: (cell, row) => dueDateTypes[cell],
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-left"
                            >
                              <label>
                                Pesquisar:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder="Pesquisar por..."
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={(el) => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        hover
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
              <Card hidden={this.state.crud === ""}>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">{this.state.dataPanelTitle}</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="danger"
                        onClick={(e) => this.closeDataPanel(e)}
                        disabled={this.state.saving}
                        size="sm"
                      >
                        {this.state.crud === "R" ? (
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-bold-left" />
                          </span>
                        ) : null}
                        <span className="btn-inner--text">
                          {this.state.crud === "R"
                            ? "Voltar para o Browser"
                            : "Cancelar"}
                        </span>
                      </Button>
                      <Button
                        className="btn-round btn-icon"
                        color="success"
                        onClick={(e) => this.saveContractData(e)}
                        size="sm"
                        hidden={this.state.crud === "R"}
                        disabled={this.state.saving}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i
                            className={
                              this.state.crud === "D"
                                ? "fas fa-trash"
                                : "ni ni-check-bold"
                            }
                          />
                        </span>
                        <span className="btn-inner--text">
                          {this.state.crud === "D" ? "Excluir" : "Salvar"}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row" hidden={this.state.crud === "C"}>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="code">
                          Código
                        </label>
                        <Input
                          autoFocus
                          id="code"
                          type="text"
                          value={this.state.code}
                          readOnly
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="name">
                          Nome do Projeto
                        </label>
                        <Input
                          autoFocus
                          id="name"
                          placeholder="Nome do contrato"
                          type="text"
                          value={this.state.name}
                          readOnly={this.state.readOnly}
                          disabled={this.state.saving}
                          valid={this.state.customStyles.nameState === "valid"}
                          invalid={
                            this.state.customStyles.nameState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "name")}
                        />
                        <div className="invalid-feedback">
                          Digite o nome do contrato.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="client">
                          Cliente
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.customer}
                          disabled={this.state.saving || this.state.readOnly}
                          onSelect={(e) => this.stylesForm(e, "customer")}
                          options={{
                            placeholder: "Selecione um cliente",
                            language: {
                              noResults: function () {
                                return "Nenhum cliente encontrado."
                              },
                            },
                          }}
                          data={this.state.customersList}
                        />
                        <Input
                          hidden
                          valid={
                            this.state.customStyles.customerState === "valid"
                          }
                          invalid={
                            this.state.customStyles.customerState === "invalid"
                          }
                        />
                        <div className="invalid-feedback">
                          Cliente inválido ou não encontrado.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="service">
                          Serviço
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.service}
                          disabled={this.state.saving || this.state.readOnly}
                          onSelect={(e) => this.stylesForm(e, "service")}
                          options={{
                            placeholder: "Selecione um serviço",
                            language: {
                              noResults: function () {
                                return "Nenhum serviço encontrado."
                              },
                            },
                          }}
                          data={this.state.servicesList}
                        />
                        <Input
                          hidden
                          valid={
                            this.state.customStyles.serviceState === "valid"
                          }
                          invalid={
                            this.state.customStyles.serviceState === "invalid"
                          }
                        />
                        <div className="invalid-feedback">
                          Serviço inválido ou não encontrado.
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="type">
                          Tipo
                        </label>
                        <Input
                          id="type"
                          type="select"
                          value={this.state.type}
                          disabled={this.state.readOnly || this.state.saving}
                          valid={this.state.customStyles.typeState === "valid"}
                          invalid={
                            this.state.customStyles.typeState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "type")}
                        >
                          <option value="O">Aberto</option>
                          <option value="C">Fechado</option>
                        </Input>
                        <div className="invalid-feedback">Escolha um tipo.</div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="hourlyRate"
                        >
                          Valor Hora (R$)
                        </label>
                        <Input
                          className="form-control"
                          id="hourlyRate"
                          placeholder="0.00"
                          type="number"
                          value={this.state.hourlyRate}
                          disabled={this.state.readOnly || this.state.saving}
                          valid={
                            this.state.customStyles.hourlyRateState === "valid"
                          }
                          invalid={
                            this.state.customStyles.hourlyRateState ===
                            "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "hourlyRate")}
                        />
                        <div className="invalid-feedback">
                          Digite o valor hora.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="dueDateType"
                        >
                          Tipo Vencimento
                        </label>
                        <Input
                          id="dueDateType"
                          type="select"
                          value={this.state.dueDateType}
                          disabled={this.state.readOnly || this.state.saving}
                          valid={
                            this.state.customStyles.dueDateTypeState === "valid"
                          }
                          invalid={
                            this.state.customStyles.dueDateTypeState ===
                            "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "dueDateType")}
                        >
                          <option value="I">Dias Pós-Faturamento</option>
                          <option value="F">Dia Fixo</option>
                        </Input>
                        <div className="invalid-feedback">
                          Escolha um tipo de vencimento.
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="dueDateDay"
                        >
                          {/* Dia Vencimento ou Dias Vencimento */}
                          {this.state.dueDateDayLabel}
                        </label>
                        <Input
                          autoFocus
                          id="dueDateDay"
                          placeholder=""
                          type="number"
                          value={this.state.dueDateDay}
                          readOnly={this.state.readOnly}
                          disabled={this.state.saving}
                          valid={
                            this.state.customStyles.dueDateDayState === "valid"
                          }
                          invalid={
                            this.state.customStyles.dueDateDayState ===
                            "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "dueDateDay")}
                        />
                        <div className="invalid-feedback">
                          {this.state.dueDateDayMsg}
                        </div>
                      </Col>
                      <Col
                        className="mb-3"
                        md="4"
                        hidden={this.state.dueDateType === "I"}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="dueDateSameMonth"
                        >
                          Dentro do Mês ?
                        </label>
                        <div>
                          <label className="custom-toggle custom-toggle-default mr-1">
                            <input
                              // defaultChecked={this.state.dueDateSameMonth}
                              type="checkbox"
                              checked={this.state.dueDateSameMonth}
                              disabled={
                                this.state.readOnly || this.state.saving
                              }
                              onChange={(e) =>
                                this.stylesForm(e, "dueDateSameMonth", true)
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label">Filial</label>
                        <Input
                          id="filial"
                          type="select"
                          value={this.state.branch}
                          onChange={(e) => {
                            this.stylesForm(e, "branch", false)
                          }}
                        >
                          <option value="1">Golive Consultoria</option>
                          <option value="4">Golive Soluções</option>
                        </Input>
                      </Col>

                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="separateInvoice"
                        >
                          Nota fiscal separada?
                        </label>
                        <div>
                          <label className="custom-toggle custom-toggle-default mt-3">
                            <input
                              type="checkbox"
                              checked={this.state.separateInvoice}
                              id="separateInvoice"
                              value={this.state.separateInvoice}
                              onChange={(e) => {
                                this.stylesForm(e, "separateInvoice", true)
                              }}
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        </div>
                      </Col>
                      <Col className="mb-3" md="12">
                        <label className="form-control-label">
                          Obs. Nota Fiscal
                        </label>
                        <Input
                          autoFocus
                          id="obs"
                          //placeholder="Nome do contrato"
                          type="text"
                          value={this.state.invoiceNotice}
                          //readOnly={this.state.readOnly}
                          // disabled={this.state.saving}
                          onChange={(e) => {
                            this.stylesForm(e, "invoiceNotice", false)
                          }}
                        ></Input>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row hidden={this.state.crud === ""}>
            <Col xs="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Parcelas</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        onClick={(e) => this.openModalCreateInstallments(e)}
                        size="sm"
                        disabled={this.state.saving}
                        hidden={
                          this.state.crud !== "C" && this.state.crud !== "U"
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-money-coins" />
                        </span>
                        <span className="btn-inner--text">Gerar Parcelas</span>
                      </Button>
                      <Button
                        className="btn-round btn-icon"
                        color="warning"
                        onClick={(e) =>
                          this.openModalContractInstallment(e, "C")
                        }
                        size="sm"
                        disabled={this.state.saving}
                        hidden={
                          this.state.crud !== "C" && this.state.crud !== "U"
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-fat-add" />
                        </span>
                        <span className="btn-inner--text">Parcela Manual</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="table-responsive">
                    <Table className="align-items-center table-flush">
                      <thead className="thead-light">
                        <tr>
                          <th className="sort" data-sort="number" scope="col">
                            #
                          </th>
                          <th className="sort" data-sort="date" scope="col">
                            Emissão
                          </th>
                          <th className="sort" data-sort="dueDate" scope="col">
                            Vencimento
                          </th>
                          <th className="sort" data-sort="amount" scope="col">
                            Valor (R$)
                          </th>
                          <th className="sort" data-sort="hours" scope="col">
                            Horas
                          </th>
                          <th className="sort" data-sort="criteria" scope="col">
                            Critérios
                          </th>
                          <th
                            className="sort"
                            data-sort="authorized"
                            scope="col"
                          >
                            Autorizado
                          </th>
                          <th className="sort" data-sort="status" scope="col">
                            Status
                          </th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody className="list">
                        {this.getInstallments(this.state.installments)}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card hidden={this.state.crud === ""}>
                <CardHeader>
                  <Row>
                    <Col xs="12">
                      <h3 className="mb-0">Renovação do Contrato</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="mb-3" md="3">
                        <label
                          className="form-control-label"
                          htmlFor="newDueDate"
                        >
                          Vencimento
                        </label>
                        <Input
                          id="newDueDate"
                          type="date"
                          value={this.state.newDueDate}
                          readOnly={this.state.readOnly}
                          disabled={this.state.saving}
                          onChange={(e) => this.stylesForm(e, "newDueDate")}
                        />
                      </Col>
                      <Col className="mb-3" md="3">
                        <label
                          className="form-control-label"
                          htmlFor="readjust"
                        >
                          Reajuste ?
                        </label>
                        <div>
                          <label className="custom-toggle custom-toggle-default mr-1">
                            <input
                              // defaultChecked={this.state.readjust}
                              type="checkbox"
                              checked={this.state.readjust}
                              disabled={
                                this.state.readOnly || this.state.saving
                              }
                              onChange={(e) =>
                                this.stylesForm(e, "readjust", true)
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        </div>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
        <CreateInstallments
          modalCreateInstallmentsOpen={this.state.modalCreateInstallmentsOpen}
          createInstallmentsDateFrom={this.state.createInstallmentsDateFrom}
          createInstallmentsFirstDueDate={
            this.state.createInstallmentsFirstDueDate
          }
          createInstallmentsTotalAmount={
            this.state.createInstallmentsTotalAmount
          }
          createInstallmentsTotalHours={this.state.createInstallmentsTotalHours}
          createInstallmentsQty={this.state.createInstallmentsQty}
          createInstallmentsHours={this.state.createInstallmentsHours}
          contractInstallmentsRecurrence={
            this.state.contractInstallmentsRecurrence
          }
          createPreviewInstallments={this.createPreviewInstallments.bind(this)}
          closeModalCreateInstallments={this.closeModalCreateInstallments.bind(
            this
          )}
          createInstallments={this.createInstallments.bind(this)}
          customStyles={this.state.customStyles}
          previewInstallments={this.state.previewInstallments}
          saving={this.state.saving}
          hourlyRate={this.state.hourlyRate}
        />
        <ContractInstallment
          hourlyRate={this.state.hourlyRate}
          modalContractInstallmentOpen={this.state.modalContractInstallmentOpen}
          modalContractInstallmentTitle={
            this.state.modalContractInstallmentTitle
          }
          contractInstallmentDocDate={this.state.contractInstallmentDocDate}
          contractInstallmentDueDate={this.state.contractInstallmentDueDate}
          contractInstallmentAmount={this.state.contractInstallmentAmount}
          contractInstallmentHours={this.state.contractInstallmentHours}
          contractInstallmentCriteria={this.state.contractInstallmentCriteria}
          contractInstallmentAuthorized={
            this.state.contractInstallmentAuthorized
          }
          contractInstallmentStatus={this.state.contractInstallmentStatus}
          saving={this.state.saving}
          customStyles={this.state.customStyles}
          stylesForm={this.stylesForm.bind(this)}
          closeModalContractInstallment={this.closeModalContractInstallment.bind(
            this
          )}
          saveContractInstallment={this.saveContractInstallment.bind(this)}
          crudInstallment={this.state.crudInstallment}
        />
      </>
    )
  }
}

export default Contracts
