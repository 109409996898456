import LightHeader from "components/Headers/LightHeader";
import ProjectIndicatorTableHeader from "components/TableHeaders/admin/ProjectIndicatorTableHeader";
import ProjectIndicatorTable from "components/Tables/admin/ProjectIndicatorTable";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import api from "services/api";
import "react-loading-skeleton/dist/skeleton.css";
import * as XLSX from "xlsx";
import { createDatasetForSpreadSheet } from "utils/createSpreadsheetDataset";
import { createDatasetForDownload } from "utils/createSpreadsheetDataset";
import { getMidnightThisDay } from "utils/dateHandling";
function ProjectIndicator() {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [downloadDataset, setDownloadDataset] = useState([]);
  const [date, setDate] = useState();

  const onSpreadsheetChange = (dataset) => {
    const downloadDataset = createDatasetForDownload(dataset);
    setDownloadDataset(downloadDataset);
  };

  async function fetchTableData() {
    setLoading(true);
    const tableDataResponse = await api.post(
      "project/getProjectIndicatorData",
      { date: getMidnightThisDay(date) }
    );
    setProjects(tableDataResponse.data);
    setLoading(false);
  }

  useEffect(() => {
    if (projects.length < 0) setDataset([]);
    const dataset = createDatasetForSpreadSheet(projects);
    setDataset(dataset);
    onSpreadsheetChange(dataset);
  }, [projects]);

  async function downloadSpreadSheet() {
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(downloadDataset);

    XLSX.utils.book_append_sheet(wb, ws, "mySheet");

    XLSX.writeFile(wb, `Indicador_De_Projetos-${date}.xlsx`);
  }

  return (
    <>
      <LightHeader
        title="Indicador de Projetos"
        menuTitle="Indicador de Projetos"
        parent="Relatórios"
      />
      <Container className="mt--6">
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                {" "}
                <ProjectIndicatorTableHeader
                  dataset={dataset}
                  date={date}
                  setDate={(date) => setDate(date)}
                  fetchTableData={() => fetchTableData()}
                  downloadSpreadSheet={() => downloadSpreadSheet()}
                />{" "}
              </CardHeader>
              <CardBody
                style={{
                  overflowX: "auto",
                  padding: "0px 0px 0px 0px",
                }}
              >
                {loading ? (
                  <center>
                    {" "}
                    <Skeleton
                      count={10}
                      height={30}
                      borderRadius="5px"
                      inline
                    />
                  </center>
                ) : projects.length > 0 ? (
                  <ProjectIndicatorTable
                    dataset={dataset}
                    onSpreadsheetChange={(e) => onSpreadsheetChange(e)}
                  />
                ) : null}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ProjectIndicator;
