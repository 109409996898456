// react plugin used to create DropdownMenu for selecting items
// reactstrap components
import { Button, Modal, ModalBody } from "reactstrap"
import PropTypes from "prop-types"

function ConfirmationModal(props) {
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{props.action?.text}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody>
        {" "}
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
        {props.children}
      </ModalBody>

      <div className="modal-footer">
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            props.deny()
            props.closeModal()
          }}
        >
          Cancelar
        </Button>
        <Button
          color="success"
          type="button"
          disabled={props.action?.blockConfirm || false}
          onClick={(e) => {
            props.confirm()
            props.closeModal()
          }}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  )
}
ConfirmationModal.propTypes = {
  action: (PropTypes.object = {
    text: PropTypes.string,
    blockConfirm: PropTypes.bool,
  }),
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  deny: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
}
export default ConfirmationModal
