import ServiceOrdersReport from "components/Pdf/ServiceOrderReport"
import ViewPdf from "components/Pdf/ViewPdf"
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import api from "services/api"
import { getMidnightThisDay } from "utils/dateHandling"

function Document() {
  const search = useLocation().search
  const params = useParams()
  const [serviceOrder, setServiceOrder] = useState({})
  const [userServiceOrders, setUserServiceOrders] = useState({})
  const [periodHolidays, setPeriodHollidays] = useState([])
  const [latestClosing, setLatestClosing] = useState({})
  const [docType, setDocType] = useState("")
  const [to, setTo] = useState("")
  const [from, setFrom] = useState("")

  useEffect(() => {
    const type = new URLSearchParams(search).get("type")
    setDocType(type)

    async function getData(type) {
      if (type === "os") {
        let serviceOrderResponse = await api.get(
          "serviceOrder/read/" + params.id
        )
        setServiceOrder(serviceOrderResponse.data)
      } else if (type === "os_report") {
        let searchParams = null
        const dateTo = new URLSearchParams(search).get("dateTo")
        const dateFrom = new URLSearchParams(search).get("dateFrom")

        setTo(new Date(`${dateTo} 23:59:59`).toLocaleDateString("pt-BR"))
        setFrom(new Date(`${dateFrom} 23:59:59`).toLocaleDateString("pt-BR"))

        if (dateTo !== "" && dateFrom !== "")
          searchParams = {
            date: {
              $gte: new Date(`${dateFrom}`),
              $lte: getMidnightThisDay(dateTo),
            },
          }
        if (dateTo !== "" && dateFrom === "")
          searchParams = { date: { $lte: getMidnightThisDay(dateTo) } }
        if (dateTo === "" && dateFrom !== "")
          searchParams = { date: { $gte: new Date(`${dateFrom}`) } }

        let serviceOrderResponse = await api.post(
          "user/searchForServiceOrdersReport",
          { user: params.id, ...searchParams }
        )
        let hollidaysResponse = await api.post("holliday/search", searchParams)

        let closingResponse = await api.post("consultantClosing/search", {
          search: { consultant: params.id },
          sort: { date: -1 },
          limit: 1,
        })
        setLatestClosing(closingResponse?.data[0] || null)
        setPeriodHollidays(hollidaysResponse?.data || [])
        setUserServiceOrders(serviceOrderResponse.data)
      }
    }

    if (params.id) {
      getData(type)
    }
  }, [params, search])
  return (
    <>
      <div
        style={{
          margin: "auto",
          width: "fit-content",
        }}
      >
        {docType === "os" && serviceOrder ? (
          <ViewPdf serviceOrder={serviceOrder}></ViewPdf>
        ) : null}
        {docType === "os_report" &&
        userServiceOrders.serviceOrders?.length > 0 ? (
          <ServiceOrdersReport
            periodHolidays={periodHolidays}
            userServiceOrders={userServiceOrders}
            latestClosing={latestClosing}
            to={to}
            from={from}
          ></ServiceOrdersReport>
        ) : null}
      </div>
    </>
  )
}

export default Document
