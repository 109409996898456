import LightHeader from "components/Headers/LightHeader"
import Loading from "components/Modals/Loading"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap"
import InvoicesForecastTableHeader from "components/TableHeaders/admin/InvoicesForecastTableHeader.js"
import InvoicesForecastTable from "components/Tables/admin/InvoicesForecastTable"
import api from "services/api"
import { hoursToMinutes } from "utils/timeHandling"
import { minutesToHours } from "utils/timeHandling"

import { transformNumberToCurency } from "utils/currencyHandling"
import { getMidnightThisDay } from "utils/dateHandling"
const now = new Date()

export default function HoursAnalysis() {
  const [loading, setloading] = useState(false)
  const [periodWorkingHours, setPeriodWorkingHours] = useState(false)
  const [filters, setFilters] = useState({
    to: null,
    from: null,
    grouping: "consul",
  })
  const [data, setData] = useState([])

  const fetchData = useCallback(async (searchParams) => {
    setloading(true)
    const total = {
      fullName: "TOTAL",
      scheduledTime: 0,
      forseenTime: 0,
      performedTime: 0,
      scheduledCost: 0,
      forseenCost: 0,
      performedCost: 0,
      costOverTime: 0,
    }
    // TODO: Remember to fix this query on mongo if neto complains about performance

    const consultantsResponse = await api.post(
      "user/searchForInvoicesForecast ",
      searchParams
    )

    setPeriodWorkingHours(
      minutesToHours(consultantsResponse.data.periodWorkingMinutes)
    )

    const data = consultantsResponse.data.users
      .filter((cr) => cr.schedules.length > 0)
      .map((consultant) => {
        let scheduledTime = 0
        let forseenTime = 0
        let performedTime = 0
        let scheduledCost = 0
        let forseenCost = 0
        let performedCost = 0
        let periodBalance = 0
        let todayBalance = 0

        consultant.schedules.forEach((schedule) => {
          scheduledTime += hoursToMinutes(schedule.timeTotal)
          scheduledCost +=
            (hoursToMinutes(schedule.timeTotal) / 60) * consultant.hourlyRate
          total.scheduledTime += hoursToMinutes(schedule.timeTotal)
          total.scheduledCost +=
            (hoursToMinutes(schedule.timeTotal) / 60) * consultant.hourlyRate

          if (!schedule.serviceOrder) {
            forseenTime += hoursToMinutes(schedule.timeTotal)
            forseenCost +=
              (hoursToMinutes(schedule.timeTotal) / 60) * consultant.hourlyRate

            total.forseenTime += hoursToMinutes(schedule.timeTotal)
            total.forseenCost +=
              (hoursToMinutes(schedule.timeTotal) / 60) * consultant.hourlyRate
          } else {
            schedule.appointments.forEach((appointment) => {
              if (appointment.osCreated) {
                performedTime += hoursToMinutes(appointment.hours)
                performedCost += appointment.cost

                forseenTime += hoursToMinutes(appointment.hours)
                forseenCost += appointment.cost

                total.forseenTime += hoursToMinutes(appointment.hours)
                total.forseenCost += appointment.cost

                total.performedTime += hoursToMinutes(appointment.hours)
                total.performedCost += appointment.cost
              }
            })
          }
        })
        return {
          actions: { consultantId: consultant._id },
          name: consultant.fullName,
          scheduledTime: minutesToHours(scheduledTime),
          forseenTime: minutesToHours(forseenTime),
          performedTime: minutesToHours(performedTime),
          scheduledCost: transformNumberToCurency(scheduledCost),
          forseenCost: transformNumberToCurency(forseenCost),
          performedCost: transformNumberToCurency(performedCost),
          periodBalance: minutesToHours(
            performedTime - consultantsResponse.data.periodWorkingMinutes
          ),
          todayBalance: minutesToHours(
            performedTime - consultantsResponse.data.todayWorkingMinutes
          ),
          costOverTime: transformNumberToCurency(
            performedCost / (performedTime / 60)
          ),
        }
      })
    setData([
      ...data,
      {
        name: total.fullName,
        scheduledTime: minutesToHours(total.scheduledTime),
        forseenTime: minutesToHours(total.forseenTime),
        performedTime: minutesToHours(total.performedTime),
        scheduledCost: transformNumberToCurency(total.scheduledCost),
        forseenCost: transformNumberToCurency(total.forseenCost),
        performedCost: transformNumberToCurency(total.performedCost),
        costOverTime: transformNumberToCurency(
          total.performedCost / (total.performedTime / 60)
        ),
      },
    ])

    setloading(false)
  }, [])

  async function fetchSchedules() {
    let searchParams = {}
    if (filters.from && !filters.to)
      searchParams.date = { $gte: new Date(`${filters.from}`).toISOString() }
    if (filters.to && !filters.from)
      searchParams.date = {
        $lte: getMidnightThisDay(filters.to),
      }
    if (filters.to && filters.from)
      searchParams.date = {
        $gte: new Date(`${filters.from}`).toISOString(),
        $lte: getMidnightThisDay(filters.to),
      }

    await fetchData(searchParams)
  }

  useEffect(() => {
    let filters = {
      from: new Date(now.getFullYear(), now.getMonth(), 1)
        .toISOString()
        .split("T")[0],
      to: new Date(now.getFullYear(), now.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0],
    }
    setFilters((prev) => ({ ...prev, ...filters }))

    let searchParams = {}

    if (filters.from && !filters.to)
      searchParams.date = { $gte: new Date(`${filters.from}`).toISOString() }
    if (filters.to && !filters.from)
      searchParams.date = {
        $lte: getMidnightThisDay(filters.to),
      }
    if (filters.to && filters.from)
      searchParams.date = {
        $gte: new Date(`${filters.from}`).toISOString(),
        $lte: getMidnightThisDay(filters.to),
      }

    fetchData(searchParams)
  }, [fetchData])

  return (
    <>
      <LightHeader
        title="Análise de horas"
        menuTitle="Análise de horas"
        parent="Relatórios"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <InvoicesForecastTableHeader
                  filters={filters}
                  periodWorkingHours={periodWorkingHours}
                  setFilters={(filter) => {
                    setFilters((prev) => ({ ...prev, ...filter }))
                  }}
                  fetchSchedules={() => fetchSchedules()}
                />
              </CardHeader>
              <CardBody
                style={{
                  padding: "0px 0px 0px 0px",
                  height: "auto",
                }}
              >
                <InvoicesForecastTable
                  hours={true}
                  data={data}
                  to={filters.to}
                  from={filters.from}
                  hours={true}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loading modalOpened={loading} />
    </>
  )
}
