import LightHeader from "components/Headers/LightHeader"
import CreateBilling from "components/Modals/create/CreateBilling"
import Loading from "components/Modals/Loading"
import BillingDetail from "components/Modals/show-edit/BillingDetail"
import BillingsTableHeader from "components/TableHeaders/admin/BillingsTableHeader"
import BillingsTable from "components/Tables/admin/BillingsTable"
import { useCallback, useEffect, useState } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap"
import api from "services/api"
/*
  TODO:
  fix the get all user method to get schedules with all inner data
*/
export default function Billings() {
  const [loading, setloading] = useState(false)
  const [createBillingOpen, setCreateBillingOpen] = useState(false)
  const [billings, setBillings] = useState([])
  const [filters, setFilters] = useState({})

  const [billingDetailOpen, setBillingDetailOpen] = useState(false)
  const [selectedBilling, setSelectedBilling] = useState({ code: "" })

  /**
   *
   */
  const fetchBillings = useCallback(async () => {
    const billingsResponse = await api.post(
      "billing/search",
      !filters.code ? {} : { code: { $regex: filters.code } }
    )
    setBillings(billingsResponse.data)
  }, [filters])
  /**
   *
   */
  useEffect(() => {
    fetchBillings()
  }, [fetchBillings])

  return (
    <>
      <LightHeader
        title="Faturamentos"
        menuTitle="Faturamentos"
        parent="Fechamentos"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <BrowserView>
                  <BillingsTableHeader
                    openCreateBilling={() => setCreateBillingOpen(true)}
                    filters={filters}
                    setFilters={(prop) => {
                      setFilters((prev) => ({ ...prev, ...prop }))
                    }}
                  />
                </BrowserView>
                <MobileView></MobileView>
              </CardHeader>
              <CardBody
                style={{
                  overflowX: "auto",
                  padding: "0px 0px 0px 0px",
                  height: "65vh",
                }}
              >
                <BrowserView>
                  <BillingsTable
                    billings={billings}
                    handleBillingClick={(billing) => {
                      console.log(billing)
                      console.table(billing)
                      setSelectedBilling(billing)
                      setBillingDetailOpen(true)
                    }}
                  />
                </BrowserView>
                <MobileView></MobileView>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loading modalOpened={loading} />
      <CreateBilling
        setloading={(value) => setloading(value)}
        open={createBillingOpen}
        updateBillings={() => {
          fetchBillings()
        }}
        closeModal={() => setCreateBillingOpen(false)}
      />
      <BillingDetail
        open={billingDetailOpen}
        billing={selectedBilling}
        updateBillings={() => {
          fetchBillings()
        }}
        closeModal={() => setBillingDetailOpen(false)}
      />
    </>
  )
}
