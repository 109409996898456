import React from "react"
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

// core components
import LightHeader from "components/Headers/LightHeader.js"
import ReactBSAlert from "react-bootstrap-sweetalert"

import Moment from "moment"

//Custom components
import api from "services/api"
import Loading from "components/Modals/Loading"
import Avatar from "react-avatar"
import User from "components/Modals/show-edit/User"

const profiles = {
  D: "Diretoria",
  A: "Administração",
  C: "Consultoria",
  O: "Coordenação",
  T: "TI",
  L: "Cliente",
}

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 25,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
})

const { SearchBar } = Search

class Users extends React.Component {
  state = {
    data: [],
    isLoading: true,
    saving: false,
    alert: null,

    currentUserId: "",

    //Modal user variables
    modalUserOpened: false,
    modalTitle: "",
    id: "",
    crud: "",
    readOnly: false,
    fullName: "",
    email: "",
    entryDate: "",
    birthDate: "",
    password: "",
    sglProfile: "",
    hasActivities: false,
    showSchedule: false,
    hourlyRate: "0.00",
    kind: "",
    active: false,
    menuAccesses: [],
    class: "consultant",
    image: null,

    //Modal user fields validations
    customStyles: {
      fullNameState: null,
      emailState: null,
      passwordState: null,
      sglProfileState: null,
      kindState: null,
    },
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    this.setState({ isLoading: true })

    const currentUserId = localStorage.getItem(process.env.REACT_APP_USERID_KEY)

    this.setState({
      currentUserId: currentUserId,
    })

    var users = []
    var row = {}
    var response = await api.post("/user/search", {
      $or: [{ class: "CG" }, { class: "TP" }],
    })

    const usersAux = response.data

    for (let i = 0; i < usersAux.length; i++) {
      row = {
        _id: usersAux[i]._id,
        fullName: usersAux[i].fullName,
        email: usersAux[i].email,
        birthDate: usersAux[i].birthDate,
        entryDate: usersAux[i].entryDate,
        sglProfile: usersAux[i].sglProfile,
        sglProfileName: profiles[usersAux[i].sglProfile],
        hourlyRate: usersAux[i].hourlyRate,
        kind: usersAux[i].kind,
        active: usersAux[i].active,
        image: usersAux[i].image,
        showSchedule: usersAux[i].showSchedule,
        class: usersAux[i].class,
      }
      if (row.image) {
        let response = await api.get("/document/read/" + row.image)
        row.image = response.data
      }

      if (users.length === 0) {
        users = [row]
      } else {
        users.push(row)
      }
    }
    this.setState({ data: users, isLoading: false })
  }

  openModalUser = async (e, row, crud) => {
    this.setState({ crud: crud })

    if (crud === "C") {
      this.setState({ modalTitle: "Novo Usuário" })
    } else if (crud === "R") {
      this.setState({ modalTitle: "Visualizando Usuário" })
    } else if (crud === "U") {
      this.setState({ modalTitle: "Alterando Usuário" })
    } else if (crud === "D") {
      this.setState({ modalTitle: "Excluindo Usuário" })
    }

    if (crud === "C") {
      let menuAccesses = await api.get("menu/all")
      this.setState({
        crud: crud,
        sglProfile: "",
        hourlyRate: "0.00",
        kind: "",
        class: "CG",
        menuAccesses: menuAccesses.data,
      })
    } else {
      var birthDate = null
      var entryDate = null

      if (row.birthDate && row.birthDate !== null) {
        birthDate = row.birthDate.substring(0, 10)
      }

      if (row.entryDate && row.entryDate !== null) {
        entryDate = row.entryDate.substring(0, 10)
      }

      let menuAccesses = await api.get("menu/all")
      let hasActivities = await api.post("activityConsultant/search", {
        consultant: row._id,
      })
      this.setState({
        id: row._id,
        crud: crud,
        readOnly: crud === "R" || crud === "D",
        fullName: row.fullName,
        email: row.email,
        birthDate: birthDate,
        entryDate: entryDate,
        password: process.env.REACT_APP_DEFPWD,
        sglProfile: row.sglProfile,
        class: row.class,
        hourlyRate: row.hourlyRate,
        kind: row.kind,
        active: row.active,
        image: row.image,
        menuAccesses: menuAccesses.data,
        showSchedule: row.showSchedule,
        hasActivities: hasActivities.data.length > 0,
      })
    }

    this.setState({ modalUserOpened: true })
  }

  closeModalUser(e) {
    this.setState({ modalUserOpened: false })

    this.clearModalState()
  }

  clearModalState() {
    this.setState({
      id: "",
      crud: "",
      readOnly: false,
      fullName: "",
      email: "",
      class: "",
      birthDate: "",
      entryDate: "",
      password: "",
      sglProfile: "",
      hourlyRate: "0.00",
      kind: "",
      active: false,
      saving: false,
      showSchedule: false,
      menuAccess: {},

      //Validations
      customStyles: {
        fullNameState: null,
        emailState: null,
        passwordState: null,
      },
    })
  }

  modalSave = async (e) => {
    this.setState({ saving: true })

    let newState = this.state

    var letSave = true
    var newData = {}
    var success = true

    if (this.state.crud === "D") {
      if (this.state.id === this.state.currentUserId) {
        this.warningAlert("Você não pode excluir seu próprio perfil.")
        this.setState({ saving: false })
        return
      }

      var response = await api.post("/plan/search", {
        deleted: "N",
        analyst: this.state.id,
      })
      var responseMng = await api.post("/plan/search", {
        deleted: "N",
        manager: this.state.id,
      })
      var responseKey = await api.post("/plan/search", {
        deleted: "N",
        keyUser: this.state.id,
      })

      if (
        response.data.length > 0 ||
        responseMng.data.length > 0 ||
        responseKey.data.length > 0
      ) {
        this.warningAlert(
          "Este usuário possui planos (SmartFlow) atribuídos a ele."
        )
        this.setState({ saving: false })
        return
      }

      response = await api.post("/test/search", {
        deleted: "N",
        analyst: this.state.id,
      })
      responseMng = await api.post("/test/search", {
        deleted: "N",
        manager: this.state.id,
      })
      responseKey = await api.post("/test/search", {
        deleted: "N",
        keyUser: this.state.id,
      })

      if (
        response.data.length > 0 ||
        responseMng.data.length > 0 ||
        responseKey.data.length > 0
      ) {
        this.warningAlert(
          "Este usuário possui testes (SmartFlow) atribuídos a ele."
        )
        this.setState({ saving: false })
        return
      }

      if (this.state.image && this.state.image !== null) {
        await api.delete("/document/delete/" + this.state.image)
      }

      try {
        await api.put("/user/update/" + this.state.id, {
          deleted: "Y",
          image: null,
        })
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar excluir. Tente novamente.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeModalUser()
      }
    } else if (this.state.crud === "C" || this.state.crud === "U") {
      //Field content validations
      if (newState.fullName === "") {
        newState.fullNameState = "invalid"
        letSave = false
      } else {
        newState.fullNameState = "valid"
      }

      if (newState.email === "") {
        newState.emailState = "invalid"
        letSave = false
      } else {
        newState.emailState = "valid"
      }

      if (newState.password === "") {
        newState.passwordState = "invalid"
        letSave = false
      } else {
        newState.passwordState = "valid"
      }

      if (newState.sglProfile === "") {
        newState.sglProfileState = "invalid"
        letSave = false
      } else {
        newState.sglProfileState = "valid"
      }

      if (
        newState.sglProfile === "C" &&
        (newState.hourlyRate * 1 === 0 ||
          newState.hourlyRate === null ||
          !newState.hourlyRate)
      ) {
        newState.hourlyRateState = "invalid"
        letSave = false
      } else {
        newState.hourlyRateState = "valid"
      }

      if (newState.kind === "") {
        newState.kindState = "invalid"
        letSave = false
      } else {
        newState.kindState = "valid"
      }

      this.setState({
        customStyles: newState,
      })

      if (!letSave) {
        this.setState({ saving: false })
        return
      } else {
        const existUser = await api.post("/user/search", {
          email: this.state.email,
          deleted: "N",
        })

        if (existUser.data.length > 0) {
          if (existUser.data[0]._id !== this.state.id) {
            this.warningAlert(
              "Já existe um usuário cadastrado com este e-mail."
            )
            this.setState({ saving: false })
            return
          }
        }

        var birthDate = null
        var entryDate = null

        if (this.state.birthDate && this.state.birthDate !== "") {
          birthDate = Moment(this.state.birthDate).format("YYYY-MM-DD")
        }

        if (this.state.entryDate && this.state.entryDate !== "") {
          entryDate = Moment(this.state.entryDate).format("YYYY-MM-DD")
        }

        newData = {
          fullName: this.state.fullName,
          email: this.state.email,
          birthDate: birthDate,
          entryDate: entryDate,
          sglProfile: this.state.sglProfile,
          hourlyRate: this.state.hourlyRate,
          class: this.state.class,
          kind: this.state.kind,
          active: this.state.active,
          showSchedule: this.state.showSchedule,
        }

        if (this.state.crud === "C") newData = { ...newData, image: null }

        if (
          this.state.password !== "" &&
          this.state.password !== process.env.REACT_APP_DEFPWD
        ) {
          newData = { ...newData, password: this.state.password }
        }

        this.saveUserData(newData)
      }
    }
  }

  saveUserData = async (newData) => {
    var success = true

    if (this.state.crud === "C") {
      try {
        await api.post("/user/create", newData)
      } catch (err) {
        this.warningAlert("Já existe um usuário cadastrado com este e-mail.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeModalUser()
      }
    } else {
      newData = { ...newData, oldEmail: this.state.email }

      try {
        //Update user
        await api.put("/user/update/" + this.state.id, newData)
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar alterar. Tente novamente.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeModalUser()
      }
    }
  }

  stylesForm = async (e, stateName, menuAccessKey) => {
    if (stateName === "active") {
      this.setState({ active: !this.state.active })
      return
    }
    if (stateName === "showSchedule") {
      this.setState({ showSchedule: !this.state.showSchedule })
      return
    }
    if (stateName === "class" && e.target.value === "TP") {
      this.setState({
        sglProfile: "Z",
        class: "TP",
        kind: "R",
        showSchedule: false,
        password: "terceiro",
      })
      return
    }

    let newState = this.state.customStyles
    if (stateName === "hourlyRate") {
      if (
        this.state.sglProfile === "C" &&
        (e.target.value * 1 === 0 || e.target.value === null)
      ) {
        newState[stateName + "State"] = "invalid"
      } else {
        newState[stateName + "State"] = "valid"
      }
    } else if (stateName !== "menuAccess") {
      if (e.target.value === null || e.target.value === "") {
        newState[stateName + "State"] = "invalid"
      } else {
        newState[stateName + "State"] = "valid"
      }
    }
    this.setState({
      [stateName]: e.target.value,
      customStyles: newState,
    })
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  render() {
    return (
      <>
        <LightHeader
          title="Cadastro de Usuários"
          parent="Cadastros"
          menuTitle="Usuários"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Lista de Usuários do SGL</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        onClick={(e) => this.openModalUser(e, null, "C")}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Usuário</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "_id",
                      text: "Id",
                      hidden: true,
                    },
                    {
                      dataField: "actions",
                      editable: false,
                      text: "Ações",
                      isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) => this.openModalUser(e, row, "U")}
                              >
                                <i className="fas fa-user-edit" />
                                <span>Alterar</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openModalUser(e, row, "D")}
                              >
                                <i className="fas fa-trash" />
                                <span>Excluir</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openModalUser(e, row, "R")}
                              >
                                <i className="fas fa-glasses" />
                                <span>Visualizar</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      },
                    },
                    {
                      dataField: "profileImg",
                      editable: false,
                      text: "Nome Completo",
                      // isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <div>
                            {row.image ? (
                              <img
                                alt="..."
                                className="avatar rounded-circle mr-3"
                                src={row.image.url}
                              />
                            ) : (
                              <Avatar
                                className="avatar rounded-circle mr-3"
                                name={row.fullName}
                                maxInitials={2}
                                round={true}
                                size={48}
                                title={" "}
                                color={"#ced4da"}
                                fgColor={"#172b4d"}
                              />
                            )}
                            <b>{row.fullName}</b>
                          </div>
                        )
                      },
                    },
                    {
                      dataField: "fullName",
                      text: "Nome Aux",
                      sort: true,
                      hidden: true,
                    },
                    {
                      dataField: "email",
                      text: "E-mail",
                      sort: true,
                    },
                    {
                      dataField: "sglProfileName",
                      text: "Perfil",
                      sort: true,
                    },
                    {
                      dataField: "image",
                      text: "Imagem",
                      sort: true,
                      hidden: true,
                    },
                    {
                      dataField: "active",
                      text: "Ativo",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <label className="custom-toggle custom-toggle-default">
                            <input
                              defaultChecked={row.active}
                              type="checkbox"
                              disabled
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        )
                      },
                    },

                    {
                      dataField: "mainUser",
                      text: "Responsável Cliente",
                      hidden: true,
                    },
                    {
                      dataField: "showSchedule",
                      text: "Exige Agenda",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <label className="custom-toggle custom-toggle-default">
                            <input
                              defaultChecked={row.showSchedule}
                              type="checkbox"
                              disabled
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        )
                      },
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-left"
                            >
                              <label>
                                Pesquisar:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder="Pesquisar por..."
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={(el) => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        hover
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
          <User
            {...this.props}
            modalOpened={this.state.modalUserOpened}
            closeModal={this.closeModalUser.bind(this)}
            modalTitle={this.state.modalTitle}
            id={this.state.id}
            crud={this.state.crud}
            readOnly={this.state.readOnly}
            fullName={this.state.fullName}
            email={this.state.email}
            birthDate={this.state.birthDate}
            entryDate={this.state.entryDate}
            password={this.state.password}
            sglProfile={this.state.sglProfile}
            hourlyRate={this.state.hourlyRate}
            kind={this.state.kind}
            active={this.state.active}
            menuAccesses={this.state.menuAccesses}
            class={this.state.class}
            customStyles={this.state.customStyles}
            modalSave={this.modalSave.bind(this)}
            stylesForm={this.stylesForm.bind(this)}
            hasActivities={this.state.hasActivities}
            showSchedule={this.state.showSchedule}
            saving={this.state.saving}
          />
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
      </>
    )
  }
}

export default Users
