/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap"
// core components
import ReactBSAlert from "react-bootstrap-sweetalert"

import api from "services/api"

import ProfileHeader from "components/Headers/ProfileHeader.js"
import Loading from "components/Modals/Loading"
import MultipleFilesDropzone from "components/Dropzones/MultipleFilesDropzone.js"
import { isManager } from "services/auth"
import { isConsulting } from "services/auth"

class Profile extends React.Component {
  state = {
    isLoading: false,
    saving: false,
    editing: false,
    alert: null,
    id: "",
    currentName: "",
    fullName: "",
    headerName: "",
    email: "",
    oldEmail: "",
    image: null,
    profileImageUrl: "",
    password: "",
    confirmPassword: "",

    //Modal fields validations
    customStyles: {
      fullNameState: null,
      passwordState: null,
      confirmPasswordState: null,
      emailState: null,
    },

    //Current user access level
    isManagerConsulting: false,
    isResourceConsulting: false,
    isManagerCustomer: false,
    currentUserFullName: "",
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    const isManagerConsulting = isManager() && isConsulting()
    const isResourceConsulting = !isManager() && isConsulting()
    const isManagerCustomer = isManager() && !isConsulting()

    this.setState({
      isLoading: true,
    })

    const userId = localStorage.getItem(process.env.REACT_APP_USERID_KEY)
    var response = await api.get("/user/read/" + userId)
    const user = response.data

    var headerName = user.fullName

    if (String(headerName).indexOf(" ") > 0) {
      headerName = String(headerName).substring(
        0,
        String(headerName).indexOf(" ")
      )
    }

    var profileImageUrl = ""
    var userImageId = null

    if (user.image && user.image !== null) {
      response = await api.get("/document/read/" + user.image._id)

      if (response.data !== null) {
        profileImageUrl = response.data.url
      }

      userImageId = user.image._id
    }

    this.setState({
      id: user._id,
      currentName: user.fullName,
      fullName: user.fullName,
      headerName: headerName + "!",
      email: user.email,
      oldEmail: user.email,
      image: userImageId,
      profileImageUrl: profileImageUrl,
      password: process.env.REACT_APP_DEFPWD,
      confirmPassword: process.env.REACT_APP_DEFPWD,
      isLoading: false,
      isManagerConsulting: isManagerConsulting,
      isResourceConsulting: isResourceConsulting,
      isManagerCustomer: isManagerCustomer,
    })
  }

  editProfile(e) {
    e.preventDefault()

    this.setState({
      editing: true,
    })
  }

  stylesForm = async (e, stateName) => {
    let newState = this.state.customStyles

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid"
    } else {
      newState[stateName + "State"] = "valid"
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState,
    })

    if (stateName === "password") {
      let customStyles = this.state.customStyles

      customStyles.confirmPasswordState = "invalid"

      this.setState({ confirmPassword: "", customStyles: customStyles })
    }
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  confirmRemoveImage(e) {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Tem certeza ?"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.removeProfileImage()}
          showCancel
          confirmBtnBsStyle="secondary"
          confirmBtnText="Cancelar"
          cancelBtnBsStyle="success"
          cancelBtnText="Sim, remover minha imagem!"
          btnSize=""
        >
          Sua imagem do perfil será removida.
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  save = async (e) => {
    this.setState({
      saving: true,
      isLoading: true,
    })

    let newState = this.state
    let letSave = true
    let success = true

    if (newState.fullName === "") {
      newState.customStyles.fullNameState = "invalid"
      letSave = false
    } else {
      newState.customStyles.fullNameState = "valid"
    }

    if (newState.email === "") {
      newState.customStyles.emailState = "invalid"
      letSave = false
    } else {
      newState.customStyles.emailState = "valid"
    }

    if (newState.password === "") {
      newState.customStyles.passwordState = "invalid"
      letSave = false
    } else {
      newState.customStyles.passwordState = "valid"
    }

    if (newState.password !== newState.confirmPassword) {
      newState.customStyles.passwordState = "invalid"
      newState.customStyles.confirmPasswordState = "invalid"
      letSave = false
    } else {
      newState.customStyles.passwordState = "valid"
      newState.customStyles.confirmPasswordState = "valid"
    }

    this.setState({
      customStyles: newState.customStyles,
    })

    if (!letSave) {
      this.setState({ saving: false, isLoading: false })
      return
    }

    const existUser = await api.post("/user/search", {
      email: this.state.email,
      deleted: "N",
    })

    if (existUser.data.length > 0) {
      if (existUser.data[0]._id !== this.state.id) {
        this.warningAlert("Já existe um usuário cadastrado com este e-mail.")
        this.setState({ saving: false, isLoading: false })
        return
      }
    }

    var uploadedFile
    var fileData
    let imageProfile = this.state.image

    const newImageFile = document.getElementById("newFiles")

    if (newImageFile !== null) {
      if (newImageFile.uploadfileslist) {
        if (newImageFile.uploadfileslist.length > 0) {
          uploadedFile = newImageFile.uploadfileslist[0]

          const fileName = uploadedFile.name

          if (
            !fileName.includes(".xbm") &&
            !fileName.includes(".tif") &&
            !fileName.includes(".pjp") &&
            !fileName.includes(".svgz") &&
            !fileName.includes(".jpg") &&
            !fileName.includes(".jpeg") &&
            !fileName.includes(".ico") &&
            !fileName.includes(".tiff") &&
            !fileName.includes(".gif") &&
            !fileName.includes(".svg") &&
            !fileName.includes(".jfif") &&
            !fileName.includes(".webp") &&
            !fileName.includes(".png") &&
            !fileName.includes(".bmp") &&
            !fileName.includes(".pjpeg") &&
            !fileName.includes(".avif")
          ) {
            this.warningAlert("Formato de imagem não suportado.")

            this.setState({ saving: false, isLoading: false })
            return
          }

          if (imageProfile && imageProfile !== null) {
            await api.delete("/document/delete/" + this.state.image)
          }

          if (uploadedFile.upload) {
            fileData = new FormData()

            fileData.append("file", uploadedFile.file, uploadedFile.name)

            await api
              .post("/document/create/", fileData)
              .then(async (response) => {
                imageProfile = response.data._id

                this.setState({ image: imageProfile })

                newImageFile.uploadfileslist = []
              })
              .catch(() => {
                this.warningAlert("Não foi possível gravar a imagem do perfil.")
              })
          }
        }
      }
    }

    let newData = {
      fullName: newState.fullName,
      email: newState.email,
      oldEmail: newState.email,
      image: imageProfile,
    }

    let password = this.state.password

    if (password !== "" && password !== process.env.REACT_APP_DEFPWD) {
      newData = { ...newData, password: password }
    }

    try {
      //Update user profile
      await api.put("/user/update/" + this.state.id, newData)

      //Update interactions
      var response = await api.post("/interaction/search", {
        deleted: "N",
        user: this.state.id,
      })

      const interactions = response.data

      if (interactions.length > 0) {
        for (let i = 0; i < interactions.length; i++) {
          await api.put("/interaction/update/" + interactions[i]._id, {
            userFullName: newState.fullName,
          })
        }
      }

      //Set some filters
      var filterChildren = {
        deleted: "N",
      }

      if (this.state.isManagerConsulting || this.state.isResourceConsulting) {
        filterChildren = { ...filterChildren, analyst: this.state.id }
      } else {
        if (this.state.isManagerCustomer) {
          //Update projects
          response = await api.post("/project/search", {
            deleted: "N",
            responsible: this.state.id,
          })

          let projects = response.data

          if (projects.length > 0) {
            for (let i = 0; i < projects.length; i++) {
              await api.put("/project/update/" + projects[i]._id, {
                responsibleFullName: newState.fullName,
              })
            }
          }

          filterChildren = { ...filterChildren, manager: this.state.id }
        } else {
          filterChildren = { ...filterChildren, keyUser: this.state.id }
        }
      }

      //Update plans
      response = await api.post("/plan/search", filterChildren)

      const plans = response.data

      if (plans.length > 0) {
        for (let i = 0; i < plans.length; i++) {
          if (
            this.state.isManagerConsulting ||
            this.state.isResourceConsulting
          ) {
            await api.put("/plan/update/" + plans[i]._id, {
              analystFullName: newState.fullName,
            })
          } else {
            if (this.state.isManagerCustomer) {
              await api.put("/plan/update/" + plans[i]._id, {
                managerFullName: newState.fullName,
              })
            } else {
              await api.put("/plan/update/" + plans[i]._id, {
                keyUserFullName: newState.fullName,
              })
            }
          }
        }
      }

      //Update tests
      response = await api.post("/test/search", filterChildren)

      const tests = response.data

      if (tests.length > 0) {
        for (let i = 0; i < tests.length; i++) {
          if (
            this.state.isManagerConsulting ||
            this.state.isResourceConsulting
          ) {
            await api.put("/test/update/" + tests[i]._id, {
              analystFullName: this.state.fullName,
            })
          } else {
            if (this.state.isManagerCustomer) {
              await api.put("/test/update/" + tests[i]._id, {
                managerFullName: this.state.fullName,
              })
            } else {
              await api.put("/test/update/" + tests[i]._id, {
                keyUserFullName: this.state.fullName,
              })
            }
          }
        }
      }
    } catch (err) {
      this.warningAlert("Ocorreu um erro ao tentar salvar. Tente novamente.")
      this.setState({ saving: false, isLoading: false })
      success = false
    }

    if (success) {
      this.loadData()

      localStorage.setItem(process.env.REACT_APP_USERIMGID_KEY, imageProfile)

      this.setState({
        isLoading: false,
        saving: false,
        editing: false,
      })
    }
  }

  cancel = async (e) => {
    this.setState({ editing: false })

    this.loadData()
  }

  removeProfileImage = async () => {
    this.hideAlert()

    this.setState({ isLoading: true })

    let imageProfile = this.state.image
    let success = true

    if (imageProfile && imageProfile !== null) {
      try {
        await api.delete("/document/delete/" + imageProfile)

        await api.put("/user/update/" + this.state.id, { image: null })
      } catch (err) {
        this.warningAlert(
          "Houve um erro ao tentar excluir a imagem. Tente novamente."
        )
        success = false
      }
    }

    if (success) {
      this.setState({ imagem: null })
      localStorage.setItem(process.env.REACT_APP_USERIMGID_KEY, null)

      this.loadData()
    }

    this.setState({ isLoading: false })
  }

  render() {
    return (
      <>
        <ProfileHeader
          headerName={this.state.headerName}
          editProfile={this.editProfile.bind(this)}
          editing={this.state.editing}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-xl-0" xl="12">
              <Card className="card-profile">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a
                        href="#editProfile"
                        disabled={this.state.editing}
                        onClick={(e) => this.editProfile(e)}
                      >
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={
                            this.state.image
                              ? this.state.profileImageUrl
                              : require("assets/img/theme/no-user.png")
                          }
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardBody>
                  <Form>
                    <Row>
                      <Col lg="8">
                        <h6 className="heading-small text-muted mb-4">
                          Dados do seu perfil de usuário
                        </h6>
                      </Col>
                      <Col lg="4" className="text-right">
                        <Button
                          className="btn-neutral"
                          color="default"
                          href="#removeImg"
                          size="sm"
                          hidden={
                            this.state.editing ||
                            !this.state.image ||
                            this.state.image === null
                          }
                          onClick={(e) => this.confirmRemoveImage(e)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-trash" />
                          </span>
                          <span className="btn-inner--text">
                            Remover imagem
                          </span>
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="fullName"
                          >
                            Nome Completo
                          </label>
                          <Input
                            id="fullName"
                            placeholder="Digite seu nome completo"
                            type="text"
                            value={this.state.fullName}
                            onChange={(e) => this.stylesForm(e, "fullName")}
                            readOnly={!this.state.editing}
                            disabled={this.state.saving}
                            valid={
                              this.state.customStyles.fullNameState === "valid"
                            }
                            invalid={
                              this.state.customStyles.fullNameState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Digite seu nome.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <Input
                            id="email"
                            placeholder="exemplo@seu-email.com.br"
                            type="email"
                            autoComplete="new-password"
                            value={this.state.email}
                            onChange={(e) => this.stylesForm(e, "email")}
                            readOnly={!this.state.editing}
                            disabled={this.state.saving}
                            valid={
                              this.state.customStyles.emailState === "valid"
                            }
                            invalid={
                              this.state.customStyles.emailState === "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Digite um e-mail.
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="password"
                          >
                            Senha
                          </label>
                          <Input
                            aria-describedby="inputGroupPrepend"
                            id="password"
                            placeholder="Digite sua senha"
                            type="password"
                            autoComplete="new-password"
                            value={this.state.password}
                            readOnly={!this.state.editing}
                            disabled={this.state.saving}
                            valid={
                              this.state.customStyles.passwordState === "valid"
                            }
                            invalid={
                              this.state.customStyles.passwordState ===
                              "invalid"
                            }
                            onChange={(e) => this.stylesForm(e, "password")}
                          />
                          <div className="invalid-feedback">
                            Digite uma senha.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="password"
                          >
                            Confirme a senha
                          </label>
                          <Input
                            aria-describedby="inputGroupPrepend"
                            id="confirmPassword"
                            placeholder="Digite sua senha"
                            type="password"
                            autoComplete="new-password"
                            value={this.state.confirmPassword}
                            readOnly={!this.state.editing}
                            disabled={this.state.saving}
                            valid={
                              this.state.customStyles.confirmPasswordState ===
                              "valid"
                            }
                            invalid={
                              this.state.customStyles.confirmPasswordState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.stylesForm(e, "confirmPassword")
                            }
                          />
                          <div className="invalid-feedback">
                            Repita sua senha.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="6" hidden={!this.state.editing}>
                        <FormGroup>
                          <label className="form-control-label">
                            Imagem do Perfil
                          </label>
                          <MultipleFilesDropzone
                            instructions="Clique ou arraste uma imagem (resolução 512 x 512)"
                            maxFiles={1}
                            acceptedFiles="image/*"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-4" hidden={!this.state.editing} />
                    <Row>
                      <Col lg="12" className="text-right">
                        <Button
                          color="danger"
                          type="button"
                          hidden={!this.state.editing}
                          onClick={(e) => this.cancel(e)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color="success"
                          type="button"
                          hidden={!this.state.editing}
                          onClick={(e) => this.save(e)}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Loading modalOpened={this.state.isLoading} />
        {this.state.alert}
      </>
    )
  }
}

export default Profile
