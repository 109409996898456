import React from "react"
import moment from "moment"
import { Calendar, globalizeLocalizer } from "react-big-calendar"
import { Button, Col } from "reactstrap"

import globalize from "globalize"
const localizer = globalizeLocalizer(globalize)

function BigCalendar(props) {
  /**
   * gets the style for the events in the big calendar component
   * @param {*} event
   * @returns {Object}
   */
  function getEventStyle(event) {
    return event.type === "selected-schedule"
      ? { backgroundColor: "LightSkyBlue" }
      : event.type === "copied-schedule"
      ? { backgroundColor: "MediumSeaGreen" }
      : event.type === "copied-schedule-with-os"
      ? { backgroundColor: "Orange" }
      : event.type === "delete-schedule"
      ? { backgroundColor: "Crimson" }
      : event.type === "self-schedule"
      ? { backgroundColor: "Goldenrod" }
      : event.type === "busy-schedule"
      ? { backgroundColor: "Tomato" }
      : { backgroundColor: "white" }
  }

  return (
    <Col xs={12} style={{ marginTop: "5vh" }}>
      <Calendar
        events={props.events}
        startAccessor={(event) => new Date(event.start)}
        endAccessor={(event) => new Date(event.end)}
        defaultDate={moment().toDate()}
        localizer={localizer}
        style={{ height: "60vh" }}
        selectable
        views={["month"]}
        onSelectSlot={(e) => {
          if (props.schedule.status === "AP" || !props.schedule.status)
            props.setSelectedDates(e)
        }}
        onSelectEvent={(e) => {
          if (props.schedule.status === "AP" || !props.schedule.status)
            props.handleSelectEvent(e)

        }}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          style: getEventStyle(event),
        })}
      />
      <>
        <Button
          color="success"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            props.copySchedules()
          }}
          disabled={
            props.events.filter((ev) => ev.type === "selected-schedule")
              ?.length <= 0 || props.project.endDate
          }
          style={{ marginTop: "1vh" }}
        >
          Copiar agenda
        </Button>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            props.deleteSchedules()
          }}
          disabled={
            props.events.filter((ev) => ev.type === "delete-schedule")
              ?.length <= 0
          }
          style={{ marginTop: "1vh" }}
        >
          Apagar Agenda
        </Button>
      </>
    </Col>
  )
}
export default BigCalendar
