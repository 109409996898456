import "moment/locale/pt-br";
import React, { useCallback, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PropTypes from "prop-types";

import { Button, Col, Input, Modal, ModalBody } from "reactstrap";
import ConfirmationModal from "./ConfirmationModal";
import NationalHollidaysTable from "components/Tables/admin/NationalHollidaysTable";
import { Row } from "react-grid";
import api from "services/api";

function NationalHollidays({
  isOpen,
  closeModal,
  importHollidays,
  warningAlert,
}) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [importYear, setImportYear] = useState("");
  const [destinationYear, setDestinationYear] = useState(
    new Date().toISOString().split("-")[0]
  );
  const [hollidays, setHollidays] = useState();

  async function fetchApiHollidays() {
    try {
      const hollidays = await fetch(
        "https://brasilapi.com.br/api/feriados/v1/" + importYear
      );
      const data = await hollidays.json();

      if (Array.isArray(data))
        setHollidays(
          data.map((holliday) => ({
            date: new Date(holliday.date).toISOString(),
            reason: holliday.name,
            selected: true,
            hours: "08:00",
          }))
        );
      else {
        throw Error(data.message);
      }
    } catch (err) {
      warningAlert({ color: "danger", message: err.message });
    }
  }
  async function fetchOurHollidays() {
    const dateFrom = importYear + "-01-01T00:00:00.000Z";
    const dateTo = parseInt(importYear) + 1 + "-01-01T00:00:00.000Z";
    const hollidays = await api.post("holliday/search", {
      date: { $gte: dateFrom, $lt: dateTo },
    });

    if (hollidays.data.length < 1) {
      warningAlert({ color: "warning", message: "Não há feriados nesse ano" });
      return;
    }

    setHollidays(
      hollidays.data.map((holliday) => ({
        date: holliday.date,
        reason: holliday.reason,
        selected: true,
        hours: holliday.hours,
      }))
    );
  }
  function changeHollidaySelected(rowIndex) {
    setHollidays((prev) =>
      prev.with(rowIndex, {
        ...prev[rowIndex],
        selected: !prev[rowIndex].selected,
      })
    );
  }
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="xl"
        isOpen={isOpen}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title"> Importação de feriados</h5>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => closeModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalBody style={{ overflowX: "auto" }} id="appointments-table">
          <Row>
            <Col xs={2}>
              <label className="form-control-label" htmlFor="year">
                Ano de Importação
              </label>
              <Input
                aria-describedby="inputGroupPrepend"
                id="year"
                type="number"
                size={"md"}
                importYear
                onChange={(e) => setImportYear(e.target.value)}
              />
            </Col>
            <Col xs={2}>
              <label className="form-control-label" htmlFor="year">
                Ano de Destino
              </label>
              <Input
                aria-describedby="inputGroupPrepend"
                id="year"
                type="number"
                size={"md"}
                value={destinationYear}
                onChange={(e) => setDestinationYear(e.target.value)}
              />
            </Col>
            <Col xs={2}>
              <Button
                type="button"
                onClick={(e) => fetchApiHollidays()}
                style={{ marginTop: "3.4vh" }}
                size="md"
              >
                Importar da API
              </Button>
            </Col>
            <Col xs={2}>
              <Button
                type="button"
                onClick={(e) => fetchOurHollidays()}
                style={{ marginTop: "3.4vh" }}
                size="md"
              >
                Importar do Sgl
              </Button>
            </Col>
            <Col xs={2}></Col>
          </Row>
          <hr></hr>
          <NationalHollidaysTable
            hollidays={hollidays}
            changeHollidaySelected={(rowIndex) =>
              changeHollidaySelected(rowIndex)
            }
          />
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="success"
            type="button"
            disabled={!Array.isArray(hollidays) || hollidays.length < 1}
            onClick={(e) => setConfirmationModalOpen(true)}
          >
            Importar
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        text={
          "Importar feriados do ano " +
          importYear +
          " para o ano " +
          destinationYear +
          " ?"
        }
        confirm={() => {
          importHollidays(
            hollidays
              .filter((holliday) => holliday.selected)
              .map((holliday) => ({
                date:
                  destinationYear +
                  "-" +
                  holliday.date.split("-")[1] +
                  "-" +
                  holliday.date.split("-")[2],
                reason: holliday.reason,
                hours: holliday.hours,
              }))
          );
        }}
        closeModal={() => setConfirmationModalOpen(false)}
        deny={() => setConfirmationModalOpen(false)}
      />
    </>
  );
}

NationalHollidays.propTypes = {
  title: PropTypes.string.isRequired,
  pendency: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
};
export default NationalHollidays;
