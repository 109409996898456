// react plugin used to create DropdownMenu for selecting items
// reactstrap components
import { useState } from "react"
import { Button, Input, Modal, ModalBody } from "reactstrap"

export default function FinishProjectModal(props) {
  const [date, setDate] = useState("")
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={props.isOpen}
      autoFocus={false}      
    >
      <div className="modal-header">
        <h5 className="modal-title">Finalizar projeto</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody>
        <h3>Insira a data de finalização do projeto</h3>
        <Input
          id="date"
          type="date"
          value={date}
          onChange={(e) => {
            setDate(e.target.value)
          }}
        />
      </ModalBody>

      <div className="modal-footer">
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            props.closeModal()
          }}
        >
          Cancelar
        </Button>
        <Button
          color="success"
          type="button"
          onClick={(e) => {
            props.updateProject(date)
          }}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  )
}
