import SglTable from "components/ToolkitTable/SglTable"
import React, { useEffect, useMemo, useState } from "react"
import { Col, Row } from "reactstrap"

const columns = [
  {
    dataField: "customer",
    text: "Cliente",
    sort: true,
    hidden: false,
  },
  {
    dataField: "hourlyRate",
    text: "Valor Hora",
    sort: true,
  },
  {
    dataField: "maxCostHour",
    text: "Custo Hr Máximo",
    sort: true,
  },
  {
    dataField: "performedHours",
    text: "Horas Realizadas",
    sort: true,
  },
  {
    dataField: "billedHours",
    text: "Horas Faturadas",
    sort: true,
  },
  {
    dataField: "maximumCost",
    text: "Custo Máximo",
    sort: true,
  },
  {
    dataField: "performedCost",
    text: "Custo Realizado",
    sort: true,
  },
  {
    dataField: "difference",
    text: "Diferença",
    sort: true,
  },
]

function ServiceDeskTable(props) {
  return (
    <>
      {props.data.length > 0 && (
        <SglTable
          className="serviceDeskTable"
          columns={columns}
          data={props.data}
          rowClick={() => {}}
        />
      )}
    </>
  )
}
export default ServiceDeskTable
