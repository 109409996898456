import React from "react";
// reactstrap components
import { ListGroupItem, Form, Row, Col, Input } from "reactstrap";

import InputMask from "react-input-mask";

class ListGroupItemContact extends React.Component {
  render() {
    return (
      <>
        {this.props.contactCrud === "C" || this.props.contactCrud === "U" ? (
          <ListGroupItem
            className="list-group-item-action flex-column align-items-start py-4 px-4"
            tag="a"
          >
            <div className="d-flex w-100 justify-content-center">
              <div>
                <div className="d-flex w-100 align-items-center">
                  <h4 className="mb-1">
                    {this.props.contactCrud === "C"
                      ? "Novo Contato"
                      : "Alterando Contato"}
                  </h4>
                </div>
              </div>
            </div>
            <Form className="needs-validation" noValidate autoComplete="off">
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newContactName"
                  >
                    Nome Completo
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newContactName"
                    placeholder="Nome completo do contato"
                    type="text"
                    value={this.props.newContactName}
                    disabled={this.props.saving}
                    valid={
                      this.props.customStyles.newContactNameState === "valid"
                    }
                    invalid={
                      this.props.customStyles.newContactNameState === "invalid"
                    }
                    onChange={(e) => this.props.stylesForm(e, "newContactName")}
                  />
                  <div className="invalid-feedback">
                    Digite o nome do contato.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newContactPhone"
                  >
                    Fone
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newContactPhone"
                    placeholder="Fone do contato"
                    mask={this.props.phoneMaskBuilder(
                      this.props.newContactPhone
                    )}
                    maskChar=""
                    type="tel"
                    tag={InputMask}
                    value={this.props.newContactPhone}
                    disabled={this.props.saving}
                    valid={
                      this.props.customStyles.newContactPhoneState === "valid"
                    }
                    invalid={
                      this.props.customStyles.newContactPhoneState === "invalid"
                    }
                    onChange={(e) =>
                      this.props.stylesForm(e, "newContactPhone")
                    }
                  />
                  <div className="invalid-feedback">
                    Digite o telefone do contato.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newContactEmail"
                  >
                    E-mail
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newContactEmail"
                    placeholder="E-mail do contato"
                    type="text"
                    value={this.props.newContactEmail}
                    disabled={this.props.saving}
                    valid={
                      this.props.customStyles.newContactEmailState === "valid"
                    }
                    invalid={
                      this.props.customStyles.newContactEmailState === "invalid"
                    }
                    onChange={(e) =>
                      this.props.stylesForm(e, "newContactEmail")
                    }
                  />
                  <div className="invalid-feedback">
                    Digite o e-mail do contato.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newContactDepartment"
                  >
                    Setor
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newContactDepartment"
                    placeholder="Setor do contato"
                    type="text"
                    value={this.props.newContactDepartment}
                    disabled={this.props.saving}
                    valid={
                      this.props.customStyles.newContactDepartmentState ===
                      "valid"
                    }
                    invalid={
                      this.props.customStyles.newContactDepartmentState ===
                      "invalid"
                    }
                    onChange={(e) =>
                      this.props.stylesForm(e, "newContactDepartment")
                    }
                  />
                  <div className="invalid-feedback">
                    Digite o setor do contato.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newContactNotes"
                  >
                    Observações
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newContactNotes"
                    placeholder="Ex.: somente horário comercial"
                    type="text"
                    value={this.props.newContactNotes}
                    disabled={this.props.saving}
                    onChange={(e) =>
                      this.props.stylesForm(e, "newContactNotes")
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="6" lg="4">
                  <label
                    className="form-control-label"
                    htmlFor="newContactBilling"
                  >
                    Recebe Cobrança
                  </label>
                  <div>
                    <label className="custom-toggle custom-toggle-default mr-1">
                      <input
                        defaultChecked={this.props.newContactBilling}
                        type="checkbox"
                        checked={this.props.newContactBilling}
                        disabled={this.props.saving}
                        onChange={(e) =>
                          this.props.stylesForm(e, "newContactBilling", true)
                        }
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Não"
                        data-label-on="Sim"
                      />
                    </label>
                  </div>
                </Col>
                <Col className="mb-3" md="6" lg="4">
                  <label
                    className="form-control-label"
                    htmlFor="newContactNfse"
                  >
                    Recebe NF
                  </label>
                  <div>
                    <label className="custom-toggle custom-toggle-default mr-1">
                      <input
                        defaultChecked={this.props.newContactNfse}
                        type="checkbox"
                        checked={this.props.newContactNfse}
                        disabled={this.props.saving}
                        onChange={(e) =>
                          this.props.stylesForm(e, "newContactNfse", true)
                        }
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Não"
                        data-label-on="Sim"
                      />
                    </label>
                  </div>
                </Col>
              </Row>
            </Form>
            {this.props.contactCrud === "C" ? (
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <div className="d-flex w-100 align-items-center">
                    <h4 className="mb-1"> </h4>
                  </div>
                  <div className="d-flex w-100 align-items-center">
                    <small>
                      <a
                        href="#saveNewContact"
                        className="text-success"
                        onClick={(e) =>
                          this.props.saveNewContact(e, this.props)
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-check-bold" />
                        </span>
                        Salvar
                      </a>
                      <a
                        href="#cancelNewContact"
                        className="text-danger"
                        onClick={(e) => this.props.cancelNewContact(e)}
                      >
                        <span className="btn-inner--icon mr-1">
                          {" | "}
                          <i className="ni ni-fat-remove" />
                        </span>
                        Cancelar
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.contactCrud === "U" ? (
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <div className="d-flex w-100 align-items-center">
                    <h4 className="mb-1"> </h4>
                  </div>
                  <div className="d-flex w-100 align-items-center">
                    <small>
                      <a
                        href="#updateContact"
                        className="text-success"
                        onClick={(e) => this.props.updateContact(e, this.props)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-check-bold" />
                        </span>
                        Salvar Alterações
                      </a>
                      <a
                        href="#cancelContactUpdate"
                        className="text-danger"
                        onClick={(e) =>
                          this.props.cancelContactUpdate(e, this.props)
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          {" | "}
                          <i className="ni ni-fat-remove" />
                        </span>
                        Cancelar Alterações
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            ) : null}
          </ListGroupItem>
        ) : (
          <ListGroupItem
            className="list-group-item-action flex-column align-items-start py-4 px-4"
            tag="a"
            hidden={this.props.neededAction === "D"}
          >
            <div className="d-flex w-100 justify-content-between">
              <div>
                <div className="d-flex w-100 align-items-center">
                  <h4 className="mb-1">{this.props.name}</h4>
                </div>
              </div>
              <small className="text-info">
                {this.props.billing ? (
                  <>
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-money-coins" />
                    </span>
                    Cobrança
                  </>
                ) : null}
                {this.props.nfse ? (
                  <>
                    <span className="btn-inner--icon mr-1">
                      {this.props.billing ? " | " : ""}
                      <i className="ni ni-single-copy-04" />
                    </span>
                    NF
                  </>
                ) : null}
              </small>
            </div>
            <h5 className="mb-0">
              Fone:
              <span className="text-muted">{" " + this.props.phone}</span>
            </h5>
            <h5 className="mb-0">
              E-mail:
              <span className="text-muted">{" " + this.props.email}</span>
            </h5>
            <h5 className="mb-0">
              Setor:
              <span className="text-muted">{" " + this.props.department}</span>
            </h5>
            <h5 className="mb-0">
              Observações:
              <span className="text-muted">
                {" " + this.props.notes && this.props.notes !== null
                  ? this.props.notes
                  : ""}
              </span>
            </h5>
            {(this.props.crud === "C" || this.props.crud === "U") &&
            this.props.contactCrud !== "D" &&
            !this.props.creatingContact &&
            !this.props.updatingContact ? (
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <div className="d-flex w-100 align-items-center">
                    <h4 className="mb-1"> </h4>
                  </div>
                  <div className="d-flex w-100 align-items-center">
                    <small>
                      <a
                        href="#updateContact"
                        onClick={(e) => this.props.updateContact(e, this.props)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                        </span>
                        Alterar
                      </a>
                      <a
                        href="#deleteContact"
                        className="text-danger"
                        onClick={(e) => this.props.deleteContact(e, this.props)}
                      >
                        <span className="btn-inner--icon mr-1">
                          {" | "}
                          <i className="fas fa-trash" />
                        </span>
                        Excluir
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.contactCrud === "D" ? (
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <div className="d-flex w-100 align-items-center">
                    <h4 className="mb-1"> </h4>
                  </div>
                  <div className="d-flex w-100 align-items-center">
                    <small>
                      <a
                        href="#deleteContact"
                        className="text-success"
                        onClick={(e) => this.props.deleteContact(e, this.props)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-trash" />
                        </span>
                        Confirmar Exclusão
                      </a>
                      <a
                        href="#cancelContactDelete"
                        className="text-danger"
                        onClick={(e) =>
                          this.props.cancelContactDelete(e, this.props)
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          {" | "}
                          <i className="ni ni-fat-remove" />
                        </span>
                        Cancelar
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            ) : null}
          </ListGroupItem>
        )}
      </>
    );
  }
}

export default ListGroupItemContact;
