import React from "react"
import { Button, Col, Input, Row } from "reactstrap"
import PropTypes from "prop-types"

function ProjectIndicatorTableHeader(props) {
  return (
    <>
      <Row>
        <Col className="mb-3 mt-3" lg="3">
          <label className="form-control-label" htmlFor="date">
            Data
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="date"
            type="month"
            value={props.date}
            onChange={(e) => {
              props.setDate(e.target.value)
            }}
          />
        </Col>
        <Col className="mt-4" lg="1" style={{ textAlignLast: "end" }}>
          <Button
            type="button"
            style={{ marginTop: "23px" }}
            onClick={(e) => props.fetchTableData()}
            disabled={!props.date}
          >
            Buscar
          </Button>
        </Col>
        <Col className="mt-4" lg="8" style={{ textAlignLast: "end" }}>
          {props.dataset.length > 1 && (
            <Button
              type="button"
              color="info"
              style={{ marginTop: "23px" }}
              onClick={(e) => props.downloadSpreadSheet()}
            >
              Baixar
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}
ProjectIndicatorTableHeader.propTypes = {
  dataset: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  setProject: PropTypes.func.isRequired,
  fetchTableData: PropTypes.func.isRequired,
  downloadSpreadSheet: PropTypes.func.isRequired,
}
export default ProjectIndicatorTableHeader
