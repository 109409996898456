import React, { useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"

function MobileSchedulesTableHeader(props) {
  return (
    <Row>
      <Col xs={"12"} className="pt-3">
        <label className="form-control-label" htmlFor="from">
          De
        </label>
        <Input
          id="from"
          type="date"
          value={props.from}
          onChange={(e) => {
            props.setFrom(e.target.value)
          }}
        />
      </Col>
      <Col xs={"12"} className="pt-3">
        <label className="form-control-label" htmlFor="to">
          até
        </label>
        <Input
          id="to"
          type="date"
          value={props.to}
          onChange={(e) => {
            props.setTo(e.target.value)
          }}
        />
      </Col>
    </Row>
  )
}

export default MobileSchedulesTableHeader
