import Tag from "components/Tag/Tag"
import React, { useState } from "react"

import { Row, Table, UncontrolledTooltip } from "reactstrap"

function SchedulesTable(props) {
  function getCustomer(id) {
    return props.customers.find((customer) => customer._id === id)
  }

  function getProject(id) {
    const project = props.projects.find((project) => project._id === id)
    return project.name.replace(/\s\s+/g, " ")
  }

  function getAddress(id) {
    if (!props.adresses) return ""
    const adress = props.adresses.find((address) => address._id === id)
    if (adress?.address) return adress.address.replace(/\s\s+/g, " ")
    return ""
  }

  function getScheduleTagColor(schedule) {
    if (schedule.status === "PA") return "var(--yellow)"
    else if (schedule.status === "RJ") return "#000000"
    let date = new Date()
    const dayOfWeek = date.getDay()
    if (dayOfWeek === 0) {
      date.setDate(date.getDate() - 4)
    } else if (dayOfWeek === 6) {
      date.setDate(date.getDate() - 3)
    } else if (dayOfWeek === 1) {
      date.setDate(date.getDate() - 4)
    } else {
      date.setDate(date.getDate() - 2)
    }
    const isServiceOrderLate =
      new Date(schedule.createdAt) < date && new Date(schedule.date) < date
    return schedule.serviceOrder
      ? "var(--success)"
      : !schedule.serviceOrder && isServiceOrderLate
      ? "var(--warning)"
      : undefined
  }

  const getSchedules = (date) => {
    let schedules = []
    schedules = props.consultant?.schedules?.filter(
      (schedule) => schedule.date.split("T")[0] === date.fullDate.split("T")[0]
    )
    return schedules || []
  }

  return (
    <Table hidden={!props.columns.length}>
      <thead>
        <tr>
          {props.columns.map((date, index) => {
            return (
              <th
                key={index}
                style={{
                  zIndex: "3",
                  position: "sticky",
                  top: "0",
                  background: "white",
                }}
              >
                <Row style={{ justifyContent: "center" }}>{date.weekday}</Row>
                <Row style={{ justifyContent: "center" }}>{date.day}</Row>
              </th>
            )
          })}
        </tr>
      </thead>

      <tbody>
        <tr>
          {props.columns.map((date, columnsIndex) => {
            let schedules = getSchedules(date)
            if (schedules.length > 0) {
              return (
                <td key={columnsIndex}>
                  {schedules.map((schedule, scheduleIndex) => {
                    return (
                      <Row
                        id={`schedule-${schedule._id}-row-${scheduleIndex}`}
                        style={{
                          justifyContent: "center",
                          margin:
                            scheduleIndex !== schedules.length - 1
                              ? " 1vh 0vw 1vh 0vw"
                              : " 1vh 0vw 25vh 0vw",
                        }}
                        key={scheduleIndex}
                        onClick={() => {
                          props.handleScheduleModal(true)
                          props.handleSelectSchedule(schedule)
                        }}
                      >
                        <Tag backgroundColor={getScheduleTagColor(schedule)}>
                          {getCustomer(schedule.customer).shortName}
                        </Tag>
                        <UncontrolledTooltip
                          delay={0}
                          target={`schedule-${schedule._id}-row-${scheduleIndex}`}
                        >
                          <div
                            style={{
                              padding: "1vh 2vh 1vh 2vh",
                            }}
                          >
                            <Row className="pb-2" style={{ textAlign: "left" }}>
                              {getProject(schedule.project) ??
                                "Sem projeto definido"}
                            </Row>
                            <Row className="pb-2" style={{ textAlign: "left" }}>
                              {getAddress(schedule.address) ??
                                "Sem Endereço definido"}
                            </Row>
                            <Row style={{ textAlign: "left" }}>
                              Início {schedule.timeFrom}
                            </Row>
                            <Row className="pb-2" style={{ textAlign: "left" }}>
                              Fim {schedule.timeTo}
                            </Row>
                          </div>
                        </UncontrolledTooltip>
                      </Row>
                    )
                  })}
                </td>
              )
            } else {
              return (
                <td key={columnsIndex}>
                  <div style={{ width: "12vh" }}></div>
                </td>
              )
            }
          })}
        </tr>
      </tbody>
    </Table>
  )
}
export default SchedulesTable
