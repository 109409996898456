import { keyFactory } from "helpers/menuAccess_helper"
import React from "react"
import { Col, Form, Input, Row } from "reactstrap"

export default function MenuAccessBodyForm(props) {
  return (
    <Form className="needs-validation" noValidate autoComplete="off">
      <div className="form-row">
        {/* Date */}
        <Col className="mb-3" xs="12" md="2">
          <label className="form-control-label" htmlFor="code">
            Código
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="code"
            type="text"
            value={props.menuAccessBody.code}
            readOnly={props.readOnly}
            disabled={props.saving || props.disableCode}
            valid={props.menuAccessBody.code !== ""}
            invalid={props.menuAccessBody.code === ""}
            onChange={(e) => {
              props.setMenuAccessBody({ code: e.target.value })
            }}
          />
          <div className="invalid-feedback">Código é obrigatório.</div>
        </Col>
        <Col className="mb-3" md="10">
          <label className="form-control-label" htmlFor="name">
            Nome
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="name"
            type="text"
            value={props.menuAccessBody.name}
            readOnly={props.readOnly}
            disabled={props.saving}
            valid={props.menuAccessBody.name !== ""}
            invalid={props.menuAccessBody.name === ""}
            onChange={(e) => {
              props.setMenuAccessBody({ name: e.target.value })
            }}
          />
          <div className="invalid-feedback">Nome é obrigatório.</div>
        </Col>

        <Col className="mb-3" md="12">
          <label className="form-control-label" htmlFor="accesses">
            Acessos
          </label>
          <ul style={{ fontSize: "14px" }}>
            <Row>
              <Col md="6">
                {props.menuAccessBody?.menu &&
                  Object.keys(props.menuAccessBody.menu)
                    .slice(0, Object.keys(props.menuAccessBody.menu).length / 2)
                    .map((key, index) => {
                      return (
                        <li key={index}>
                          <Row>
                            <Col md="8">{keyFactory(key)}</Col>
                            <Col md="4">
                              <label className="custom-toggle custom-toggle-default mb-2">
                                <input
                                  type="checkbox"
                                  checked={props.menuAccessBody.menu[key]}
                                  value={props.menuAccessBody.menu[key]}
                                  onChange={(e) => {
                                    props.setMenuAccessBody({
                                      menu: {
                                        ...props.menuAccessBody.menu,
                                        [key]: !props.menuAccessBody.menu[key],
                                      },
                                    })
                                  }}
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="Não"
                                  data-label-on="Sim"
                                />
                              </label>
                            </Col>
                          </Row>
                        </li>
                      )
                    })}
              </Col>
              <Col md="6">
                {props.menuAccessBody?.menu &&
                  Object.keys(props.menuAccessBody.menu)
                    .slice(Object.keys(props.menuAccessBody.menu).length / 2)
                    .map((key, index) => {
                      return (
                        <li key={index}>
                          <Row>
                            <Col md="8">{keyFactory(key)}</Col>
                            <Col md="4">
                              <label className="custom-toggle custom-toggle-default mb-2">
                                <input
                                  type="checkbox"
                                  checked={props.menuAccessBody.menu[key]}
                                  value={props.menuAccessBody.menu[key]}
                                  onChange={(e) => {
                                    props.setMenuAccessBody({
                                      menu: {
                                        ...props.menuAccessBody.menu,
                                        [key]: !props.menuAccessBody.menu[key],
                                      },
                                    })
                                  }}
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="Não"
                                  data-label-on="Sim"
                                />
                              </label>
                            </Col>
                          </Row>
                        </li>
                      )
                    })}
              </Col>
            </Row>
          </ul>

          <div className="invalid-feedback">Digite uma Descrição</div>
        </Col>
        {/* Date */}
      </div>
    </Form>
  )
}
