function convertMongoDate(date) {
  if (!date) return ""
  return new Date(date.split("T")[0] + " 00:00").toLocaleDateString("pt-BR")
}
function convertMongoDateToDateInput(date) {
  if (!date) return ""
  return date.split("T")[0]
}
function getMidnightThisDay(date) {
  if (!date) return new Date().toISOString()
  return new Date(`${date} 20:59:59`).toISOString()
}
function getFirstDayOfNextMonth(date) {
  if (!date) date = new Date()
  return new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1,
    -3,
    0,
    0
  ).toISOString()
}
function convertMongoDateAndHour(date) {
  return `${new Date(date).toLocaleDateString("pt-BR")} ${new Date(
    date
  ).toLocaleTimeString("pt-BR", {
    timeStyle: "short",
    timeZone: "America/Sao_Paulo",
  })}`
}

export {
  convertMongoDateToDateInput,
  convertMongoDate,
  convertMongoDateAndHour,
  getMidnightThisDay,
  getFirstDayOfNextMonth,
}
