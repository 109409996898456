import React from "react";
import { Button, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { userKind } from "services/auth";
import { BrowserView, MobileView } from "react-device-detect";

function PendenciesTableHeader(props) {
  return (
    <>
      <Row>
        <Col lg="6">
          <h3 className="mb-0">Pendências</h3>
        </Col>
      </Row>
      {userKind() === "M" && (
        <>
          <BrowserView>
            <Row>
              <Col xs={6} className="mb-3 mt-3" />
              <Col
                xs={6}
                style={{ textAlignLast: "end" }}
                className="mb-3 mt-3"
              >
                <Button
                  onClick={() => props.openEmailRoutineModal()}
                  color="info"
                >
                  Rotina de envio
                </Button>
                <Button
                  onClick={() => props.openConfirmationModal()}
                  color="danger"
                >
                  Enviar para todos
                </Button>
              </Col>
            </Row>
          </BrowserView>
          <MobileView>
            <Row style={{ textAlignLast: "end" }} className="mb-3 mt-3">
              <Button
                size="sm"
                onClick={() => props.openEmailRoutineModal()}
                color="info"
              >
                Rotina de envio
              </Button>
              <Button
                size="sm"
                onClick={() => props.openConfirmationModal()}
                color="danger"
              >
                Enviar para todos
              </Button>
            </Row>
          </MobileView>
        </>
      )}
    </>
  );
}
PendenciesTableHeader.propTypes = {
  sendAll: PropTypes.func,
};

export default PendenciesTableHeader;
