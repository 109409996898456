import api from "services/api"
import LightHeader from "components/Headers/LightHeader"
import Loading from "components/Modals/Loading"
import React, { useState, useEffect, useCallback } from "react"
import ReactBSAlert from "react-bootstrap-sweetalert"

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap"
import HollidaysTable from "components/Tables/admin/HollidaysTable"
import CreateHolliday from "components/Modals/create/CreateHolliday"
import { convertMongoDate } from "utils/dateHandling"
import Holliday from "components/Modals/show-edit/Holliday"
import NationalHollidays from "components/Modals/NationalHollidays"
/*
  TODO:
  fix the get all user method to get schedules with all inner data
*/
export default function Hollidays() {
  //component
  const [isLoading, setIsLoading] = useState(false)
  const [alert, setAlert] = useState(null)

  const [isCreateHollidayOpen, setIsCreateHollidayOpen] = useState(false)
  const [isNationalHollidaysOpen, setIsNationalHollidaysOpen] = useState(false)

  const [isEditHollidayOpen, setIsEditHollidayOpen] = useState(false)
  const [selectedHolliday, setSelectedHolliday] = useState({})

  const [hollidays, setHollidays] = useState([])

  async function importHollidays(data) {
    setIsLoading(true)
    try {
      await api.post("holliday/createMany", data)
      fetchHollidays()
      setIsNationalHollidaysOpen(false)
      warningAlert({
        color: "success",
        message: "Feriados importados",
      })
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const warningAlert = useCallback((props) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle={props.color}
        confirmBtnText="Ok"
        btnSize=""
      >
        {props.message}
      </ReactBSAlert>
    )
  }, [])

  const fetchHollidays = useCallback(async () => {
    setIsLoading(true)
    try {
      const hollidaysResponse = await api.get("holliday/all")
      setHollidays(hollidaysResponse.data)
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.error + "\n" + error.messsage,
      })
    } finally {
      setIsLoading(false)
    }
  }, [warningAlert])

  const hideAlert = () => {
    setAlert(null)
  }

  useEffect(() => {
    fetchHollidays()
  }, [fetchHollidays])
  return (
    <>
      <LightHeader title="Feriados" parent="Cadastros" menuTitle="Feriados" />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Feriados</h3>
                  </Col>
                  <Col xs="6" style={{ textAlignLast: "end" }}>
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      onClick={(e) => setIsCreateHollidayOpen(true)}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Novo Feriado</span>
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="12" style={{ textAlignLast: "end" }}>
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      onClick={(e) => setIsNationalHollidaysOpen(true)}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Importar Feriados</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody
                style={{
                  padding: "0px 0px 0px 0px",
                  height: "auto",
                }}
              >
                <HollidaysTable
                  isLoading={isLoading}
                  hollidays={hollidays}
                  rowClick={(row) => {
                    setSelectedHolliday(row)
                    setIsEditHollidayOpen(true)
                  }}
                ></HollidaysTable>
              </CardBody>
            </Card>
          </div>
        </Row>
        <CreateHolliday
          isOpen={isCreateHollidayOpen}
          closeModal={() => setIsCreateHollidayOpen(false)}
          setLoading={(value) => setIsLoading(value)}
          refetchTable={() => fetchHollidays()}
          warningAlert={(props) => warningAlert(props)}
        />
        {selectedHolliday._id ? (
          <Holliday
            isOpen={isEditHollidayOpen}
            setLoading={(value) => setIsLoading(value)}
            closeModal={() => {
              setIsEditHollidayOpen(false)
              setSelectedHolliday({})
            }}
            holliday={selectedHolliday}
            refetchTable={() => fetchHollidays()}
            warningAlert={(props) => warningAlert(props)}
          />
        ) : (
          <></>
        )}
        <NationalHollidays
          isOpen={isNationalHollidaysOpen}
          importHollidays={(data) => importHollidays(data)}
          closeModal={() => setIsNationalHollidaysOpen(false)}
          warningAlert={(props) => warningAlert(props)}
        />

        <Loading modalOpened={isLoading} />
      </Container>
      {alert}
    </>
  )
}
