import React from "react"
import { Button, Col, Input, Row } from "reactstrap"

function InvoicesForecastTableHeader(props) {
  return (
    <>
      <Row></Row>
      <Row>
        <Col className="mb-3 mt-3" lg="2">
          <label className="form-control-label" htmlFor="from">
            Início
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="from"
            type="date"
            value={props.filters.from}
            onChange={(e) => {
              props.setFilters({ from: e.target.value })
            }}
          />
        </Col>
        <Col className="mb-3 mt-3" lg="2">
          <label className="form-control-label" htmlFor="to">
            Fim
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="to"
            type="date"
            value={props.filters.to}
            onChange={(e) => {
              props.setFilters({ to: e.target.value })
            }}
          />
        </Col>
        <Col className="mt-4" lg="1" style={{ textAlignLast: "end" }}>
          <Button
            type="button"
            id="authorizeAll"
            style={{ marginTop: "23px" }}
            onClick={(e) => {
              props.fetchSchedules()
            }}
          >
            Filtrar
          </Button>
        </Col>
        <Col className="mt-5" lg="7" style={{ textAlignLast: "end" }}>
          <h3>
            {" "}
            {props.periodWorkingHours
              ? "Horas úteis do período: " + props.periodWorkingHours
              : ""}
          </h3>
        </Col>
      </Row>
    </>
  )
}

export default InvoicesForecastTableHeader
