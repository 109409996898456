import React, { useCallback, useEffect, useState } from "react"
import { Button, Col, Nav, NavLink, Row, UncontrolledTooltip } from "reactstrap"
import api from "services/api"
import Select2 from "react-select2-wrapper"
import classNames from "classnames"

const headerSubtext = {
  fontWeight: "bold",
  textJustify: "left",
  marginLeft: "1vw",
  cursor: "pointer",
}

function BillingReleaseTableHeader(props) {
  const [customers, setCustomers] = useState([])
  const [consultants, setConsultants] = useState([])
  const [services, setServices] = useState([])

  /**
   *
   */
  const getCustomers = useCallback(async () => {
    const customersResponse = await api.get("customer/all")
    setCustomers(customersResponse.data)
  }, [])
  /**
   *
   */
  const getConsultants = useCallback(async () => {
    const consultantsResponse = await api.post("user/search", { class: "CG" })
    setConsultants(consultantsResponse.data)
  }, [])
  /**
   *
   */
  const getServices = useCallback(async () => {
    const servicesResponse = await api.get("service/all")
    setServices(servicesResponse.data)
  }, [])
  /**
   * gets Data from database
   */
  useEffect(() => {
    async function getData() {
      getCustomers()
      getConsultants()
      getServices()
    }
    getData()
  }, [getConsultants, getCustomers, getServices])
  return (
    <>
      <Row>
        <Col xs="12" lg="3">
          <h3 className="mb-0">Liberação de Faturamentos</h3>
        </Col>
      </Row>
      <Row className="mt-4">
        {props.activeTab === "1" && (
          <Col className="mb-3" lg="3">
            <label className="form-control-label" htmlFor="consultant">
              Filtrar por consultor
            </label>
            <Select2
              className="form-control"
              type="text"
              id="consultant"
              value={props.filters.consultant}
              options={{
                hidden: true,
                size: "small",
                placeholder: "Selecione um Consultor",
                language: {
                  noResults: function () {
                    return "Nenhum cosultor encontrado."
                  },
                },
              }}
              data={[
                { id: 0, text: "Todos" },
                ...consultants.map((consultant) => {
                  return {
                    id: consultant._id,
                    text: consultant.fullName,
                  }
                }),
              ]}
              onSelect={(e) => {
                props.setFilters({ consultant: e.target.value })
              }}
            ></Select2>
          </Col>
        )}
        {props.activeTab === "2" && (
          <Col className="mb-3" lg="3">
            <label className="form-control-label" htmlFor="project">
              Filtrar por Serviço
            </label>
            <Select2
              className="form-control"
              type="text"
              id="project"
              value={props.filters.service}
              options={{
                placeholder: "Selecione um Serviço",
                language: {
                  noResults: function () {
                    return "Nenhum serviço encontrado."
                  },
                },
              }}
              data={[
                { id: 0, text: "Todos" },
                ...services.map((service) => {
                  return {
                    id: service._id,
                    text: service.name,
                  }
                }),
              ]}
              onSelect={(e) => {
                props.setFilters({ service: e.target.value })
              }}
            ></Select2>
          </Col>
        )}

        <Col className="mb-3" lg="3">
          <label className="form-control-label" htmlFor="customer">
            Filtrar por Cliente
          </label>
          <Select2
            className="form-control"
            type="text"
            id="customer"
            value={props.filters.customer}
            options={{
              hidden: true,
              size: "small",
              placeholder: "Selecione um Cliente",
              language: {
                noResults: function () {
                  return "Nenhum cliente encontrado."
                },
              },
            }}
            data={[
              { id: 0, text: "Todos" },
              ...customers.map((customer) => {
                return {
                  id: customer._id,
                  text: customer.shortName,
                }
              }),
            ]}
            onSelect={(e) => {
              props.setFilters({ customer: e.target.value })
            }}
          ></Select2>
        </Col>
        <Col className="mb-3" lg="3"></Col>
        <Col className="mt-3" lg="3" style={{ textAlignLast: "end" }}>
          <Button
            color="success"
            type="button"
            id="authorizeAll"
            className="mt-3"
            onClick={(e) => props.save()}
          >
            Salvar
          </Button>
        </Col>
      </Row>
      <Row>
        <Nav tabs>
          <NavLink
            onClick={() => {
              props.selectTab("1")
            }}
            style={{ cursor: "pointer" }}
            className={classNames({ active: props.activeTab === "1" })}
          >
            Ordens de serviço
          </NavLink>
          <NavLink
            onClick={() => {
              props.selectTab("2")
            }}
            style={{ cursor: "pointer" }}
            className={classNames({ active: props.activeTab === "2" })}
          >
            Parcelas
          </NavLink>
        </Nav>
      </Row>
      <Row hidden={props.activeTab === "2"} style={{ height: "5vh" }}>
        <Col className="mt-3" lg="2">
          <Row className="mb-1 mt-1" style={{ placeContent: "start" }}>
            <span
              style={{ ...headerSubtext }}
              onClick={() => props.authorizeAll()}
              id={"check-all"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-list-check"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </span>
            <UncontrolledTooltip delay={0} target={"check-all"}>
              Marcar tudo
            </UncontrolledTooltip>
            <span
              style={{
                ...headerSubtext,
              }}
              onClick={() => props.unAuthorizeAll()}
              id={"uncheck-all"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-list-task"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z"
                />
                <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                <path
                  fill-rule="evenodd"
                  d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z"
                />
              </svg>
            </span>
            <UncontrolledTooltip delay={0} target={"uncheck-all"}>
              Desmarcar tudo
            </UncontrolledTooltip>
          </Row>
        </Col>
      </Row>
      <Row hidden={props.activeTab === "1"} style={{ height: "5vh" }}></Row>
    </>
  )
}

export default BillingReleaseTableHeader
