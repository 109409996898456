import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardHeader } from "reactstrap"
import { transformNumberToCurency } from "utils/currencyHandling"
import { convertMongoDate } from "utils/dateHandling"
import { minutesToHours } from "utils/timeHandling"
import { hoursToMinutes } from "utils/timeHandling"

const imageHeader = require("assets/img/brand/marca-original.png").default

const tableHeader = {
  backgroundColor: "gray",
  color: "white",
  fontSize: "14px",
  border: "1px solid lightGray  ",
  paddingLeft: " 2.5px",
}
const tableCell = {
  color: "black",
  fontSize: "12px",
  border: "2px solid lightGray  ",
  paddingLeft: " 2.5px",
  textAlign: "center",
}
const border = {
  borderStyle: "solid",
  borderWidth: "1px",
  padding: "0px 5px 0px 5px",
}
const line = {
  borderStyle: "solid",
  borderWidth: "0.1px",
  height: "1px",
  width: "100%",
}
const blackTimes = {
  fontFamily: "Times New Roman",
  color: "black",
}
const whiteTimes = {
  fontFamily: "Times New Roman",
  color: "white",
}

//TODO: Make the PDF itself a component
function RDC(props) {
  const [projects, setProjects] = useState([])
  function getDueDate() {
    let bilingDate = new Date(props.billing.date)
    let dueDate = new Date()
    dueDate.setDate(
      bilingDate.getDate() + props.invoice.contract.dueDateDay + 1
    )

    return dueDate.toLocaleDateString("pt-BR")
  }
  function printDocument() {
    const input = document.getElementById("divToPrint")
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF()
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 297)
      // pdf.output('dataurlnewwindow');
      pdf.save(`RDC_${props.invoice.customer.shortName}.pdf`)
    })
  }
  function getInvoiceTime() {
    let total = 0
    projects.forEach((project) => {
      total += project.subtotal.hours
    })
    return minutesToHours(total)
  }
  /**
   * groups by project
   */
  useEffect(() => {
    if (props.invoice.serviceOrders?.length > 0) {
      const hourlyRate = props.invoice.contract.hourlyRate

      let projects = []
      props.invoice.serviceOrders.forEach((so) => {
        let index = projects.findIndex((prjtc) => prjtc._id === so.project._id)
        if (index === -1) {
          let project = {
            ...so.project,
            serviceOrders: [],
            subtotal: {
              hours: hoursToMinutes(so.timeTotal),
              hourlyRate: hourlyRate,
              total: hoursToMinutes(so.timeTotal) * 60 * hourlyRate,
            },
          }
          project.serviceOrders.push(so)
          projects.push(project)
        } else {
          projects[index].serviceOrders.push(so)
          projects[index].subtotal.hours += hoursToMinutes(so.timeTotal)
        }
      })

      setProjects(projects)
    }
  }, [props.invoice])
  return (
    <Card>
      <CardHeader>
        <Button
          hidden={props.printAll}
          type="button"
          onClick={(e) => {
            printDocument()
          }}
        >
          Baixar
        </Button>
      </CardHeader>
      <CardBody>
        <div
          id="divToPrint"
          style={{
            fontFamily: "Times New Roman",
            width: "210mm",
            height: "297mm",
          }}
        >
          {/* header */}
          <div style={{ margin: "auto" }}>
            <div
              style={{
                width: "100%",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              <img src={imageHeader} alt={""} style={{ maxWidth: "60%" }}></img>

              <h1 style={{ ...blackTimes }}>
                {" "}
                RDC - Relatório Detalhado De Cobrança
              </h1>
            </div>
            <div>
              <span style={{ ...blackTimes }}>
                {" "}
                Curitiba, {new Date().toLocaleDateString("pt-br")}
              </span>
            </div>
            <div style={{ ...line, marginBottom: "10px" }}></div>
            <div style={{ padding: "10px 0px 10px 5px" }}>
              <span style={{ ...blackTimes }}>
                Cliente: {props.invoice.customer.shortName}
              </span>
              <br></br>
              <span style={{ ...blackTimes }}>
                CNPJ: {props.invoice.customer.cnpj}
              </span>
              <br></br>

              <span style={{ ...blackTimes }}>
                Emissão NF: {convertMongoDate(props.billing.createdAt)}
              </span>
            </div>
            <div style={{ ...line, marginBottom: "30px" }}></div>

            {props.invoice.contractInstallments?.length > 0 && (
              <div style={{ marginBottom: "30px" }}>
                <div
                  style={{
                    ...border,
                    backgroundColor: "crimson",
                    borderColor: "crimson",
                  }}
                >
                  <h4 style={{ ...whiteTimes, margin: "auto" }}>
                    {" "}
                    PROJETOS FECHADOS
                  </h4>
                </div>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ ...tableHeader, width: "70%" }}>
                        Descriçao
                      </th>
                      <th style={{ ...tableHeader, width: "4%" }}>Parcela</th>
                      <th style={{ ...tableHeader, width: "13%" }}>
                        Vencimento
                      </th>
                      <th style={{ ...tableHeader, width: "13%" }}>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.invoice.contractInstallments.map((ca, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ ...tableCell, textAlign: "left" }}>
                            {ca.contract.name}
                          </td>
                          <td style={{ ...tableCell }}>
                            {ca.code}/{props.invoice.maxParcels}
                          </td>
                          <td style={{ ...tableCell }}>
                            {convertMongoDate(ca.dueDate)}
                          </td>
                          <td style={{ ...tableCell }}>
                            {transformNumberToCurency(ca.amount)}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div
                  style={{
                    ...tableHeader,
                    width: "40%",
                    borderTop: "none",
                  }}
                >
                  Total de Parcelas :{" "}
                  {transformNumberToCurency(props.invoice.installmentsTotal)}
                </div>
              </div>
            )}
            {props.invoice.serviceOrders?.length > 0 && (
              <div style={{ marginBottom: "30px" }}>
                <div
                  style={{
                    ...border,
                    backgroundColor: "crimson",
                    borderColor: "crimson",
                  }}
                >
                  <h4 style={{ ...whiteTimes, margin: "auto" }}>
                    {" "}
                    ORDENS DE SERVIÇO
                  </h4>
                </div>
                {projects.length > 0 &&
                  projects.map((project) => {
                    return (
                      <div>
                        <div
                          style={{
                            ...border,
                            backgroundColor: "dimGray",
                            borderColor: "dimGray",
                          }}
                        >
                          <h4 style={{ ...whiteTimes, margin: "auto" }}>
                            {" "}
                            Projeto : {project.code} - {project.name}
                          </h4>
                        </div>

                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th style={{ ...tableHeader, width: "40%" }}>
                                Consultor
                              </th>
                              <th style={{ ...tableHeader, width: "10%" }}>
                                Data
                              </th>
                              <th style={{ ...tableHeader, width: "10%" }}>
                                Inicio
                              </th>
                              <th style={{ ...tableHeader, width: "10%" }}>
                                Fim
                              </th>
                              <th style={{ ...tableHeader, width: "10%" }}>
                                Traslado
                              </th>
                              <th style={{ ...tableHeader, width: "10%" }}>
                                Desconto
                              </th>
                              <th style={{ ...tableHeader, width: "10%" }}>
                                Horas
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.serviceOrders.map((so, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      ...tableCell,
                                      textAlign: "left",
                                    }}
                                  >
                                    {so.consultant.fullName}
                                  </td>
                                  <td style={{ ...tableCell }}>
                                    {convertMongoDate(so.date)}
                                  </td>
                                  <td style={{ ...tableCell }}>
                                    {so.timeFrom}
                                  </td>
                                  <td style={{ ...tableCell }}>{so.timeTo}</td>
                                  <td style={{ ...tableCell }}>
                                    {so.timeCommute}
                                  </td>
                                  <td style={{ ...tableCell }}>
                                    {so.timeOthers}
                                  </td>
                                  <td style={{ ...tableCell }}>
                                    {so.timeTotal}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        <div style={{ display: "flex", marginBottom: "5px" }}>
                          <div
                            style={{
                              ...tableHeader,
                              backgroundColor: "dimgray",
                              width: "20%",
                              borderTop: "none",
                            }}
                          >
                            Subtotal Projeto{" "}
                          </div>
                          <div
                            style={{
                              ...tableHeader,
                              backgroundColor: "dimgray",
                              width: "20%",
                              borderTop: "none",
                            }}
                          >
                            Horas : {minutesToHours(project.subtotal.hours)}
                          </div>
                          <div
                            style={{
                              ...tableHeader,
                              backgroundColor: "dimgray",
                              width: "20%",
                              borderTop: "none",
                            }}
                          >
                            Valor hora :{" "}
                            {transformNumberToCurency(
                              project.subtotal.hourlyRate
                            )}
                          </div>
                          <div
                            style={{
                              ...tableHeader,
                              backgroundColor: "dimgray",
                              width: "20%",
                              borderTop: "none",
                            }}
                          >
                            Total :{" "}
                            {transformNumberToCurency(
                              (project.subtotal.hours / 60 + Number.EPSILON) *
                                project.subtotal.hourlyRate
                            )}
                          </div>
                          <div
                            style={{
                              ...tableHeader,
                              backgroundColor: "dimgray",
                              width: "20%",
                              borderTop: "none",
                            }}
                          >
                            Vencimento : {getDueDate()}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                <div
                  style={{
                    ...tableHeader,
                    width: "40%",
                    borderTop: "none",
                  }}
                >
                  Total Projetos :{" "}
                  {transformNumberToCurency(props.invoice.schedulesTotal)}
                </div>
              </div>
            )}
            <div style={{ display: "flex", marginBottom: "30px" }}>
              <div
                style={{
                  ...tableHeader,
                  backgroundColor: "dimgray",
                  width: "20%",
                  borderTop: "none",
                }}
              >
                <h4 style={{ color: "white" }}>Horas : {getInvoiceTime()}</h4>
              </div>
              <div
                style={{
                  ...tableHeader,
                  backgroundColor: "dimgray",
                  width: "20%",
                  borderTop: "none",
                }}
              >
                <h4 style={{ color: "white" }}>
                  {" "}
                  OS : {transformNumberToCurency(props.invoice.schedulesTotal)}
                </h4>
              </div>
              <div
                style={{
                  ...tableHeader,
                  backgroundColor: "dimgray",
                  width: "20%",
                  borderTop: "none",
                }}
              >
                <h4 style={{ color: "white" }}>
                  Parcelas :{" "}
                  {transformNumberToCurency(props.invoice.installmentsTotal)}
                </h4>
              </div>
              <div
                style={{
                  ...tableHeader,
                  backgroundColor: "dimgray",
                  width: "30%",
                  borderTop: "none",
                }}
              >
                <h4 style={{ color: "white" }}>
                  TOTAL :{" "}
                  {transformNumberToCurency(
                    parseFloat(props.invoice.schedulesTotal) +
                      parseFloat(props.invoice.installmentsTotal)
                  )}{" "}
                </h4>
              </div>
            </div>
            <div
              style={{
                ...border,
                backgroundColor: "crimson",
                borderColor: "crimson",
              }}
            >
              <h4 style={{ ...whiteTimes, margin: "auto" }}>
                {" "}
                DESCRIÇÃO NOTA FISCAL
              </h4>
            </div>
            <div
              style={{
                ...border,
              }}
            >
              <span style={{ ...blackTimes, whiteSpace: "pre-line" }}>
                {props.invoice.rdcText ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.invoice.rdcText.replace("\n", "<br/>"),
                    }}
                  />
                ) : (
                  <>
                    {" "}
                    PRESTAÇÃO DE SERVIÇO DE{" "}
                    {props.invoice.service.name.toUpperCase()}
                    <br></br>
                    {props.invoice.contractInstallments.map((ci) => {
                      return (
                        <>
                          - {ci.contract.name.toUpperCase()} parcela {ci.code}/
                          {props.invoice.maxParcels} - Valor:{" "}
                          {transformNumberToCurency(ci.amount)} - Vencimento:{" "}
                          {convertMongoDate(ci.dueDate)}
                          <br></br>
                        </>
                      )
                    })}
                    {projects.map((project) => {
                      return (
                        <>
                          -{project.name.toUpperCase()} - Valor:{" "}
                          {transformNumberToCurency(
                            (project.subtotal.hours / 60 + Number.EPSILON) *
                              project.subtotal.hourlyRate
                          )}{" "}
                          - Vencimento: {getDueDate()}
                          <br></br>
                        </>
                      )
                    })}
                    <br></br>
                    Valor Total:
                    {transformNumberToCurency(
                      parseFloat(props.invoice.schedulesTotal) +
                        parseFloat(props.invoice.installmentsTotal)
                    )}
                    <br></br>
                  </>
                )}
              </span>
              <>
                {props.invoice?.contract?.invoiceNotice
                  ? `OBS: ${props.invoice.contract.invoiceNotice}`
                  : ""}
              </>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default RDC
