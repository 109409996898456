import BillingForm from "components/Forms/BillingForm"
import React, { useCallback, useEffect, useState } from "react"

import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import api from "services/api"
import Loading from "../Loading"
import { hoursToMinutes } from "utils/timeHandling"
import { minutesToHours } from "utils/timeHandling"
import CreateBillingTables from "components/Tables/admin/CreateBillingTables"
import ConfirmationModal from "../ConfirmationModal"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { getMidnightThisDay } from "utils/dateHandling"

const emptyBilling = {
  date: new Date().toISOString().split("T")[0],
}

function CreateBilling(props) {
  const [billingBody, setBillingBody] = useState(emptyBilling)
  const [loading, setLoading] = useState(false)
  const [serviceOrders, setServiceOrders] = useState([])
  const [contractInstallments, setContractInstallments] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationModalAction, setConfirmationModalAction] = useState({})

  const [alert, setAlert] = useState(null)

  const warningAlert = (props) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle={props.color}
        confirmBtnText="Ok"
        btnSize=""
      >
        {props.message}
      </ReactBSAlert>
    )
  }

  /**
   * closes modal
   */
  function closeModal() {
    setBillingBody(emptyBilling)
    setServiceOrders([])
    setContractInstallments([])
    setActiveTab("1")
    props.closeModal()
  }

  /**
   * gets serviceOrder Data from database
   */
  const getServiceOrders = useCallback(async () => {
    try {
      const serviceOrdersResponse = await api.post("serviceOrder/search", {
        billed: false,
        authorized: true,
        date: { $lte: getMidnightThisDay(billingBody.date) },
      })

      setServiceOrders(serviceOrdersResponse.data)
    } catch (error) {
      setServiceOrders([])
    }
  }, [billingBody.date])

  /**
   * gets contracts Data from database
   */
  const getContractInstallments = useCallback(async () => {
    try {
      const contractInstallmentsResponse = await api.post(
        "contractInstallment/deepSearch",
        {
          authorized: true,
          status: "P",
          deleted: "N",
          docDate: {
            $lte: getMidnightThisDay(billingBody.date),
          },
        }
      )

      setContractInstallments(contractInstallmentsResponse.data)
    } catch (error) {
      setContractInstallments([])
    }
  }, [billingBody.date])

  /**
   * saves appointment
   */
  const save = async () => {
    setLoading(true)

    let hoursTotal = 0
    let total = 0
    let invoices = []
    const month = billingBody.date.split("-")[1]
    const year = billingBody.date.split("-")[0]

    serviceOrders.forEach((so) => {
      const serviceOrderMinutes = hoursToMinutes(so.timeTotal)
      let serviceOrderTotal = 0
      if (so.project.contract) {
        serviceOrderTotal = serviceOrderMinutes * so.project.contract.hourlyRate
        //adds hours and total to billing
        hoursTotal += serviceOrderMinutes
        total += serviceOrderTotal
      }

      //if invoice is not separate, grups serviceOrders by service
      const groupingIndex = invoices.findIndex(
        (invoice) =>
          invoice.service === so.project.contract?.service?._id &&
          invoice.customer === so.customer._id
      )

      // service is not yet on the list
      if (groupingIndex === -1) {
        const invoice = {
          customer: so.customer._id,
          service: so.project.contract?.service?._id,
          contract: so.project.contract?._id,
          hoursTotal: serviceOrderMinutes,
          schedulesTotal: serviceOrderTotal,
          installmentsTotal: 0,
          serviceOrders: [so._id],
          contractInstallments: [],
        }
        invoices.push(invoice)
      }
      //group was found in list
      else {
        invoices[groupingIndex].schedulesTotal += serviceOrderTotal
        invoices[groupingIndex].hoursTotal += serviceOrderMinutes
        invoices[groupingIndex].serviceOrders.push(so._id)
      }
    })

    contractInstallments.forEach((ca) => {
      const contractInstallmentMinutes = hoursToMinutes(ca.hours)
      const contractInstallmentsTotal = ca.amount

      //adds hours and total to billing
      hoursTotal += contractInstallmentMinutes
      //*60 since the service orders total are calculated in minutes
      total += contractInstallmentsTotal * 60

      let groupingIndex = -1

      //invoice is separate, so we have to check the contract and group by it also
      if (ca.contract.separateInvoice) {
        groupingIndex = invoices.findIndex(
          (invoice) =>
            invoice.service === ca.contract.service._id &&
            invoice.customer === ca.contract.customer._id &&
            invoice.contract === ca.contract._id
        )
      } else {
        //if invoice is not separate, grups serviceOrders by service
        groupingIndex = invoices.findIndex(
          (invoice) =>
            invoice.service === ca.contract.service._id &&
            invoice.customer === ca.contract.customer._id &&
            !invoice.separateInvoice
        )
      }
      // invoice is the first in the group
      if (groupingIndex === -1) {
        const invoice = {
          customer: ca.contract.customer._id,
          service: ca.contract.service._id,
          contract: ca.contract._id,
          separateInvoice: ca.contract.separateInvoice,
          hoursTotal: contractInstallmentMinutes,
          schedulesTotal: 0,
          installmentsTotal: contractInstallmentsTotal,
          contractInstallments: [ca._id],
        }
        invoices.push(invoice)
      }
      //group was found in list
      else {
        invoices[groupingIndex].installmentsTotal += contractInstallmentsTotal
        invoices[groupingIndex].hoursTotal += contractInstallmentMinutes
        invoices[groupingIndex].contractInstallments?.push(ca._id)
      }
    })

    //converts minutes to hours and calculates price total
    invoices.forEach((invoice) => {
      invoice.hoursTotal = minutesToHours(invoice.hoursTotal)

      invoice.schedulesTotal = invoice.schedulesTotal / 60
    })
    const body = {
      ...billingBody,
      year: year,
      month: month,
      hoursTotal: minutesToHours(hoursTotal),
      total: total / 60,
      invoices: invoices,
      serviceOrders: serviceOrders.map((so) => so._id),
      contractInstallments: contractInstallments.map((ci) => ci._id),
    }
    try {
      const billingResponse = await api.post(`billing/create`, {
        ...body,
      })
      await Promise.all(
        serviceOrders.map(async (so) => {
          return await api.patch("serviceOrder/patch/" + so._id, {
            billed: true,
            billing: billingResponse.data._id,
          })
        })
      )
      await Promise.all(
        contractInstallments.map(async (ca) => {
          return await api.patch("contractInstallment/patch/" + ca._id, {
            status: "I",
            billing: billingResponse.data._id,
            billingDate: billingResponse.data.date,
          })
        })
      )
      warningAlert({ color: "success", message: "O faturamento foi criado" })
      props.updateBillings()
      closeModal()
    } catch (error) {
      console.error(error)
      warningAlert({
        color: "danger",
        message: "Ocorreu um erro ao criar o faturamento",
      })

      closeModal()
    } finally {
      setLoading(false)
    }
  }

  const getData = useCallback(async () => {
    setLoading(true)
    await getContractInstallments()
    await getServiceOrders()
    setLoading(false)
  }, [getContractInstallments, getServiceOrders])

  useEffect(() => {
    if (billingBody.date && props.open) {
      getData()
    }
  }, [billingBody.date, getData, props.open])

  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={props.open}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">Criar Faturamento</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardBody>
                <BillingForm
                  billingBody={billingBody}
                  setBillingBody={(prop) =>
                    setBillingBody((prev) => ({ ...prev, ...prop }))
                  }
                />
                {loading && (
                  <center>
                    <Skeleton
                      count={5}
                      width="95%"
                      height={30}
                      borderRadius="5px"
                      inline
                    />
                  </center>
                )}
                {(contractInstallments.length > 0 ||
                  serviceOrders.length > 0) &&
                  !loading && (
                    <>
                      <CreateBillingTables
                        serviceOrders={serviceOrders}
                        contractInstallments={contractInstallments}
                        activeTab={activeTab}
                        setActiveTab={(tab) => setActiveTab(tab)}
                      />
                    </>
                  )}
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
      <div className="modal-footer">
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            closeModal()
          }}
        >
          Cancelar
        </Button>
        <Button
          color="success"
          type="button"
          disabled={
            serviceOrders.length === 0 && contractInstallments.length === 0
          }
          onClick={() => {
            setConfirmationModalOpen(true)
            setConfirmationModalAction({
              action: "create",
              text: `Criar Faturamento`,
            })
          }}
        >
          Salvar
        </Button>
      </div>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        action={confirmationModalAction}
        text={"Você tem certeza que deseja criar um faturamento?"}
        confirm={() => {
          if (confirmationModalAction.action === "create") {
            save()
            setConfirmationModalAction({})
          }
        }}
        closeModal={() => {
          setConfirmationModalOpen(false)
        }}
        deny={() => {
          setConfirmationModalOpen(false)
        }}
      />
    </Modal>
  )
}
export default CreateBilling
