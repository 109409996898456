import PendenciesModal from "components/Modals/PendenciesModal";
import SglTable from "components/ToolkitTable/SglTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import emailjs from "@emailjs/browser";
import { convertMongoDate } from "utils/dateHandling";
import { Col, Row } from "reactstrap";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import EmailRoutineModal from "components/Modals/admin/EmailRoutineModal";
import api from "services/api";

const headerStyle = `padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                    font-size: 0.65rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    border-bottom: 1px solid #e9ecef;
                    vertical-align: bottom;
                    border-top: 1px solid #e9ecef;
                    font-weight: 600;`;

const cellStyle = `font-size: 0.8125rem;
                    white-space: nowrap;
                    padding: 1rem;
                    vertical-align: top;
                    border-top: 1px solid #e9ecef;`;

const columns = [
  {
    dataField: "_id",
    text: "Id",
    hidden: true,
  },
  {
    dataField: "consultant.fullName",
    text: "Consultor",
    sort: true,
    hidden: false,
  },
  {
    dataField: "pendencies.schedulings.length",
    text: "Solicitações Pendentes",
    sort: true,
  },
  {
    dataField: "pendencies.pointings.length",
    text: "Apontamentos Pendentes",
    sort: true,
  },
  {
    dataField: "pendencies.total",
    text: "Pendências Total",
    sort: true,
  },
];

function AdminPendenciesTable(props) {
  const [selectedPendency, setSelectedPendency] = useState(null);
  const [isPendenciesModalOpen, setIsPendenciesModalOpen] = useState(false);
  const [isEmailRoutineModalOpen, setIsEmailRoutineModalOpen] = useState(false);

  const [confirmationModalAction, setConformationModalAction] = useState({
    text: "",
    blockConfirm: false,
  });
  const [confirmationModalText, setConformationModalText] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  /**
   *
   */
  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setConformationModalAction({ text: "", blockConfirm: false });
    setConformationModalText(``);
    props.closeConfirmationModal();
  };
  /**
   *
   */
  const openConfirmationModal = () => {
    setConformationModalAction({ text: "Envio de email", blockConfirm: false });
    setConformationModalText(`Enviar email para todos os consultores?`);
    setIsConfirmationModalOpen(true);
  };

  /**
   * closes pendencies modal
   */
  const closePendenciesModal = () => {
    setSelectedPendency(null);
    setIsPendenciesModalOpen(false);
  };
  /**
   * opens pendencies modal
   * @param {Object} row
   */
  const openPendenciesModal = (row) => {
    setSelectedPendency(row);
    setIsPendenciesModalOpen(true);
  };

  /**
   * closes pendencies modal
   */
  const closeEmailRoutineModal = () => {
    setIsEmailRoutineModalOpen(false);
    props.closeEmailRoutineModal();
  };
  /**
   * opens pendencies modal
   */
  const openEmailRoutineModal = (useCa) => {
    setIsEmailRoutineModalOpen(true);
  };

  /**
   * sends one email for specifir consultant
   * @param {Object} pendency
   * @returns
   */
  async function sendEmailToConsultant(pendency) {
    let table = `<table style="
      border-spacing:2px;
      width: 100%;
      margin-bottom: 1rem;
      color: #525f7f;
      background-color: transparent;
      text-indent: initial;
      border-spacing: 2px;
      border-color: grey;
      border-collapse: collapse;
    ">
      <thead style="
          display: table-header-group;
          vertical-align: middle;
          border-color: inherit;
          text-align: -webkit-match-parent;
      ">
          <tr>
              <th style="${headerStyle}">Data <br></br> Agenda </th>
              ${
                pendency?.pendencies?.schedulings?.length > 0
                  ? "<th>Apontado Em</th>"
                  : ""
              }
              <th style="${headerStyle}">Início</th>
              <th style="${headerStyle}">Fim</th>
              <th style="${headerStyle}">Total</th>
              <th style="${headerStyle}">Cliente</th>
              <th style="${headerStyle}">Projeto</th>
              </tr>
          </thead>
      <tbody style="
          display: table-row-group;
          vertical-align: middle;
          border-color: inherit;">`;
    pendency?.pendencies?.pointings?.forEach?.((pointing, index) => {
      table += ` <tr style:"
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
                text-indent: initial;
                border-spacing: 2px;">
                <td style="${cellStyle}">${convertMongoDate(pointing.date)}</td>
                ${
                  pendency?.pendencies?.schedulings?.length > 0
                    ? `<td style="${cellStyle}"></td>`
                    : ""
                }
                <td style="${cellStyle}">${pointing.timeFrom}</td>
                <td style="${cellStyle}">${pointing.timeTo}</td>
                <td style="${cellStyle}">${pointing.timeTotal}</td>
                <td style="${cellStyle}">${pointing.customer.fullName}</td>
                <td style="${cellStyle}">${pointing.project.name}</td>
              </tr>`;
    });
    pendency?.pendencies?.schedulings?.forEach?.((schedule, index) => {
      table += ` <tr style:"
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
                text-indent: initial;
                border-spacing: 2px;">
                <td style="${cellStyle}">${convertMongoDate(schedule.date)}</td>
                <td style="${cellStyle}">${convertMongoDate(
        schedule.createdAt
      )}</td>
              </tr>`;
    });

    table += `</tbody> </table>`;

    const emailRoutineResponse = await api.post("emailRoutine/search", {});
    const templateParams = {
      date: new Date().toLocaleDateString("pt-BR"),
      consultant_name: pendency.consultant.fullName,
      recipients: `${pendency.consultant.email},${emailRoutineResponse.data[0].recipients}`,
      appointments_table: table,
    };
    try {
      return await emailjs.send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_SERVICE_TEMPLATE,
        templateParams,
        process.env.REACT_APP_EMAIL_SERVICE_PUBLIC_KEY
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const sendOne = useCallback(async () => {
    try {
      await sendEmailToConsultant(selectedPendency);
      alert("Email Enviado");
    } catch (error) {
      console.error(error);
      alert("Cocorreu um erro ao enviar o email");
    }
  }, [selectedPendency]);

  /**
   * send email for all consultants
   */
  const sendAll = async () => {
    try {
      for (const pendency of formattedPendencies) {
        await sendEmailToConsultant(pendency);
      }
      alert("Todos os consultores foram informados das pendências!");
    } catch (error) {
      console.error(error);
      alert("Cocorreu um erro ao enviar os emails");
    }
  };

  /**
   * groups appointments by consultant
   */
  const formattedPendencies = useMemo(() => {
    const consultants = [
      ...new Set([
        ...props.pendingPointings.map((schedule) => schedule.consultant._id),
        ...Object.keys(props?.pendingSchedulings || {}),
      ]),
    ];

    return consultants.map((consultantId) => {
      const pointings = props.pendingPointings.filter(
        (pointing) => pointing.consultant._id === consultantId
      );

      const consultant =
        props?.pendingSchedulings?.[consultantId] || pointings?.[0]?.consultant;

      const totalAmount =
        (pointings?.length || 0) +
        (consultant?.pendingSchedulings?.length || 0);

      const pedenciesObject = {
        consultant: consultant,
        pendencies: {
          schedulings: consultant?.pendingSchedulings,
          pointings: pointings,
          total: totalAmount,
        },
      };

      return pedenciesObject;
    });
  }, [props.pendingPointings, props?.pendingSchedulings]);

  useEffect(() => {
    if (props.isConfirmationModalOpen) openConfirmationModal();
  }, [props.isConfirmationModalOpen]);

  useEffect(() => {
    if (props.isEmailRoutineModalOpen) openEmailRoutineModal();
  }, [props.isEmailRoutineModalOpen]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <SglTable
            columns={columns}
            data={formattedPendencies}
            rowClick={(row) => openPendenciesModal(row)}
          />
          <PendenciesModal
            isOpen={isPendenciesModalOpen}
            pendency={selectedPendency}
            closeModal={() => closePendenciesModal()}
            sendEmail={() => sendOne()}
            title={"Pendências"}
          />
        </Col>
      </Row>
      <EmailRoutineModal
        isOpen={isEmailRoutineModalOpen}
        closeModal={() => closeEmailRoutineModal()}
      />
      <ConfirmationModal
        action={confirmationModalAction}
        isOpen={isConfirmationModalOpen}
        text={confirmationModalText}
        confirm={() => sendAll()}
        closeModal={() => closeConfirmationModal()}
        deny={() => closeConfirmationModal()}
      />
    </>
  );
}
export default AdminPendenciesTable;
