import HollidayForm from "components/Forms/HollidayForm"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import api from "services/api"
import ConfirmationModal from "../ConfirmationModal"

const emptyAppointment = {
  activity: "",
  description: "",
  hours: "00:00",
  cost: 0.0,
  hourlyRate: 0.0,
}

function Holliday({
  isOpen,
  closeModal,
  holliday,
  refetchTable,
  warningAlert,
  setLoading,
}) {
  const [hollidayBody, setHollidayBody] = useState(emptyAppointment)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  /**
   * saves holliday
   */
  const updateHolliday = async () => {
    setLoading(true)
    setSaving(true)
    try {
      const checkDateResponse = await api.post("holliday/search", {
        date: hollidayBody.date,
        _id: { $ne: holliday._id },
      })

      if (checkDateResponse.data.length > 0) {
        warningAlert({
          color: "danger",
          message: "Já existe um feriado cadasrado nessa data",
        })

        return
      }

      await api.patch("holliday/patch/" + hollidayBody._id, hollidayBody)
      refetchTable()
      closeModal()
      warningAlert({
        color: "success",
        message: `Feriado de ${hollidayBody.reason} cadastrado`,
      })
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    } finally {
      setSaving(false)
      setLoading(false)
    }
  }
  /**
   *
   *
   */
  async function deleteHolliday() {
    setLoading(true)
    try {
      await api.delete("holliday/delete/" + hollidayBody._id)
      refetchTable()
      closeModal()
      warningAlert({
        color: "success",
        message: `Feriado de ${hollidayBody.reason} deletado`,
      })
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (holliday)
      setHollidayBody({
        ...holliday,
        date: holliday.date.split("T")[0],
      })
  }, [holliday])

  const formValid = useMemo(
    () => hollidayBody.date !== "" && hollidayBody.reason !== "",
    [hollidayBody]
  )

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isOpen}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">Editar Feriado</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => closeModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <HollidayForm
                    hollidayBody={hollidayBody}
                    setHollidayProp={(prop) =>
                      setHollidayBody((prev) => ({ ...prev, ...prop }))
                    }
                  ></HollidayForm>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
        <div className="modal-footer">
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={(e) => setConfirmationModalOpen(true)}
          >
            Deletar
          </Button>
          <Button
            color="success"
            type="button"
            disabled={(saving, !formValid)}
            onClick={(e) => updateHolliday()}
          >
            Salvar
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        text={"Você tem certeza que deseja excluir esse Feriado?"}
        confirm={() => deleteHolliday()}
        closeModal={() => {
          setConfirmationModalOpen(false)
        }}
        deny={() => {
          setConfirmationModalOpen(false)
        }}
      />
    </>
  )
}

Holliday.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
}
export default Holliday
