import OSs from "components/Pdf/OSs"
import RDC from "components/Pdf/RDC"
import React, { useEffect, useState } from "react"
import { useParams, useLocation } from "react-router"
import api from "services/api"

function InvoiceDocument() {
  const params = useParams()
  const search = useLocation().search
  const [docType, setDocType] = useState("")
  const [invoice, setInvoice] = useState({})
  const [billing, setBilling] = useState({})

  useEffect(() => {
    setDocType(new URLSearchParams(search).get("type"))
    async function getData() {
      let billingsResponse = await api.get("billing/read/" + params.billingId)
      const invoice =
        billingsResponse.data?.invoices[
          parseInt(new URLSearchParams(search).get("invoiceIndex"))
        ]
      let contractInstalmentResponse = await api.post(
        "contractInstallment/search",
        { contract: invoice.contract._id, deleted: "N" }
      )
      let maxParcels = Math.max(
        ...contractInstalmentResponse.data.map((ci) => ci.code)
      )

      setInvoice({ ...invoice, maxParcels: maxParcels })
      setBilling(billingsResponse.data)
    }

    if (params.billingId) {
      getData()
    }
  }, [params, search])

  return (
    <>
      <div
        style={{
          margin: "auto",
          width: "fit-content",
        }}
      >
        {docType === "os" && (
          <OSs invoice={invoice} serviceOrders={invoice.serviceOrders} />
        )}

        {docType === "rdc" && invoice._id && billing._id && (
          <>
            <RDC invoice={invoice} billing={billing}></RDC>
          </>
        )}
      </div>
    </>
  )
}

export default InvoiceDocument
