import { indexOf } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Select2 from "react-select2-wrapper";
import { Col, Form, Input } from "reactstrap";

export default function ConsultantClosingForm({
  closingBody,
  setClosingProp,
  consultantOptions,
}) {
  function transformToHours(time) {
    time = time.replaceAll(/[^0-9:-]/g, "");

    let minutes = parseInt(time.split(":")[1]);
    if (!minutes) time += ":00";
    if (minutes > 59) time = time.split(":")[0] + "00";
    if (minutes < 10) time = time.split(":")[0] + "0" + minutes;
    return time;
  }
  return (
    <>
      <Form className="needs-validation" noValidate autoComplete="off">
        <div className="form-row">
          {/* Date */}
          <Col className="mb-3" lg="6" md="12">
            <label className="form-control-label" htmlFor="date">
              Data
            </label>
            <Input
              onChange={(e) => {
                setClosingProp({ date: e.target.value });
              }}
              id="date"
              type="date"
              value={closingBody.date}
              valid={closingBody.date !== "" && closingBody.date !== undefined}
              invalid={
                closingBody.date === "" || closingBody.date === undefined
              }
            />
            <div className="invalid-feedback">Digite uma data</div>
          </Col>
          <Col className="mb-3" md="12" lg="6">
            <label className="form-control-label" htmlFor="reason">
              Consultor
            </label>
            <Select2
              id="consultant"
              type="text"
              value={closingBody.consultant}
              onChange={(e) => setClosingProp({ consultant: e.target.value })}
              // onSelect={(e) => props.setConsultant(e.target.value)}
              valid={closingBody.consultant !== ""}
              invalid={closingBody.consultant === ""}
              options={{
                hidden: true,
                placeholder: "Selecione um consultor",
                language: {
                  noResults: function () {
                    return "Nenhum consultor encontrado.";
                  },
                },
              }}
              data={consultantOptions.map((consultant) => {
                return {
                  id: consultant._id,
                  text: consultant.fullName,
                };
              })}
            ></Select2>
            <div className="invalid-feedback">Selecione um consultor</div>
          </Col>
          <Col className="mb-3" xs="12" md="6">
            <label className="form-control-label" htmlFor="hours">
              Saldo
            </label>
            <Input
              aria-describedby="inputGroupPrepend"
              id="hours"
              type="tel"
              pattern="-?[0-9]+"
              value={closingBody.hours}
              onChange={(e) => {
                setClosingProp({
                  hours: e.target.value.replace(/[^0-9:-]/, ""),
                });
              }}
            />
          </Col>
        </div>
      </Form>
      {alert}
    </>
  );
}

ConsultantClosingForm.propTypes = {
  closingBody: PropTypes.shape({
    date: PropTypes.string,
    consultant: PropTypes.string,
    hours: PropTypes.any,
  }),
  setClosingProp: PropTypes.func,
};
