import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Col, Form, Input } from "reactstrap"
import ReactBSAlert from "react-bootstrap-sweetalert"
import { hoursToMinutes } from "utils/timeHandling"

export default function HollidayForm({ hollidayBody, setHollidayProp }) {
  const [alert, setAlert] = useState(null)
  const hideAlert = () => {
    setAlert(null)
  }
  const warningAlert = useCallback((props) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle={props.color}
        confirmBtnText="Ok"
        btnSize=""
      >
        {props.message}
      </ReactBSAlert>
    )
  }, [])

  return (
    <>
      <Form className="needs-validation" noValidate autoComplete="off">
        <div className="form-row">
          {/* Date */}
          <Col className="mb-3" lg="6" md="12">
            <label className="form-control-label" htmlFor="date">
              Data
            </label>
            <Input
              onChange={(e) => {
                setHollidayProp({ date: e.target.value })
              }}
              id="date"
              type="date"
              value={hollidayBody.date}
              valid={
                hollidayBody.date !== "" && hollidayBody.date !== undefined
              }
              invalid={
                hollidayBody.date === "" || hollidayBody.date === undefined
              }
            />
            <div className="invalid-feedback">Digite uma data</div>
          </Col>
          <Col className="mb-3" md="12">
            <label className="form-control-label" htmlFor="reason">
              Motivo
            </label>
            <Input
              aria-describedby="inputGroupPrepend"
              id="description"
              type="textarea"
              value={hollidayBody.reason}
              valid={
                hollidayBody.reason !== "" && hollidayBody.reason !== undefined
              }
              invalid={
                hollidayBody.reason === "" || hollidayBody.reason === undefined
              }
              onChange={(e) => setHollidayProp({ reason: e.target.value })}
            />
            <div className="invalid-feedback">Digite um Motivo</div>
          </Col>
          <Col className="mb-3" xs="12" md="6">
            <label className="form-control-label" htmlFor="hours">
              Horas
            </label>
            <Input
              aria-describedby="inputGroupPrepend"
              id="hours"
              type="time"
              value={hollidayBody.hours}
              onChange={(e) => {
                let time = hoursToMinutes(e.target.value)
                if (time > 480)
                  warningAlert({
                    color: "warning",
                    message:
                      " Cuidado! você colocou mais de 8 horas (1 agenda) para o feriado, verifique se o valor está correto",
                  })
                setHollidayProp({ hours: e.target.value })
              }}
            />
          </Col>
        </div>
      </Form>
      {alert}
    </>
  )
}

HollidayForm.propTypes = {
  hollidayBody: PropTypes.shape({
    date: PropTypes.string,
    hours: PropTypes.any,
    reason: PropTypes.string,
  }),
  setHollidayProp: PropTypes.func,
}
