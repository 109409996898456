import "moment/locale/pt-br"
import { useState } from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { Button, Card, CardBody, Col, Input, Modal, Row } from "reactstrap"
import apiSap from "services/apiSap"

export default function SapLoginModal(props) {
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")

  //const [alert, setAlert] = useState(null)
  const login = async () => {
    setLoading(true)
    try {
      await apiSap.post("/Login", {
        //CompanyDB: "SBODEMOBR2",
        CompanyDB: "SBO_GOLIVE_PRD",
        UserName: user,
        Password: password,
      })
      props.closeModal()
      props.openConfirmationModal()
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm" //TAMANHO TELA LOGIN
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
          disabled={saving}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <Card>
        <CardBody>
          <Row>
            {" "}
            <Col xs={12}>
              {" "}
              <label className="form-control-label" htmlFor="grid-password">
                Usuário
              </label>
              <Input
                type="email"
                placeholder="Usuário"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                disabled={loading}
              />{" "}
            </Col>
          </Row>

          <Row className="mt-2">
            {" "}
            <Col xs={12}>
              <label className="form-control-label" htmlFor="grid-password">
                Senha
              </label>
              <Input
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="modal-footer">
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          id="deleteSchedule"
          onClick={() => {
            props.closeModal()
          }}
        >
          Não
        </Button>
        <Button color="info" type="button" onClick={(e) => login()}>
          SIM
          {props.action}
        </Button>
      </div>
    </Modal>
  )
}
