import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { pagination } from "utils/paginationFactory";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
const { SearchBar } = Search;

class SglTable extends React.Component {
  render() {
    return (
      <ToolkitProvider
        data={this.props.data}
        keyField="_id"
        columns={this.props.columns}
        search
      >
        {(prop) => (
          <div className="py-4 table-responsive">
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter px-4 pb-1 float-left"
                  >
                    <label>
                      Pesquisar:
                      <SearchBar
                        className="form-control-sm"
                        placeholder="Pesquisar por..."
                        {...prop.searchProps}
                      />
                    </label>
                  </div>
                </Col>
              </Row>
            </Container>
            <BootstrapTable
              ref={(el) => (this.componentRef = el)}
              {...prop.baseProps}
              classes={this.props.className}
              bootstrap4={true}
              pagination={this.props.removePagination ? null : pagination}
              bordered={false}
              rowStyle={
                this.props.rowStyle
                  ? this.props.rowStyle
                  : { cursor: "pointer" }
              }
              rowEvents={{
                onClick: (e, row) => {
                  this.props.rowClick(row);
                },
              }}
              hover
              id="react-bs-table"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}
SglTable.propTypes = {
  data: PropTypes.array.isRequired,
  rowStyle: PropTypes.object,
  columns: PropTypes.array.isRequired,
  rowClick: PropTypes.func,
  cellClick: PropTypes.func,
  className: PropTypes.string,
};
export default SglTable;
