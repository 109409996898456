import React, { useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"
import Select2 from "react-select2-wrapper"

function MobileSchedulesTableHeader(props) {
  const [consultant, setConsultant] = useState("")

  return (
    <Row>
      <Col className="" xs="12">
        <label className="form-control-label" htmlFor="consultant">
          Consultor
        </label>
        <Select2
          id="consultant"
          type="text"
          value={consultant}
          onSelect={(e) => {
            setConsultant(e.target.value)
            props.setMobileConsultant(e.target.value)
          }}
          options={{
            hidden: true,
            placeholder: "Selecione um consultor",
            language: {
              noResults: function () {
                return "Nenhum consultor encontrado."
              },
            },
          }}
          data={props.consultants.map((consultant) => {
            return {
              id: consultant._id,
              text: consultant.fullName,
            }
          })}
        ></Select2>
      </Col>
      <Col xs={"12"} className="pt-3">
        <label className="form-control-label" htmlFor="from">
          De
        </label>
        <Input
          id="from"
          type="date"
          value={props.from}
          onChange={(e) => {
            props.setFrom(e.target.value)
          }}
        />
      </Col>
      <Col xs={"12"} className="pt-3">
        <label className="form-control-label" htmlFor="to">
          até
        </label>
        <Input
          id="to"
          type="date"
          value={props.to}
          onChange={(e) => {
            props.setTo(e.target.value)
          }}
        />
      </Col>

      <Col className="text-right pt-3" xs="12">
        <Button
          className="btn-round btn-icon"
          color="primary"
          onClick={(e) => props.handleCreateSchedulesModal(true)}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-plus" />
          </span>
          <span className="btn-inner--text">Nova Agenda</span>
        </Button>
      </Col>
    </Row>
  )
}

export default MobileSchedulesTableHeader
