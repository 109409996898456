import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import api from "services/api"
import { convertMongoDateToDateInput } from "utils/dateHandling"
import ConfirmationModal from "../ConfirmationModal"
import ConsultantClosingForm from "components/Forms/ConsultantClosingForm"

const emptyClosing = {
  date: "",
  consultant: "",
  hours: "0:00",
}

function ConsultantClosing({
  isOpen,
  closeModal,
  closing,
  refetchTable,
  warningAlert,
  setLoading,
}) {
  const [closingBody, setClosingBody] = useState(emptyClosing)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [consultantOptions, setConsultantOptions] = useState([])

  const fetchConsultants = useCallback(async () => {
    try {
      const consultantsResponse = await api.post("user/search", { class: "CG" })
      setConsultantOptions(consultantsResponse.data)
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    }
  }, [setConsultantOptions, warningAlert])

  /**
   * saves closing
   */
  const updateClosing = async () => {
    setLoading(true)
    setSaving(true)
    try {
      const checkDateResponse = await api.post("consultantClosing/search", {
        search: {
          date: closingBody.date,
          consultant: closingBody.consultant._id || closingBody.consultant,
          _id: { $ne: closing._id },
        },
      })
      let regex = new RegExp(/^-?(\d+):([0-5]\d)$/)
      if (!regex.test(closingBody.hours)) {
        warningAlert({
          color: "danger",
          message: "Horas não está no formato HH:mm ",
        })

        return
      }

      if (checkDateResponse.data.length > 0) {
        warningAlert({
          color: "danger",
          message:
            "Já existe um Fechamento para esse consultor cadastrado nessa data",
        })

        return
      }

      await api.patch("consultantClosing/patch/" + closingBody._id, closingBody)
      refetchTable()
      closeModal()
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    } finally {
      setSaving(false)
      setLoading(false)
    }
  }
  /**
   *
   *
   */
  async function deleteClosing() {
    setLoading(true)
    try {
      await api.delete("consultantClosing/delete/" + closingBody._id)
      refetchTable()
      closeModal()
      warningAlert({
        color: "success",
        message: `Fechamento deletado`,
      })
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (closing)
      setClosingBody({
        ...closing,
        date: closing.date.split("T")[0],
      })
  }, [closing])

  const formValid = useMemo(
    () => closingBody.date !== "" && closingBody.reason !== "",
    [closingBody]
  )
  useEffect(() => {
    fetchConsultants()
  }, [fetchConsultants])

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isOpen}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">Editar Fechamento de consultor</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => closeModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <ConsultantClosingForm
                    closingBody={closingBody}
                    consultantOptions={consultantOptions}
                    setClosingProp={(prop) =>
                      setClosingBody((prev) => ({ ...prev, ...prop }))
                    }
                  ></ConsultantClosingForm>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
        <div className="modal-footer">
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={(e) => setConfirmationModalOpen(true)}
          >
            Deletar
          </Button>
          <Button
            color="success"
            type="button"
            disabled={(saving, !formValid)}
            onClick={(e) => updateClosing()}
          >
            Salvar
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        text={"Você tem certeza que deseja excluir esse Feriado?"}
        confirm={() => {
          setConfirmationModalOpen(false)
          deleteClosing()
        }}
        closeModal={() => {
          setConfirmationModalOpen(false)
        }}
        deny={() => {
          setConfirmationModalOpen(false)
        }}
      />
    </>
  )
}

ConsultantClosing.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
}
export default ConsultantClosing
