import api from "services/api"
import LightHeader from "components/Headers/LightHeader"
import Loading from "components/Modals/Loading"
import React, { useState, useEffect, useCallback } from "react"
import ReactBSAlert from "react-bootstrap-sweetalert"

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap"
import ConsultantClosingsTable from "components/Tables/admin/ConsultantClosingsTable"
import CreateConsultantClosing from "components/Modals/create/CreateConsultantClosing"
import ConsultantClosing from "components/Modals/show-edit/ConsultantClosing"
/*
  TODO:
  fix the get all user method to get schedules with all inner data
*/
export default function ConsultantClosings() {
  //component
  const [isLoading, setIsLoading] = useState(false)
  const [alert, setAlert] = useState(null)

  const [isCreateClosingOpen, setIsCreateClosingOpen] = useState(false)

  const [isEditClosingOpen, setIsEditClosingOpen] = useState(false)
  const [selectedClosing, setSelectedClosing] = useState({})

  const [closings, setClosings] = useState([])

  const warningAlert = useCallback((props) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle={props.color}
        confirmBtnText="Ok"
        btnSize=""
      >
        {props.message}
      </ReactBSAlert>
    )
  }, [])

  const fetchClosings = useCallback(async () => {
    setIsLoading(true)
    try {
      const closingsResponse = await api.get("consultantClosing/all")
      setClosings(closingsResponse.data)
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.error + "\n" + error.messsage,
      })
    } finally {
      setIsLoading(false)
    }
  }, [warningAlert])

  const hideAlert = () => {
    setAlert(null)
  }

  useEffect(() => {
    fetchClosings()
  }, [fetchClosings])
  return (
    <>
      <LightHeader
        title="Fechamento Banco de Horas"
        parent="Fechamentos"
        menuTitle="Fechamento Banco de Horas"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Fechamento Banco de Horas</h3>
                  </Col>
                  <Col xs="6" style={{ textAlignLast: "end" }}>
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      onClick={(e) => setIsCreateClosingOpen(true)}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Novo Fechamento</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody
                style={{
                  padding: "0px 0px 0px 0px",
                  height: "auto",
                }}
              >
                <ConsultantClosingsTable
                  closings={closings}
                  isLoading={isLoading}
                  rowClick={(row) => {
                    setSelectedClosing({
                      ...row,
                      consultant: row.consultant._id,
                    })
                    setIsEditClosingOpen(true)
                  }}
                ></ConsultantClosingsTable>
              </CardBody>
            </Card>
          </div>
        </Row>
        <CreateConsultantClosing
          isOpen={isCreateClosingOpen}
          closeModal={() => setIsCreateClosingOpen(false)}
          setLoading={(value) => setIsLoading(value)}
          refetchTable={() => fetchClosings()}
          warningAlert={(props) => warningAlert(props)}
        />
        {selectedClosing._id ? (
          <ConsultantClosing
            isOpen={isEditClosingOpen}
            setLoading={(value) => setIsLoading(value)}
            closeModal={() => {
              setIsEditClosingOpen(false)
              setSelectedClosing({})
            }}
            closing={selectedClosing}
            refetchTable={() => fetchClosings()}
            warningAlert={(props) => warningAlert(props)}
          />
        ) : (
          <></>
        )}

        <Loading modalOpened={isLoading} />
      </Container>
      {alert}
    </>
  )
}
