import SglTable from "components/ToolkitTable/SglTable"
import React, { useMemo } from "react"
import { Row, Table } from "reactstrap"
import { transformNumberToCurency } from "utils/currencyHandling"
import { convertMongoDate } from "utils/dateHandling"

const columns = [
  {
    dataField: "_id",
    text: "Id",
    hidden: true,
  },
  {
    dataField: "action",
    text: "Autorizado",
    hidden: false,
  },
  {
    dataField: "name",
    text: "Projeto",
    sort: true,
    hidden: false,
  },
  {
    dataField: "serviceName",
    text: "Serviço",
    sort: true,
  },
  {
    dataField: "customer",
    text: "Cliente",
    sort: true,
  },
  {
    dataField: "code",
    text: "Parcela",
    sort: true,
  },
  {
    dataField: "docDate",
    text: "Emissão",
    sort: true,
  },
  {
    dataField: "hours",
    text: "Horas",
    sort: true,
  },
  {
    dataField: "amount",
    text: "Valor",
    sort: true,
  },
  {
    dataField: "dueDate",
    text: "Vencimento",
    sort: true,
  },
  {
    dataField: "criteria",
    text: "Critérios",
    sort: true,
  },
]

function ContractInstallmentsReleaseTable(props) {
  const installments = useMemo(() => {
    return props.contractInstallments.map((installment, index) => {
      return {
        action: (
          <label className="custom-toggle custom-toggle-default">
            <input
              checked={installment.authorized}
              type="checkbox"
              id="billing"
              value={installment.authorized}
              onChange={() => {
                props.updateAuthorizedServiceOrders(index)
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="Não"
              data-label-on="Sim"
            />
          </label>
        ),
        name: installment.contract.name,
        serviceName: installment.contract.service.name,
        customer: installment.contract.customer.shortName,
        code: installment.code,
        docDate: convertMongoDate(installment.docDate),
        hours: installment.hours,
        amount: transformNumberToCurency(installment.amount),
        dueDate: convertMongoDate(installment.dueDate),
        criteria: installment.criteria,
      }
    })
  }, [props])

  if (!props.contractInstallments) {
    return <></>
  }
  if (props.contractInstallments.length > 0) {
    return (
      <SglTable columns={columns} data={installments} rowClick={() => {}} />
    )
  } else {
    return (
      <>
        <Row className="pl-4 mt-2">
          <h3> Não há parcelas, verifique os filtros</h3>
        </Row>
      </>
    )
  }
}
export default ContractInstallmentsReleaseTable
