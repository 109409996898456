/*!

=========================================================
* Argon Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom"
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js"
import AdminFooter from "components/Footers/AdminFooter.js"
import Sidebar from "components/Sidebar/Sidebar.js"

import { isTokenValid, userClass } from "services/auth"
import Users from "views/pages/browsers/Users"
import Services from "views/pages/browsers/Services"
import Clients from "views/pages/browsers/Clients"
import Contracts from "views/pages/browsers/Contracts"
import Projects from "views/pages/browsers/Projects"
import Profile from "views/pages/access/Profile"
import { logout } from "services/auth"
import ServiceOrders from "views/pages/browsers/user/ServiceOrders"
import { userMenu } from "services/auth"
import Schedules from "views/pages/browsers/admin/Schedules"
import BillingsRelease from "views/pages/browsers/admin/BillingsRelease"
import Billings from "views/pages/browsers/admin/Billings"
import Pendencies from "views/pages/browsers/Pendencies"
import UserNavbar from "components/Navbars/UserNavbar"
import ReactBSAlert from "react-bootstrap-sweetalert"

import ProjectsReports from "views/pages/browsers/admin/ProjectsReports"
import { getFirstDayOfNextMonth } from "utils/dateHandling"
import api from "services/api"
import InvoicesAnalysis from "views/pages/browsers/admin/InvoicesAnalysis"
import HoursAnalysis from "views/pages/browsers/admin/HoursAnalysis"
import ProjectIndicator from "views/pages/browsers/admin/ProjectIndicator"
import ServiceDesk from "views/pages/browsers/admin/ServiceDesk"

const registrations = [
  {
    path: "/users",
    name: "Usuários",
    miniName: "U",
    component: Users,
    layout: "/user",
    key: "users",
    sideBar: true,
  },
  {
    path: "/services",
    name: "Serviços",
    miniName: "S",
    component: Services,
    layout: "/user",
    key: "services",
    sideBar: true,
  },
  {
    path: "/clients",
    name: "Clientes",
    miniName: "C",
    component: Clients,
    layout: "/user",
    key: "clients",
    sideBar: true,
  },
  {
    path: "/contracts",
    name: "Contratos",
    miniName: "T",
    component: Contracts,
    layout: "/user",
    key: "contracts",
    sideBar: true,
  },
  {
    path: "/projects",
    name: "Projetos",
    miniName: "P",
    component: Projects,
    layout: "/user",
    key: "projects",
    sideBar: true,
  },
  {
    path: "/schedules",
    name: "Agendas",
    miniName: "A",
    component: Schedules,
    layout: "/user",
    key: "schedules",
    sideBar: true,
  },
  {
    path: "/service-orders",
    name: "Ordens de Serviço",
    miniName: "OS",
    component: ServiceOrders,
    layout: "/user",
    key: "serviceOrders",
    sideBar: true,
  },
]

const finantial = [
  {
    path: "/billings-release",
    name: "Liberação de Faturamentos",
    miniName: "LF",
    component: BillingsRelease,
    layout: "/user",
    key: "billingsRelease",
    sideBar: true,
  },
  {
    path: "/billings",
    name: "Faturamentos",
    miniName: "Fa",
    component: Billings,
    layout: "/user",
    key: "billings",
    sideBar: true,
  },
]
const reports = [
  {
    path: "/appointments",
    name: "Pendências",
    miniName: "Ap",
    component: Pendencies,
    layout: "/user",
    key: "appointments",
    sideBar: true,
  },
  {
    path: "/invoicesAnalysis",
    name: "Análise de Faturamento",
    miniName: "AF",
    component: InvoicesAnalysis,
    layout: "/user",
    key: "invoicesAnalysis",
    sideBar: true,
  },
  {
    path: "/hoursAnalysis",
    name: "Análise de Horas",
    miniName: "AH",
    component: HoursAnalysis,
    layout: "/user",
    key: "hoursAnalysis",
    sideBar: true,
  },
  {
    path: "/projectIndicator",
    name: "Indicador de Projetos",
    miniName: "IP",
    component: ProjectIndicator,
    layout: "/user",
    key: "projectIndicator",
    sideBar: true,
  },
  {
    path: "/due-contracts",
    name: "Vencimento de Contratos",
    miniName: "VC",
    component: Contracts,
    layout: "/user",
    key: "dueContracts",
    sideBar: true,
  },
  {
    path: "/projects-reports",
    name: "Projeto Previsto x Realizado",
    miniName: "RP",
    component: ProjectsReports,
    layout: "/user",
    key: "projectsReport",
    sideBar: true,
  },
  {
    path: "/service-desk",
    name: "Análise Service Desk",
    miniName: "RP",
    component: ServiceDesk,
    layout: "/user",
    key: "serviceDesk",
    sideBar: true,
  },
]

class User extends React.Component {
  state = {
    sidenavOpen: true,
    routes: [],
    toPortal: "auth/login",
    userClass: "",
    alert: null,
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          info
          title="Atenção!"
          showCancel
          onConfirm={() => {
            this.hideAlert()
            this.setState({ toPortal: "/user/due-contracts" })
          }}
          onCancel={() => {
            this.hideAlert()
            this.setState({ toPortal: "/user/consultant" })
          }}
          cancelBtnBsStyle="warning"
          confirmBtnBsStyle="success"
          confirmBtnText="Ver contratos"
          cancelBtnText="Deixar pra depois"
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }
  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainContent.scrollTop = 0
    }
  }

  componentDidMount() {
    this.loadRoutes()
    isTokenValid().then(async (result) => {
      let response = null
      if (userMenu().dueContracts) {
        response = await api.post("/contract/search", {
          deleted: "N",
          newDueDate: {
            $lt: getFirstDayOfNextMonth(),
          },
        })
      }

      if (response?.data?.length > 0) {
        this.warningAlert(
          `Você tem ${response.data.length} contratos a vencer até o final desse mês`
        )
      } else if (!result) {
        logout()
        this.setState({ toPortal: "/auth/login" })
      } else {
        this.setState({ toPortal: "/user/consultant" })
      }
    })
  }

  loadRoutes = async () => {
    let routes = []
    let validRegistrations = registrations.filter((prop) => {
      return userMenu()[prop.key]
    })
    let validFinantial = finantial.filter((prop) => {
      return userMenu()[prop.key]
    })
    let validReports = reports.filter((prop) => {
      return userMenu()[prop.key]
    })

    if (validRegistrations.length > 0) {
      routes.push({
        collapse: true,
        name: "Cadastros",
        icon: "ni ni-single-copy-04 text-info",
        state: "registrationsCollapse",
        key: "registrations",
        sideBar: true,
        views: validRegistrations,
      })
    }
    if (validFinantial.length > 0) {
      routes.push({
        collapse: true,
        name: "Fechamentos",
        icon: "fa fa-light fa-wallet text-info",
        state: "closingsCollapse",
        key: "closings",
        sideBar: true,
        views: validFinantial,
      })
    }
    if (validReports.length > 0) {
      routes.push({
        collapse: true,
        name: "Relatórios",
        icon: "fa fa-light fa-flag text-info",
        state: "reportsCollapse",
        key: "reports",
        sideBar: true,
        views: validReports,
      })
    }
    routes.push({
      path: "/profile",
      name: "Perfil",
      key: "profile",
      miniName: "P",
      component: Profile,
      layout: "/user",
      sideBar: false,
    })

    this.setState({ routes })
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === "/user") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  getBrandText = (path) => {
    for (let i = 0; i < this.state.routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          this.state.routes[i].layout + this.state.routes[i].path
        ) !== -1
      ) {
        return this.state.routes[i].name
      }
    }
    return "Brand"
  }

  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned")
      document.body.classList.add("g-sidenav-hidden")
    } else {
      document.body.classList.add("g-sidenav-pinned")
      document.body.classList.remove("g-sidenav-hidden")
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    })
  }

  getNavbarTheme = () => {
    return "light"
    // return this.props.location.pathname.indexOf(
    //   "admin/alternative-dashboard"
    // ) === -1
    //   ? "dark"
    //   : "light";
  }

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={this.state.routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/golive.png").default,
            imgAlt: "...",
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <UserNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(this.state.routes)}
            <Redirect from="*" to={this.state.toPortal} />
          </Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
        {this.state.alert}
      </>
    )
  }
}

export default User
