import ServiceOrderForm from "components/Forms/ServiceOrderForm"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardHeader, Modal, Row } from "reactstrap"
import api from "services/api"
import { userId } from "services/auth"
import { minutesToHours } from "utils/timeHandling"
import { hoursToMinutes } from "utils/timeHandling"

const emptyServiceOrder = {
  schedule: "",
  activities: "",
  hours: "00:00",
}
function CreateServiceOrder(props) {
  const [serviceOrderBody, setServiceOrderBody] = useState(emptyServiceOrder)
  const [customer, setCustomer] = useState("")

  const [saving, setSaving] = useState(false)
  const [sanitizedActivities, setSanitizedActivities] = useState([])

  useEffect(() => {
    async function getCustomer() {
      const customerResponse = await api.get(
        "customer/read/" + props.schedule.customer
      )
      setCustomer(customerResponse.data.shortName)
    }
    if (props.schedule._id) {
      const serviceOrder = {
        schedule: props.schedule._id,
        project: props.schedule.project,
        customer: props.schedule.customer,
        consultant: userId(),
        date: props.schedule.date,
        timeTo: props.schedule.timeTo,
        timeFrom: props.schedule.timeFrom,
        timeOthers: props.schedule.timeOthers,
        timeCommute: props.schedule.timeCommute,
        timeTotal: props.schedule.timeTotal,
      }
      getCustomer()
      setServiceOrderBody((prev) => ({ ...prev, ...serviceOrder }))
    }
  }, [props.schedule])

  /**
   * hook to generate service order description and get total serviceOrdder time
   */
  useEffect(() => {
    if (sanitizedActivities.length > 0) {
      let totalServiceOrderTime = 0
      const activities = sanitizedActivities.map((activity) => {
        let totalTime = 0
        activity.appointments.forEach(async (appointment) => {
          if (appointment.consultant === userId())
            totalTime += hoursToMinutes(appointment.hours)
        })
        totalServiceOrderTime += totalTime
        return { ...activity, activityTime: minutesToHours(totalTime) }
      })

      let description = `Projeto: ${activities[0].project[0].code} ${activities[0].project[0].name}\n\n`
      for (const activity of activities) {
        if (activity.activityTime === "00:00") continue
        description += `Atividade : ${activity.process} (${activity.activityTime} horas)\n`
        for (const appointment of activity.appointments) {
          description += `${appointment.description}\n`
        }

        description += "\n"
      }

      const serviceOrder = {
        description: description,
      }
      setServiceOrderBody((prev) => ({
        ...prev,
        totalMinutes: totalServiceOrderTime,
        ...serviceOrder,
      }))
    }
  }, [sanitizedActivities])
  /**
   * Hook to calculate final time
   */
  useEffect(() => {
    let timeArray = [
      serviceOrderBody.timeTo,
      serviceOrderBody.timeFrom,
      serviceOrderBody.timeCommute,
      serviceOrderBody.timeOthers,
    ]
    if (
      timeArray.every((value) => {
        return value !== undefined
      })
    ) {
      let minutes = 0

      timeArray.forEach((time, index) => {
        if (index % 2 === 0) {
          minutes += parseInt(hoursToMinutes(time))
        } else {
          minutes -= parseInt(hoursToMinutes(time))
        }
      })
      setServiceOrderBody((prev) => ({
        ...prev,
        timeTotal: minutesToHours(minutes),
      }))
    }
  }, [
    serviceOrderBody.timeCommute,
    serviceOrderBody.timeFrom,
    serviceOrderBody.timeOthers,
    serviceOrderBody.timeTo,
    serviceOrderBody.totalMinutes,
  ])

  /**
   * hook to filter date activities and removes others dates appointments to store
   */
  useEffect(() => {
    if (props.activities && props.schedule) {
      let activities = []
      props.activities.forEach((activity) => {
        let appointments = activity.appointments.filter((appointment) => {
          return appointment.date.split("T")[0] === props.schedule.date
        })
        if (appointments.length > 0) {
          activities.push({
            ...activity,
            appointments: appointments,
          })
        }
      })
      setSanitizedActivities(activities)
      props.canMakeOs(activities.length > 0)
    }
  }, [props, props.activities, props.schedule])

  /**
   * saves ServiceOrder, adds performed hours to activity saves serviceOrderGenerated on appointments
   */
  const save = async () => {
    setSaving(true)

    //checks if actitivies available time is lesses than appointments total time
    let activitiesToFix = []
    sanitizedActivities.forEach((activity) => {
      let totalTime = 0
      activity.appointments.forEach(async (appointment) => {
        if (appointment.consultant === userId())
          totalTime += hoursToMinutes(appointment.hours)
      })

      let performedHours = hoursToMinutes(activity.performedHours)
      let estimatedHours = hoursToMinutes(activity.estimatedHours)

      if (
        performedHours + totalTime > estimatedHours &&
        activity.hoursSettled
      ) {
        activitiesToFix.push(activity)
      }
    })
    if (activitiesToFix.length === 0) {
      try {
        //saves serviceOrder
        const serviceOrder = await api.post("/serviceOrder/create", {
          ...serviceOrderBody,
        })
        //updates activities
        await Promise.all(
          sanitizedActivities.map(async (activity) => {
            let totalTime = 0
            //updates appointents
            await Promise.all(
              activity.appointments.map(async (appointment) => {
                if (appointment.consultant === userId())
                  totalTime += hoursToMinutes(appointment.hours)
                return await api.patch(
                  `/appointment/update/${appointment._id}`,
                  {
                    ...appointment,
                    osCreated: true,
                  }
                )
              })
            )

            let activityHours = hoursToMinutes(activity.performedHours)
            //updates activity performed hours
            delete activity.appointments
            let project = activity.project[0]
            delete activity.project
            const performedHours = minutesToHours(totalTime + activityHours)

            const activityConsultant = await api.post(
              `/activityConsultant/search/`,
              {
                activity: activity._id,
                consultant: userId(),
              }
            )
            await api.put(`/project/update/${project._id}`, {
              ...project,
              oldCode: project.code,
              performedHours: minutesToHours(
                hoursToMinutes(project.performedHours) + totalTime
              ),
            })
            await api.put(
              `/activityConsultant/update/${activityConsultant.data[0]._id}`,
              {
                ...activityConsultant.data[0],
                performedHours: performedHours,
              }
            )

            return await api.put(`/activity/update/${activity._id}`, {
              ...activity,
              status: "I",
              performedHours: performedHours,
            })
          })
        )
        await api.patch(`/schedule/update/${props.schedule._id}`, {
          ...props.schedule,
          serviceOrder: serviceOrder.data._id,
        })
        await props.updateSchedule()
        setSaving(false)
      } catch (error) {
        alert("ocorreu um erro" + error)
      } finally {
        setSaving(false)
        props.closeModal()
      }
    } else {
      setSaving(false)
      let info = "Erro ao criar OS, as seguintes atividades\n"
      activitiesToFix.forEach((activity) => {
        info += activity.process + "\n"
      })
      info += "Não tem saldo de horas o suficiente para os apontamentos"
      alert(info)
    }
  }
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="createServiceOrder">
          {props.title}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
          disabled={saving}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardBody>
                <ServiceOrderForm
                  customer={customer}
                  serviceOrderBody={serviceOrderBody}
                  schedule={props.schedule}
                  setServiceOrderBody={(prop) => {
                    setServiceOrderBody((prev) => ({ ...prev, ...prop }))
                  }}
                />
              </CardBody>
              <CardHeader></CardHeader>
            </Card>
          </div>
        </div>
      </Row>
      <div className="modal-footer">
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
          disabled={saving}
        >
          Cancelar
        </Button>
        <Button
          color="success"
          type="button"
          onClick={(e) => save()}
          disabled={
            saving ||
            serviceOrderBody.timeTotal !==
              minutesToHours(serviceOrderBody.totalMinutes)
          }
        >
          Salvar
        </Button>
      </div>
    </Modal>
  )
}

export default CreateServiceOrder
